
import { SoundFileEnum } from 'utils/soundPlayer';
import { MakeActionType } from 'utils/typeUtils';

export const reducerName = 'audioState' as const;
export const SET_SOUND_FILE_GAIN = `${reducerName}/SET_SOUND_FILE_GAIN` as const;
export const SET_SOUND_FILE_THROTTLE_MS = `${reducerName}/SET_SOUND_FILE_THROTTLE_MS` as const;
export const SET_OVERALL_GAIN_RATIO = `${reducerName}/SET_OVERALL_GAIN_RATIO` as const;

export const setSoundFileGain = (sound: SoundFileEnum, gain: number) => ({
  type: SET_SOUND_FILE_GAIN,
  payload: { sound, gain },
});

export const setSoundFileThrottleMs = (sound: SoundFileEnum, throttleMs: number) => ({
  type: SET_SOUND_FILE_THROTTLE_MS,
  payload: { sound, throttleMs },
});

export const setOverallGainRatio = (ratio: number) => ({
  type: SET_OVERALL_GAIN_RATIO,
  payload: { ratio },
});

export type AudioAction = MakeActionType<[
  typeof setSoundFileGain,
  typeof setSoundFileThrottleMs,
  typeof setOverallGainRatio,
]>
