import { PlayerUiState, types, player as vcPlayer } from '@livelyvideo/video-client-web';
import { SourceScoreLevel } from '@livelyvideo/video-client-core/lib/api/player/features/bitrate-switching';
import { getPlayerMediaState } from 'selectors';
import { AppThunkAction, BitrateOptions, Player } from 'store/types';
import logger from 'utils/logger';
import isEqual from 'lodash/isEqual';
import { MakeActionType } from 'utils/typeUtils';
import { removePlayerWindowState, setPlayerWindowState } from 'utils/windowQA';
import { getMessageFromError } from 'utils/errorUtils';

export const reducerName = 'playersState' as const;
export const SET_PLAYER = `${reducerName}/SET_PLAYER` as const;
export const REMOVE_PLAYER = `${reducerName}/REMOVE_PLAYER` as const;
export const SET_SCREEN_CAPTURE_PLAYER = `${reducerName}/SET_SCREEN_CAPTURE_PLAYER` as const;
export const REMOVE_SCREEN_CAPTURE_PLAYER = `${reducerName}/REMOVE_SCREEN_CAPTURE_PLAYER` as const;
export const SET_PLAYER_BITRATE_PREFERENCE = `${reducerName}/SET_PLAYER_BITRATE_PREFERENCE` as const;
export const SET_CURRENT_MAX_BITRATE = `${reducerName}/SET_CURRENT_MAX_BITRATE` as const;
export const SET_PLAYER_MEDIA_STATE = `${reducerName}/SET_PLAYER_MEDIA_STATE` as const;
export const SET_PLAYER_VISIBILITY = `${reducerName}/SET_PLAYER_VISIBILITY` as const;
export const SET_HIGH_CPU = `${reducerName}/SET_HIGH_CPU` as const;
export const TOGGLE_PLAYER_BITRATE_LOCKED = `${reducerName}/TOGGLE_PLAYER_BITRATE_LOCKED` as const;

/** Name of a players stream */
export enum PlayerStreamEnum {
  /** Default stream name for non-filtered video */
  DEVICES = 'devices',
  SCREEN_CAPTURE = 'screencapture',
  FILTERED_VIDEO = 'filtered-video',
}

type MediaStatePayload = {
  userId: string,
  videoOff?: boolean | null,
  audioOff?: boolean | null
}

// VideoClient doesn't seem to provide this already as a single type
interface PlayerProvider {
  player: types.PlayerAPI;
  peer: types.PeerAPI;
  streamName: string;
}

const setPlayer = (player: Player) => ({
  type: SET_PLAYER,
  payload: {
    player,
  },
});

const setScreensharePlayer = (player: Player) => ({
  type: SET_SCREEN_CAPTURE_PLAYER,
  payload: {
    player,
  },
});

const removePlayer = (id: string) => ({
  type: REMOVE_PLAYER,
  payload: {
    id,
  },
});

const removeScreensharePlayer = (id: string) => ({
  type: REMOVE_SCREEN_CAPTURE_PLAYER,
  payload: {
    id,
  },
});

export const setBitrateLevelPreference = (userId: string, preference: keyof BitrateOptions) => ({
  type: SET_PLAYER_BITRATE_PREFERENCE,
  payload: { userId, preference },
});

export const setCurrentMaxBitrate = (userId: string, maxBitrate: number) => ({
  type: SET_CURRENT_MAX_BITRATE,
  payload: { userId, maxBitrate },
});

export const _setPlayerMediaState = (payload: MediaStatePayload) => ({
  type: SET_PLAYER_MEDIA_STATE,
  payload,
});

export const _setPlayerVisibility = (userId: string, isVisible: boolean) => ({
  type: SET_PLAYER_VISIBILITY,
  payload: {
    userId,
    isVisible,
  },
});

export const setHighCPU = (isHighCPU:boolean) => ({
  type: SET_HIGH_CPU,
  payload: {
    isHighCPU,
  },
});

export const togglePlayerBitrateLocked = (userId:string) => ({
  type: TOGGLE_PLAYER_BITRATE_LOCKED,
  payload: {
    userId,
  },
});

/**
 * @todo This is a temp wrapper around _setPlayerMediaState
 * simply to add the logging to determine if the hack in useSetPlayerMediaState
 * can be removed
 */
export const setPlayerMediaState = (payload: MediaStatePayload, logIfChange = false): AppThunkAction => (
  dispatch, getState,
) => {
  const { userId, videoOff, audioOff } = payload;

  if (logIfChange) {
    const currentMediaState = getPlayerMediaState(userId)(getState());
    const nextMediaState = { videoOff, audioOff };
    if (!isEqual(currentMediaState, nextMediaState)) {
      logger.warn('User media state may not have updated correctly', { userId, currentMediaState, nextMediaState });
    }
  }
  setPlayerWindowState(payload);

  dispatch(_setPlayerMediaState(payload));
};

/**
 * Wrapper function for _setPlayerVisibility that uses getState to avoid re-renders in VideoTileVideoPlayer
 */
export const setPlayerVisibility = (userId: string, isVisible: boolean): AppThunkAction => (dispatch, getState) => {
  const visible = getState().playersState.visibility[userId];
  if (isVisible !== visible) {
    dispatch(_setPlayerVisibility(userId, isVisible));
  }
};

export type PlayersAction = MakeActionType<[
  typeof setPlayer,
  typeof setScreensharePlayer,
  typeof removePlayer,
  typeof removeScreensharePlayer,
  typeof setBitrateLevelPreference,
  typeof setCurrentMaxBitrate,
  typeof _setPlayerMediaState,
  typeof _setPlayerVisibility,
  typeof setHighCPU,
  typeof togglePlayerBitrateLocked,
]>

/**
 * adds player store object to state
 * logs an error if there is a store without a user (ghost bug)
 */
export const setPlayerByProvider = (playerProvider: PlayerProvider): AppThunkAction => async (dispatch, getState) => {
  const { player, peer, streamName } = playerProvider;
  const { userId, peerId, peerParams } = peer as types.BasePeer & { // missing type in VC
    peerParams: { displayName: string }
  };

  logger.info('Adding player', { userId, peerId });

  const playerObject: Player = {
    uiState: new PlayerUiState(player),
    userId: userId || '',
    peerId: peerId || '',
    displayName: peerParams?.displayName || '',
  };

  const { participants } = getState().roomState.room;
  if (!participants[playerObject.userId]) {
    logger.warn('No participant user ID found for peer', { peerId, userId });
  }

  if (streamName === PlayerStreamEnum.SCREEN_CAPTURE) {
    dispatch(setScreensharePlayer(playerObject));
  } else {
    setPlayerWindowState({ userId: userId || '', displayName: peerParams?.displayName });
    dispatch(setPlayer(playerObject));
  }
};

/**
 * Remove a player store from state
 * @param {string} id
 */
export const removePlayerByProvider = (playerProvider: PlayerProvider, debugString?:string): AppThunkAction => (dispatch, getState) => {
  const { playersState: { players, screenSharePlayers } } = getState();

  const { peer, streamName } = playerProvider;

  const userId = peer?.userId;
  const peerId = peer?.peerId;

  if (!userId) {
    logger.error('Cannot remove player', { reason: 'No peer userId', userId, peerId });
    return;
  }

  logger.info('Removing player', { userId, peerId });

  let player: Player;
  if (streamName === PlayerStreamEnum.SCREEN_CAPTURE) {
    dispatch(removeScreensharePlayer(userId));
    player = screenSharePlayers[userId];
  } else {
    dispatch(removePlayer(userId));
    removePlayerWindowState(userId);
    player = players[userId];
  }

  if (player) {
    console.log('!!!!!!!!!!!!!!!!DISPOSE~!!!!!!!!!!');
    player.uiState.dispose(debugString);
  }
};

export const updatePreferredBitrateLevel = (userId:string, preference: 'Low'|'Medium'|'High'): AppThunkAction => (dispatch, getState) => {
  dispatch(setBitrateLevelPreference(userId, preference));

  const player = getState().playersState.players[userId];

  if (!player) return;

  if (!player.uiState.player?.isImplements(vcPlayer.Feature.BITRATE_SWITCHING)) {
    logger.warn('Bitrate switching not supported by player');
    return;
  }

  const info = {
    userId: player.userId,
    displayName: player.displayName,
    preference,
  };
  try {
    player.uiState.player.setPreferredLevel(SourceScoreLevel[preference]);
    logger.debug('Setting player preferred max bitrate level', { info });
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error setting preferred bitrate level for player', { ...info, errorMessage });
  }
};
