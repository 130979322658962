import { createSelector } from 'reselect';
import { findUserGroupId } from 'utils/breakoutGroupUtils';
import sortParticipants from 'utils/sortParticipants';
import {
  CHAT_BG_PREFIX, CHAT_DM_PREFIX, CONVO_ID_EVERYONE, LaunchedGroups, StoredKNParticipant, StoredKNRoom, BitrateOptions, StoreState,
} from 'store/types';
import { BG_HEADER_HEIGHT_PX, controlsHeight } from 'styles/styles';
import { Conversation } from 'components/Chat/ChatPanel/ChatRoster/ChatRoster';
import determinePlayerPlayPause, { DeterminePlayerPlaySuccessOrError, resultIsNoop } from 'utils/determinePlayerPlayPause';
import { KNRosterUser } from 'utils/roomState/knats';
import { RoomLayoutEnum } from 'actions/roomActions';
import {
  getLaunchedGroups, getIsLaunched, getJoinAudioGroup, getJoinAudioId, getIsGroupsVisible,
} from './breakoutGroupsSelectors';
import {
  getIsLockedFromJoining, getIsUserInFilmStrip, getIsUserPresenter, getKnockers, getOwnerId, getParticipantIds,
  getParticipants, getParticipantsLength, getRoomLayout, getRoomScreenSharers, getRoster, getRosterAsMap, getFullscreenUserId,
  getRoomHighlights, getRoomPermission, getIsRoomSessionLocked,
} from './roomSelectors';
import { getCurrentUserId, getUserProfile } from './loginSelectors';
import {
  getIsHighCPU, getMediaState, getPlayers, getScreenSharePlayers,
} from './playersSelectors';
import { getIsChatVisible, getSelectedConversationId, getUnseenMessages } from './chatSelectors';
import { getIsMobile, getIsTablet } from './browserSelectors';
import { getScreenshareBroadcast } from './screenShareSelectors';
import { getIsIcebreakerOpen } from './icebreakerSelectors';
import { getIsChalkboardOpen } from './chalkboardSelectors';

export const getCurrentChatConvoName = createSelector(
  getLaunchedGroups,
  getRosterAsMap,
  getSelectedConversationId,
  (launchedGroups, rosterMap, convoId) => {
    if (convoId === CONVO_ID_EVERYONE) {
      return 'Everyone';
    }

    if (convoId.startsWith(CHAT_DM_PREFIX)) {
      return rosterMap[convoId.replace(CHAT_DM_PREFIX, '')]?.displayName || '';
    }

    if (convoId.startsWith(CHAT_BG_PREFIX)) {
      return launchedGroups[convoId.replace(CHAT_BG_PREFIX, '')]?.name || '';
    }

    return '';
  },
);

/** Returns the user's group or "unassigned" if not in a group. If not launched, returns null */
export const getCurrentUserGroup = createSelector(
  getLaunchedGroups,
  getUserProfile,
  getIsLaunched,
  (launchedGroups, user, isLaunched) => {
    if (!isLaunched) return null;

    const userId = user?.userId;
    if (!userId) return null;

    const groupId = findUserGroupId(launchedGroups, userId);
    if (groupId) return launchedGroups[groupId];

    return null;
  },
);

export const getCurrentUserGroupId = createSelector(
  getCurrentUserGroup,
  (group) => (group ? `${group.id}` : null),
);

/**
 * This filters the room state's screen sharers by whether we have a
 * media player present for peers or an active broadcast for the current user,
 * which helps prevent issues where we are missing a media stream from
 * the SFU despite that the room state indicates the user is sharing their screen.
 */
export const getScreenSharersInSfu = createSelector(
  getRoomScreenSharers,
  getScreenSharePlayers,
  getScreenshareBroadcast,
  getCurrentUserId,
  (userIds, players, broadcast, currentUserId) => userIds.filter((userId) => !!(players[userId] || (userId === currentUserId && broadcast))),
);

export const getIsUserScreenSharer = (userId: string) => createSelector(
  getScreenSharersInSfu,
  (screenSharers) => screenSharers.includes(userId),
);

const createGetGridParticipants = (includeCurrentUser: boolean) => createSelector(
  getParticipants,
  getCurrentUserId,
  getLaunchedGroups,
  getIsLaunched,
  (participants,
    currentUserId = '',
    launchedGroups,
    isLaunched) => {
    const launchedIds = Object.keys(launchedGroups);
    // if breakout groups launched
    if (isLaunched) {
      let groupId = '';

      launchedIds.forEach((id) => {
        const groupParticipants = launchedGroups[id].users;
        if (groupParticipants.indexOf(currentUserId) > -1) {
          groupId = id;
        }
      });

      const group = launchedGroups[groupId];
      if (group) {
        // return all users from that group
        const groupUsers = group.users.reduce(
          (result: { [userId: string]: StoredKNParticipant }, id) => {
            if (participants[id]) {
              result[id] = participants[id] as StoredKNParticipant;
            }
            return result;
          }, {},
        );

        return groupUsers;
      }
    }

    // if no breakout groups, return all participants without current user
    const gridUsers = Object.keys(participants).reduce((result: StoredKNRoom['participants'], id: string) => {
      if (includeCurrentUser || id !== currentUserId) {
        result[id] = participants[id];
      }
      return result;
    }, {});

    return gridUsers;
  },
);

export const getGridParticipants = createGetGridParticipants(true);
export const getGridParticipantsNoCurrentUser = createGetGridParticipants(false);

export const getGridParticipantIds = createSelector(
  getGridParticipantsNoCurrentUser,
  (participants) => Object.keys(participants),
);

export const getGridParticipantsLength = createSelector(
  getGridParticipantIds,
  (participants) => participants.length,
);

// Returns array of groups users that are still in the room
export const getGroupRoomUsers = (groupId: string) => createSelector(
  getLaunchedGroups,
  getParticipants,
  (launchedGroups, participants) => {
    const groupUsers = launchedGroups[groupId]?.users || [];

    const users = groupUsers.reduce((result: StoredKNParticipant[], id) => {
      const participant = participants[id];
      if (participant) {
        result.push(participant);
      }
      return result;
    }, []);

    return users;
  },
);

export const getGroupRoomUserIds = (groupId: string) => createSelector(
  getGroupRoomUsers(groupId),
  (groupUsers) => groupUsers.map((user) => user.userId),
);

// gets all launched groups with only users that are still in the room
export const getGroupsRoomUsers = createSelector(
  getLaunchedGroups,
  getParticipants,
  (launchedGroups, participants) => {
    const groupIds = Object.keys(launchedGroups);
    const groups = groupIds.reduce((result: LaunchedGroups, groupId) => {
      const groupUsers = launchedGroups[groupId]?.users || [];
      result[groupId] = { ...launchedGroups[groupId] };

      const users: string[] = [];
      groupUsers.forEach((id) => {
        if (participants[id]) {
          users.push(id);
        }
      }, []);

      result[groupId].users = users;

      return result;
    }, {});
    return groups;
  },
);

/**
 * returns true or false if the current user is the owner
 */
export const getIsCurrentUserOwner = createSelector(
  getOwnerId,
  getCurrentUserId,
  (ownerId, userId): boolean => ownerId === userId,
);

/**
 * Returns true if the current user is in the participants object
 */
export const getIsCurrentUserInParticipants = createSelector(
  getCurrentUserId,
  getParticipantIds,
  (userId, ids): boolean => !!(userId && ids.includes(userId)),
);

export const getSortedGrid = createSelector(
  getGridParticipants,
  getMediaState,
  getScreenSharersInSfu,
  getOwnerId,
  getCurrentUserId,
  (gridParticipants,
    mediaState,
    screenSharers,
    ownerId,
    currentUserId = '') => {
    const finalSort = sortParticipants({
      gridParticipants,
      mediaState,
      screenSharers,
      ownerId: ownerId || '',
      currentUserId,
      sortingType: 'GridView',
    });

    return finalSort;
  },
);

export const getSortedFilmStrip = (presenterId: string | null) => createSelector(
  getGridParticipants,
  getMediaState,
  getScreenSharersInSfu,
  getOwnerId,
  getCurrentUserId,
  (gridParticipants,
    mediaState,
    screenSharers,
    ownerId,
    currentUserId = '') => {
    const finalSort = sortParticipants({
      gridParticipants,
      mediaState,
      screenSharers,
      ownerId: ownerId || '',
      currentUserId,
      sortingType: 'FilmStrip',
      presenterId,
    });

    return finalSort;
  },
);

/** User could be locked out due to room state or room-locked event */
export const getIsUserLockedOut = createSelector(
  getCurrentUserId,
  getIsLockedFromJoining,
  getKnockers,
  (userId, isLockedFromJoining, knockers) => (
    (userId && !!knockers[userId]) || isLockedFromJoining
  ),
);

/**
 * returns CC ServiceRoomParticipant object for current user
 */
export const getCurrentUser = createSelector(
  getParticipants,
  getCurrentUserId,
  (participants, userId) => {
    if (userId) {
      return participants[userId];
    }
    return null;
  },
);

/* normally would exist in RoomSelectors but must
exist here to prevent dependency cycles */
export const getInAerialGroups = createSelector(
  getIsCurrentUserOwner,
  getCurrentUserGroupId,
  getIsLaunched,
  (isOwner, ownerGroupId, isLaunched) => isOwner && !ownerGroupId && isLaunched,
);

/**
 * Returns true if the current user is alone in the session
 */
export const getIsCurrentUserAlone = createSelector(
  getParticipants,
  getCurrentUserId,
  (users, currentUserId) => {
    const ids = Object.keys(users);
    return ids.length === 1 && ids[0] === currentUserId;
  },
);

export const getCurrentUserInGroup = createSelector(
  getIsLaunched,
  getCurrentUserGroupId,
  (isLaunched, groupId) => isLaunched && groupId,
);

export const getControlBarHeight = createSelector(
  getIsTablet,
  (isTablet) => (isTablet ? controlsHeight : 0),
);

export const getBreakoutGroupHeaderHeight = createSelector(
  getIsLaunched,
  (isLaunched) => (isLaunched ? BG_HEADER_HEIGHT_PX + 8 : 0),
);

/**
 * This is the amount that the actual space for video tiles in the room
 * must be shortened by, depending on if the control bar is at the bottom
 * or if the the breakout groups header is at the top, etc.
 *
 * Used for grid calculations in useVideoGrid.ts
 */
export const getRoomCrop = createSelector(
  getControlBarHeight,
  getBreakoutGroupHeaderHeight,
  (controlBarHeight, breakoutGroupsHeaderHeight) => controlBarHeight + breakoutGroupsHeaderHeight,
);

/** Roster users who are present, followed by users who are absent, both sorted by displayName */
export const getRosterUsersForChatRoster = createSelector(
  getParticipants,
  getRoster,
  getUnseenMessages,
  getCurrentUserId,
  getOwnerId,
  getIsCurrentUserOwner,
  (participants, roster, unseenMessages, currentUserId, ownerId): Conversation[] => {
    const presentArray: KNRosterUser[] = [];
    const absentArray: KNRosterUser[] = [];

    roster.forEach((rosterUser) => {
      const rosterUserId = rosterUser.id;

      // current user should not see self in roster
      if (`${rosterUserId}` === `${currentUserId}`) return;

      // owner goes first in the present array, regardless of their status
      const isOwner = rosterUserId === ownerId;
      const method = isOwner ? 'unshift' : 'push';
      const array = rosterUser.id in participants || isOwner ? presentArray : absentArray;
      array[method](rosterUser);
    });

    // convert to shape usable by chat roster
    return [...presentArray, ...absentArray].map((user) => {
      const id = `${CHAT_DM_PREFIX}${user.id}` as const;
      return {
        label: user.displayName,
        nUnread: unseenMessages[id]?.count || 0,
        active: user.id in participants,
        id,
      };
    });
  },
);

/** Only calculates new, intended state for players if dependencies have changed */
export const getDeterminePlayerStateResults = createSelector(
  getCurrentUserGroup,
  getPlayers,
  getOwnerId,
  getJoinAudioId,
  getJoinAudioGroup,
  getIsCurrentUserOwner,
  getCurrentUserGroupId,
  getIsLaunched,
  (currentGroup, players, ownerId, joinAudioGroupId, joinAudioGroup, isOwner, currentUserGroupId, isLaunched) => (
    Object.keys(players).map((playerUserId) => determinePlayerPlayPause({
      currentGroupParticipants: currentGroup ? currentGroup.users : [],
      joinAudioUsers: joinAudioGroup ? joinAudioGroup.users : [],
      currentUserGroupId,
      isLaunched,
      isOwner,
      joinAudioGroupId,
      ownerId,
      playerUserId,
      player: players[playerUserId],
    }))
      // ignore any results where player state does not change
      .filter((result) => !resultIsNoop(result)) as DeterminePlayerPlaySuccessOrError[]
  ),
);

export const getShouldFocusNotification = createSelector(
  getIsChatVisible,
  getIsIcebreakerOpen,
  getIsGroupsVisible,
  getIsChalkboardOpen,
  (chat, icebreaker, breakoutGroups, chalkboard) => !chat && !icebreaker && !breakoutGroups && !chalkboard,
);

export const getShouldBitrateBeHighest = (userId:string) => createSelector(
  getRoomLayout,
  getIsUserPresenter(userId),
  getIsUserScreenSharer(userId),
  getParticipantsLength,
  getIsHighCPU,
  getFullscreenUserId,
  (layout, isPresenter, isUserScreenSharing, nParticipants, isHighCPU, fullscreenUserId) => {
    if (
      (layout !== RoomLayoutEnum.GRID && isPresenter)
      || isUserScreenSharing
      || (layout === RoomLayoutEnum.FULLSCREEN && fullscreenUserId === userId)
      || (nParticipants < 5 && !isHighCPU)) {
      return true;
    }
    return false;
  },
);

export const getShouldBitrateBeLowest = (userId: string, isSmallTile: boolean) => createSelector(
  getRoomLayout,
  getIsUserInFilmStrip(userId),
  getIsMobile,
  getParticipantsLength,
  getIsHighCPU,
  (layout, isFilmStrip, isMobile, nParticipants, isHighCPU) => {
    if (
      isFilmStrip
      || isSmallTile
      || isMobile
      || (nParticipants > 8 && layout === RoomLayoutEnum.GRID)
      || isHighCPU) {
      return true;
    }
    return false;
  },
);

export const calculatePreferredBitrateLevel = (userId: string, isSmallTile: boolean) => createSelector(
  getShouldBitrateBeHighest(userId),
  getShouldBitrateBeLowest(userId, isSmallTile),
  (shouldBeHighest, shouldBeLowest) => {
    let newLevelPreference: keyof BitrateOptions = 'Medium';

    if (shouldBeLowest) {
      newLevelPreference = 'Low';
    } else if (shouldBeHighest) {
      newLevelPreference = 'High';
    }
    return newLevelPreference;
  },
);

export const getPlayerBitrates = (state:StoreState) => state.playersState.playerBitrateLevels;

export const getCurrentMaxBitrateById = (userId:string) => createSelector(
  getPlayerBitrates,
  (bitrates) => {
    if (bitrates[userId]) {
      return bitrates[userId].currentMaxBitrate;
    }
    return null;
  },
);

export const getIsCurrentUserHighlighted = createSelector(
  getCurrentUserId,
  getRoomHighlights,
  (userId, highlights) => {
    if (!highlights || !userId) {
      return false;
    }
    if (highlights === userId) {
      return true;
    }
    return false;
  },
);

export const getIsAllowedToNudge = createSelector(
  getRoomPermission('privateNudge'),
  getIsCurrentUserOwner,
  (canNudge, isOwner) => !!(isOwner || canNudge),
);

export const getMustKnock = createSelector(
  getIsUserLockedOut,
  getIsCurrentUserOwner,
  getIsRoomSessionLocked,
  (isUserLockedOut, isOwner, roomLocked) => isUserLockedOut || (!isOwner && roomLocked),
);

export const getIsOwnerScreenSharing = createSelector(
  getOwnerId,
  getScreenSharePlayers,
  (ownerId, players) => !!ownerId && !!players[ownerId],
);
