import {
  Middleware, Reducer,
} from 'redux';
import logger from 'utils/logger';
import {
  MapPartialKeys, ValueOf,
} from 'utils/typeUtils';
import {
  DebugState, ChalkboardState, EncoderState, LoginState,
  PreferencesState, RoomState, StoreState, MobileState, FilterState,
} from './types';

/* ************** */
/*  BEGIN CONFIG  */
/* ************** */
// Look for "New slice state?" comments if adding store slice to persist

type PersistConfig = MapPartialKeys<StoreState, readonly string[]>

export interface LocalPersistConfig extends PersistConfig {
  loginState: Readonly<Array<keyof LoginState>>,
  roomState: Readonly<Array<keyof RoomState>>,
  encoderState: Readonly<Array<keyof EncoderState>>,
  preferencesState: Readonly<Array<keyof PreferencesState>>,
  debugState: Readonly<Array<keyof DebugState>>
  mobileState: Readonly<Array<keyof MobileState>>
  chalkboardState: Readonly<Array<keyof ChalkboardState>>
  filterState: Readonly<Array<keyof FilterState>>
  // New slice state? Add here (look for other "New slice state?" comments)
}

// Add/remove keys to persist as necessary for each slice
const loginState = ['user', 'token', 'livelyToken', 'livelyTokenExp'] as const;
// const roomState = ['layout', 'prevUserSelectedLayout', 'presenterId'] as const;
const roomState = [] as const;
const encoderState = [
  // 'isVideoPaused',
  // 'isAudioMuted',
  // 'audioDeviceIdPref',
  // 'videoDeviceIdPref',
  // 'numBitrates',
] as const;
const preferencesState = ['soundPreferences'] as const;
const debugState = [
  'enableBitrateLevels',
  'enableGrafanaLinks',
  'enableSandbox',
  'enableMockParticipants',
  'enableDeleteAllRooms',
  'enableArcade',
  'enableChalkboardStream',
  'enableEmitVideoClientStats',
] as const;
const mobileState = ['dismissedMobileRedirect'] as const;
const chalkboardState = ['tips'] as const;
const filterState = [
  'currentFilterKey',
  'enabledPublicFilters',
  'tensorFlowBackend',
  'enableFaceDetection',
  'maxFrameRate',
  'predictionInterval',
  'croppedCanvasSize'] as const;
// New slice state? Add here

// Could be better typed, but for TypeScript this module may be better refactored
// to allow higher assurance typing, or by using a 3rd party middleware
export type PersistedStore = {
  [key in
  typeof loginState[number] |
  typeof roomState[number] |
  typeof encoderState[number] |
  typeof preferencesState[number] |
  typeof debugState[number] |
  typeof mobileState[number] |
  typeof chalkboardState[number] |
  typeof filterState[number]
  // New slice state? Add here
  ]: any;
}

export const persistConfig: LocalPersistConfig = {
  loginState,
  roomState,
  encoderState,
  preferencesState,
  debugState,
  mobileState,
  chalkboardState,
  filterState,
  // New slice state? Add here
} as const;

const keepOnLogoutConfig: Partial<LocalPersistConfig> = {
  // New slice state? (to persist after logout only) Add here
};

/* ************** */
/*   END CONFIG   */
/* ************** */

/**
 * removes persisted store to reset it
 */
export const resetPersistedStore = () => {
  localStorage.removeItem('persistedStore');
};

/**
 * stringifies the store and sets to localStorage
 * @param {object} store
 */
export const setPersistedStore = (store: PersistedStore) => {
  localStorage.setItem('persistedStore', JSON.stringify(store));
};

/**
 * gets persisted store and tries to parse
 * returns store object or null
 */
export const getPersistedStore = (): PersistedStore | null => {
  const json = localStorage.getItem('persistedStore');
  if (!json) {
    return null;
  }
  try {
    const persisted = JSON.parse(json) as PersistedStore;
    return persisted;
  } catch (error) {
    logger.warn('Error while parsing persisted store: ', { error } as any);
    // reset the persisted store if there was an error while parsing
    resetPersistedStore();
    return null;
  }
};

/**
 * gets a value from the persisted store
 * @param {string} key
 */
export const getItemFromPersistedStore = (key: string) => {
  const store = getPersistedStore();
  if (store) {
    return store[key as keyof PersistedStore];
  }
  return null;
};

/** Clears persisted store on logout, except for what is in keepOnLogoutConfig */
export const clearPersistedStoreForLogout = () => {
  logger.info('Clearing persisted redux store');
  try {
    const store = getPersistedStore();
    if (store) {
      const newStore: Partial<PersistedStore> = {};
      Object.values(keepOnLogoutConfig).forEach((propKeyArray) => {
        (propKeyArray as Array<keyof PersistedStore>).forEach((propKey) => {
          if (propKey in store) {
            newStore[propKey] = store[propKey];
          }
        });
      });
      setPersistedStore(newStore as PersistedStore);
    }
  } catch (err) {
    logger.warn('Error clearing persisted store for logout', { error: err as any });
  }
};

/**
 * Takes a redux reducer and array of keys as arguments and
 * mutates the action with "_persist: true" if the action changes
 * a value that is persisted in local storage. This mutation
 * will be used in localStoragePersistMiddleware
 */
export const persistReducer = <SliceState extends ValueOf<StoreState>>(
  reducer: Reducer<SliceState, any>,
  keys: ValueOf<LocalPersistConfig>): Reducer => (state, action) => {
    if (!state) {
      return reducer;
    }
    const newState = reducer(state, action);

    keys?.forEach((_key) => {
      const key = _key as keyof SliceState;
      if (state[key] !== (newState[key])) {
        (action as typeof action & { _persist: boolean })._persist = true;
      }
    });
    return newState;
  };

/**
 * Middleware for setting specified values to local storage
 * so they persist through page refreshes
 * @param {{ getState: func }}
 */
const localStoragePersistMiddleware: Middleware = ({ getState }) => (next) => (action) => {
  const result = next(action);

  if (action._persist) {
    const fullState = getState();
    const persist: Partial<PersistedStore> = {};

    Object.keys(persistConfig).forEach((state) => {
      persistConfig[state as keyof LocalPersistConfig]?.forEach((key) => {
        const value = fullState[state][key];
        persist[key as keyof PersistedStore] = value;
      });
    });

    setPersistedStore(persist as PersistedStore);
  }

  return result;
};

export default localStoragePersistMiddleware;
