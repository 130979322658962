import { FaceDetectionEvent } from './faceDetectionEvent';
import { FilterEvent } from './filterEvent';
import { Filters } from './filters';

export type Option<T> = T | null | undefined;

export type AllFalsyValues = null | undefined | 0 | '' | 0n;

/** @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState */
export enum HTMLMediaReadyState {
  HAVE_NOTHING,
  HAVE_METADATA,
  HAVE_CURRENT_DATA,
  HAVE_FUTURE_DATA,

  /** This is the ready state of the video element once its onloadeddata event has been emitted */
  HAVE_ENOUGH_DATA,
}

export type FaceDetectorModels = 'face-api' | 'tensorflow';

export interface Prediction {
  topLeftX: number,
  topLeftY: number,
  width: number,
  height: number,
  confidence?: number,
}

export type PredictionMethod = 'imageBitmap' | 'imageData';

export interface FaceDetectionEventData {
  [FaceDetectionEvent.PREDICTION_COMPLETE.id]: Prediction;
  [FaceDetectionEvent.INITIALIZING_MODEL.id]?: null;
  [FaceDetectionEvent.MODEL_CHOSEN.id]: FaceDetectorModels;
  [FaceDetectionEvent.MODEL_READY.id]: null;
}


export interface FilterEventData {
  [FilterEvent.VIDEO_DATA_ALREADY_READY.id]?: null;
  [FilterEvent.VIDEO_DATA_LOADED.id]?: Event;
  [FilterEvent.VIDEO_DATA_LOAD_START.id]?: Event;
  [FilterEvent.VIDEO_DIMENSIONS_0_DETECTED.id]?: null;
  [FilterEvent.CURRENT_FILTER_SET.id]?: Filters;
  [FilterEvent.DOUBLE_THRESHOLD_LOW_VALUE_SET.id]: number;
  [FilterEvent.DOUBLE_THRESHOLD_HIGH_VALUE_SET.id]: number;
  [FilterEvent.ENABLE_FACE_DETECTION_SET.id]: boolean;
  [FilterEvent.MAX_FRAME_RATE_SET.id]: number;
  [FilterEvent.PREDICTION_INTERVAL_SET.id]: number;
  [FilterEvent.CROPPED_CANVAS_SIZE_SET.id]: number;
  [FilterEvent.CONTEXT_LOST.id]?: Event;
  [FilterEvent.CONTEXT_RESTORED.id]?: Event;
  [FilterEvent.CLEANING_UP.id]?: null;
  [FaceDetectionEvent.PREDICTION_COMPLETE.id]: Prediction;
  [FaceDetectionEvent.INITIALIZING_MODEL.id]?: null;
  [FaceDetectionEvent.MODEL_CHOSEN.id]: FaceDetectorModels;
  [FaceDetectionEvent.MODEL_READY.id]: null;
}

export type TensorFlowBackends = 'webgl' | 'wasm' | 'cpu';

export interface FaceDetectorOptions<L extends Logger = Logger> {
  tensorFlowBackend: TensorFlowBackends,
  logger: L,
}

export interface Logger<D extends unknown = unknown> {
  log: (message: string, data?: D) => void,
  debug: (message: string, data?: D) => void,
  warn: (message: string, data?: D) => void,
  error: (message: string, data?: D) => void,
  info: (message: string, data?: D) => void,
}


export type FilterOptions<L extends Logger = Logger> = FaceDetectorOptions & {
  /** Determines whether face detection is immediately initialized.
   * This setting can be updated later, after Filter has been instantiated */
  enableFaceDetection: boolean,

  /** How often face detection predictions are run and how quickly the bounding box
   * moves to each new location based on the previous prediction */
  predictionInterval: number,

  /** How often Filter tries to draw video frames to the canvas.
   * Decreasing this number will artificially throttle the maximum framerate */
  maxFrameRate: number,

  initialFilter: Filters

  croppedCanvasSize: number,

  logger: L,
};

export enum MainToWorkerActionNoPayload { }

export enum MainToWorkerActionWithPayload {
  INIT = 'INIT',
  /** Send ImageData to web worker for worker to analyze ImageData */
  REQUEST_PREDICTION_WITH_IMAGE_DATA = 'REQUEST_PREDICTION_WITH_IMAGE_DATA',
  /** Send ImageBitamp to web worker for worker to analyze ImageBitamp */
  REQUEST_PREDICTION_WITH_IMAGE_BITMAP = 'REQUEST_PREDICTION_WITH_IMAGE_BITMAP',
  /** Turn on/off face detection */
  FACE_DETECTION_ENABLED = 'FACE_DETECTION_ENABLED',
}

export const WorkerToMainActionNoPayload = {
  INITIALIZING_MODEL: FaceDetectionEvent.INITIALIZING_MODEL.id,
  MODEL_READY: FaceDetectionEvent.MODEL_READY.id,
} as const;

export const WorkerToMainActionWithPayload = {
  MODEL_CHOSEN: FaceDetectionEvent.MODEL_CHOSEN.id,
  PREDICTION_COMPLETE: FaceDetectionEvent.PREDICTION_COMPLETE.id,
} as const;


export type WorkerToMainActionNoPayloadKey = keyof typeof WorkerToMainActionNoPayload;

export type WorkerToMainActionWithPayloadKey = keyof typeof WorkerToMainActionWithPayload;

/** Messages that can be passed from worker thread to main thread */
export type WorkerToMainAction = WorkerToMainActionNoPayloadKey | WorkerToMainActionWithPayloadKey;
export interface WorkerToMainMessagesWithPayload {
  [WorkerToMainActionWithPayload.MODEL_CHOSEN]: FaceDetectorModels,
  [WorkerToMainActionWithPayload.PREDICTION_COMPLETE]: Prediction;
}
export type WorkerToMainMessage<M extends WorkerToMainAction = WorkerToMainAction> =
  M extends WorkerToMainActionNoPayloadKey
  ? {
    action: M;
    body?: null,
  }
  : M extends WorkerToMainActionWithPayloadKey
  ? {
    action: M;
    body: WorkerToMainMessagesWithPayload[M];
  }
  : never;


/** Messages that can be passed from main thread to worker thread */
export type MainToWorkerAction = MainToWorkerActionNoPayload | MainToWorkerActionWithPayload;
export interface MainToWorkerMessagesWithPayload {
  [MainToWorkerActionWithPayload.INIT]: {
    tensorFlowBackend: FilterOptions['tensorFlowBackend'],
  };
  [MainToWorkerActionWithPayload.REQUEST_PREDICTION_WITH_IMAGE_DATA]: {
    imageData: ImageData,
  };
  [MainToWorkerActionWithPayload.REQUEST_PREDICTION_WITH_IMAGE_BITMAP]: {
    imageBitmap: ImageBitmap,
  };
  [MainToWorkerActionWithPayload.FACE_DETECTION_ENABLED]: {
    enabled: boolean,
  };
}

export type MainToWorkerMessage<M extends MainToWorkerAction = MainToWorkerAction> =
  M extends MainToWorkerActionNoPayload
  ? {
    action: M;
  }
  : M extends MainToWorkerActionWithPayload
  ? {
    action: M;
    body: MainToWorkerMessagesWithPayload[M];
  }
  : never;

/** Message from the main thread to the detectFaceWorker */

export type TargetBoundingBox = {
  topLeftX: number;
  topLeftY: number;
  sizeX: number;
  sizeY: number;
};
export type CurrentBoundingBox = {
  topLeftX: number;
  topLeftY: number;
  sizeX: number;
  sizeY: number;
} | null;
