import { EncoderUiState, types, mediaController } from '@livelyvideo/video-client-web';
import logger from 'utils/logger';
import { setRoomError, roomRequest } from 'actions/sharedActions';
import { DisplayCaptureSurfaceEnum } from 'utils/screenshare';
import {
  AppThunkAction, Broadcast, MediaStreamController, UiState,
} from 'store/types';
import { AxiosRequestConfig } from 'axios';
import { MakeActionType } from 'utils/typeUtils';
import mixpanel from 'utils/mixpanel';
import { getMessageFromError } from 'utils/errorUtils';
import { batch } from 'react-redux';
import { SCREEN_SHARE_MAX_BITRATE } from 'reducers/debugReducer';

export const reducerName = 'screenShareReducer' as const;
export const SET_SCREEN_CAPTURE = `${reducerName}/SET_SCREEN_CAPTURE` as const;
export const SET_SCREEN_SHARE_BROADCAST = `${reducerName}/SET_BROADCAST` as const;
export const SET_SCREEN_SHARE_UI_STATE = `${reducerName}/SET_UI_STATE` as const;
export const SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER = `${reducerName}/SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER` as const;
export const SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER_ERROR = `${reducerName}/SET_MEDIA_CONTROLLER_ERROR` as const;
export const RESET_SCREEN_SHARE_ENCODER = `${reducerName}/RESET_SCREEN_SHARE_ENCODER` as const;
export const SET_DISPLAY_CAPTURE_SURFACE = `${reducerName}/SET_DISPLAY_CAPTURE_SURFACE` as const;
export const SET_SWAPPED_SCREENSHARE_PREVIEW = `${reducerName}/SET_SWAPPED_SCREENSHARE_PREVIEW` as const;
export const SET_SCREEN_SHARE_CONTENT_SOURCE = `${reducerName}/SET_SCREEN_SHARE_CONTENT_SOURCE` as const;

export const setScreenCapture = (isScreenCapture: boolean) => ({
  type: SET_SCREEN_CAPTURE,
  payload: { isScreenCapture },
});

export const setDisplayCaptureSurface = (displayCaptureSurface: DisplayCaptureSurfaceEnum) => ({
  type: SET_DISPLAY_CAPTURE_SURFACE,
  payload: { displayCaptureSurface },
});

export const setScreenShareUiState = (screenShareUiState: UiState) => ({
  type: SET_SCREEN_SHARE_UI_STATE,
  payload: { screenShareUiState },
});

export const setScreenShareBroadcast = (broadcast: Broadcast) => ({
  type: SET_SCREEN_SHARE_BROADCAST,
  payload: { broadcast },
});

export const setScreenShareMediaStreamController = (
  mediaStreamController: MediaStreamController,
) => ({
  type: SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER,
  payload: { mediaStreamController },
});

export const setScreenShareMediaStreamControllerError = (
  mediaControllerError: string | null,
) => ({
  type: SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER_ERROR,
  payload: { mediaControllerError },
});

export const setSwappedScreenSharePreview = (swapped: boolean) => ({
  type: SET_SWAPPED_SCREENSHARE_PREVIEW,
  payload: { swapped },
});

export type ScreenShareAction = MakeActionType<[
  typeof setScreenCapture,
  typeof setDisplayCaptureSurface,
  typeof setScreenShareUiState,
  typeof setScreenShareBroadcast,
  typeof setScreenShareMediaStreamController,
  typeof setScreenShareMediaStreamControllerError,
  typeof RESET_SCREEN_SHARE_ENCODER,
  typeof setSwappedScreenSharePreview,
]>

/**
 * Sends POST request to CC Service which will set the screen sharer ID and
 * cause the websocket to emit a "start-screen-share" event
 */
export const emitStartScreenShare = (): AppThunkAction => async (dispatch, getState) => {
  dispatch(setScreenCapture(true));

  const state = getState();
  const roomId = state.roomState.room.id;
  const currentUserId = state.loginState.user?.userId;

  if (!currentUserId) {
    logger.warn('emitStartScreenShare: Cannot emit start screen share', { currentUserId });
    return;
  }

  const options:AxiosRequestConfig = {
    method: 'POST',
    url: `/rooms/${roomId}/screen-share`,
    data: {
      screenSharer: currentUserId,
    },
  };

  try {
    await dispatch(roomRequest(options));
  } catch (error) {
    logger.error('Error starting screen share', { error: error as any });
    dispatch(setRoomError(getMessageFromError(error)));
  }
};

/**
 * Sends DELETE request to CC Service which will remove the current screen sharer ID
 * and emit a "stop-screen-share" event.
 * This endpoint does not required an ID, only a lively token.
 */
export const emitEndScreenShare = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  const currentUserId = state.loginState.user?.userId;

  if (!currentUserId) {
    logger.warn('emitEndScreenShare: Cannot emit end screen share. currentUserId is : ', currentUserId as any);
    return;
  }

  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `/rooms/${roomId}/screen-share`,
  };

  try {
    await dispatch(roomRequest(options));
  } catch (error) {
    logger.error('Error ending screen share', { error: error as any });
    dispatch(setRoomError(getMessageFromError(error)));
  }
};

/**
 * Ends screen share and does cleanup
 * If emit is true it will send a DELETE request to the backend.
 *
 * emit will be false when the owner has disabled screen share
 * and the user is currently sharing. In this case the DELETE
 * would error with a 403
 */
export const endScreenShare = (emit = true, debugString?:string): AppThunkAction => (dispatch, getState) => {
  const {
    broadcast, screenShareMediaStreamController, screenShareUiState, swappedScreenSharePreview,
  } = getState().screenShareState;

  batch(() => {
    dispatch(setScreenCapture(false));
    dispatch(setDisplayCaptureSurface(DisplayCaptureSurfaceEnum.UNKNOWN));
    if (emit) {
      dispatch(emitEndScreenShare());
    }
  });

  if (swappedScreenSharePreview) dispatch(setSwappedScreenSharePreview(false));

  if (broadcast) {
    broadcast.dispose(debugString);
  }

  if (screenShareUiState) {
    screenShareUiState.dispose(debugString);
  }

  if (screenShareMediaStreamController) {
    screenShareMediaStreamController.dispose(debugString);
  }

  // reset to default state
  dispatch({ type: RESET_SCREEN_SHARE_ENCODER });

  mixpanel.track('Share Screen Stop');
};

export const CANVAS_STREAM_FPS = 24;

export const startScreenShare = (element?:HTMLCanvasElement): AppThunkAction => async (dispatch, getState) => {
  const state = getState();

  if (state.screenShareState.swappedScreenSharePreview) dispatch(setSwappedScreenSharePreview(false));

  // screen share uses the same call as the camera encoder
  const { call } = state.encoderState;

  try {
    const options = element ? { capturable: { element, framerate: CANVAS_STREAM_FPS } } : undefined;
    const mediaStreamController = await mediaController.requestController(options);

    const videoDeviceChanged = async (videoDevice: types.MediaStreamControllerEvents['videoDeviceChanging']) => {
      if (videoDevice?.deviceId === 'screencapture' || videoDevice?.deviceId === 'capturable') {
        if (!call) {
          logger.warn('Cannot start screen share broadcast', { reason: 'No call', call });
          return;
        }

        try {
          const broadcast = await call.broadcast(mediaStreamController, {
            streamName: 'screencapture',
            videoProducerOptions: {
              encodings: [{ maxBitrate: SCREEN_SHARE_MAX_BITRATE }],
            },
          });
          dispatch(setScreenShareBroadcast(broadcast));
          dispatch(emitStartScreenShare());
          let displayCaptureSurface: DisplayCaptureSurfaceEnum = DisplayCaptureSurfaceEnum.UNKNOWN;
          if (mediaStreamController.source) {
            displayCaptureSurface = DisplayCaptureSurfaceEnum.getFromMediaStream(mediaStreamController.source);
            dispatch(setDisplayCaptureSurface(displayCaptureSurface));
          }
          mixpanel.track('Share Screen Starts', {
            'Screen Type': displayCaptureSurface.name,
          });
        } catch (error) {
          logger.error('Error starting screen share broadcast', { error } as any);
        }
      } else if (videoDevice === null) {
        dispatch(endScreenShare(true, 'videoDevice is null from videoDeviceChanged event'));
      }
    };

    const videoDeviceChanging = (dev: types.MediaStreamControllerEvents['videoDeviceChanging']) => {
      // this case is reach if a user cancels screen share before selecting a screen
      if (dev === null) {
        dispatch(endScreenShare(false, 'User cancelled screen share'));
      }
    };

    // we must set this listener before we set the videoDeviceId
    // to trigger the screen share prompt at the right time
    mediaStreamController.on('videoDeviceChanged', videoDeviceChanged);
    mediaStreamController.on('videoDeviceChanging', videoDeviceChanging);

    mediaStreamController.audioMuted = true;
    mediaStreamController.videoDisabled = false;

    mediaStreamController.once('disposed', () => {
      mediaStreamController.off('videoDeviceChanged', videoDeviceChanged);
      mediaStreamController.off('videoDeviceChanging', videoDeviceChanging);
    });

    const screenShareUiState = new EncoderUiState(mediaStreamController, {
      audioDevice: null,
      videoDevice: 'screencapture',
    });

    dispatch(setScreenShareUiState(screenShareUiState));
    dispatch(setScreenShareMediaStreamController(mediaStreamController));
  } catch (error) {
    logger.warn('Error setting screen share mediaStreamController', { error } as any);
    dispatch(setScreenShareMediaStreamControllerError('Error setting screen share mediaStreamController'));
  }
};
