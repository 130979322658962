import React, {
  ElementType, Fragment, ReactNode, useCallback, useEffect, useMemo,
} from 'react';
import {
  Redirect, useLocation, useParams,
} from 'react-router-dom';
import { LoginTypesEnum } from 'store/types';
import Loading from 'components/Loading/Loading';
import useTokenAuth from 'hooks/useTokenAuth';
import useStateIfMounted from 'hooks/useStateIfMounted';
import request from 'utils/request';
import logger from 'utils/logger';
import {
  getIsUserValid, getJoinedRoom, getLoginType,
} from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import useAccountMergeError from 'hooks/useAccountMergeError';
import { Either } from 'utils/typeUtils';
import { toggleIsNewDevice } from 'actions/joinActions';
import { setCurrentPathAsPostLoginRedirect } from 'actions/loginActions';
import RouteEnum from 'utils/routeEnum';
import { FetchRoomStateResponse } from 'utils/ccService/types';

/**
 * These are the various requirements that ProtectedRoute can enforce.
 */
type ProtectedType = 'requireAuth' | 'prohibitAuth' | 'roomManager' | 'room';

type ComponentProps = Either<{
  children?: ReactNode,
  component?: ElementType,
  type?: ProtectedType
}, 'children', 'component'>

/**
 * Conditionally renders a component depending on if certain criteria are met.
 * Accepts either a `component` props OR a `children` prop, but never both/none.
 *
 * Using the `component` prop is useful when the component to be rendered doesn't require any props of its own.
 * @example
 * ```
 * <Route path="/" exact render={() => <ProtectedRoute type="prohibitAuth" component={Login} />} />
 * ```
 *
 * Using the `children` prop is useful when you would like to pass props to the component, especially when
 * those props are coming from the <Route /> component above.
 * @example
 * ```
 * <Route
      path="/room-manager/:view/:roomId"
      exact
      render={({ match: { params: { view, roomId } } }) => (
        <ProtectedRoute type="roomManager">
          <RoomManager view={view} roomId={roomId} />
        </ProtectedRoute>
      )}
    />
    ```
 */
export default function ProtectedRoute({ children, component: Component, type = 'requireAuth' }: ComponentProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { roomId, view }: { roomId?: string, view?: string } = useParams();
  const joinedRoomId = useSelector(getJoinedRoom);
  const isUserValid = useSelector(getIsUserValid);
  const loginType = useSelector(getLoginType);
  const { token, isChecked, loginError } = useTokenAuth();
  const { hasMergeError } = useAccountMergeError();
  const mustCheckAuth = !!token && !hasMergeError;
  const [checkingIfOwnerInRoom, setCheckingIfOwnerInRoom] = useStateIfMounted(true);
  const [ownerInRoom, setOwnerInRoom] = useStateIfMounted(false);

  const isReturningDevice = useMemo(() => {
    const returningDevice = localStorage.getItem('isReturningDevice');
    return returningDevice !== null && JSON.parse(returningDevice) === true;
  }, []);

  /**
   * Be careful when editing this variable. If is very easy to cause excessive re-renders here by
   * creating a Function component function rather than assigning the value to a variable.
   */
  const component = <Fragment>{Component ? <Component /> : children}</Fragment>;

  /* if this is the user's first visit,
  check if the owner is already in the room */
  const fetchOwnerInRoom = useCallback(async () => {
    try {
      if (isUserValid || isReturningDevice || !roomId) return;
      const { data } = await request<FetchRoomStateResponse>({
        method: 'GET',
        url: `/rooms/${roomId}`,
      });
      const { participants } = data.roomState;
      let ownerFound = false;
      Object.keys(participants).forEach((userId) => {
        const participant = participants[userId];
        if (participant.owner) ownerFound = true;
      });
      setOwnerInRoom(ownerFound);
    } catch (error) {
      logger.error('Error getting room state', { error } as any);
    } finally {
      setCheckingIfOwnerInRoom(false);
    }
  }, [isUserValid, isReturningDevice, roomId, setCheckingIfOwnerInRoom, setOwnerInRoom]);

  useEffect(() => {
    fetchOwnerInRoom();
  }, []);

  switch (type) {
    case 'requireAuth': {
      // Require that the user be a logged-in AUTH_USER
      if (loginType !== LoginTypesEnum.AUTH_USER) {
        dispatch(setCurrentPathAsPostLoginRedirect());
        return <Redirect to={{ pathname: RouteEnum.HOME.encodePath(), state: { from: location } }} />;
      }
      return component;
    }
    case 'prohibitAuth': {
      // Require that the user NOT be a logged-in AUTH_USER
      if (loginType === LoginTypesEnum.AUTH_USER) return <Redirect to={{ pathname: RouteEnum.DASHBOARD.encodePath(), state: { from: location } }} />;
      return component;
    }
    case 'roomManager': {
      /* If a token is included in the URL as a query param,
      show loading screen while validating user */
      if (mustCheckAuth && !isChecked) return <Loading />;
      // login error
      if (mustCheckAuth && isChecked && loginError) return <Redirect to={RouteEnum.ERROR.encodePath()} />;
      // get rid of token in the URL
      if (mustCheckAuth && isChecked && isUserValid) {
        return (
          <Redirect to={RouteEnum.ROOM_MANAGER__JOIN.encodePath({ urlParams: { roomId: roomId || '' } })} />
        );
      }
      // not logged in and first time user, only redirect when trying to go to /join
      if (!isUserValid && !isReturningDevice && view === 'join') {
        localStorage.setItem('isReturningDevice', 'true');
        // show loading screen while checking if owner is already in the room
        if (checkingIfOwnerInRoom) return <Loading />;
        // if the owner is not in the room, show login flow
        if (!ownerInRoom) {
          dispatch(toggleIsNewDevice(true));
          return component;
        }
      }
      return component;
    }
    case 'room': {
      // redirect if the user is an ANON_USER or if the user has not joined this room
      if (!isUserValid || joinedRoomId !== roomId) {
        logger.info('Redirect to /join', { isUserValid, joinedRoomId, roomId });
        return <Redirect to={RouteEnum.ROOM_MANAGER__JOIN.encodePath({ urlParams: { roomId: roomId || '' } })} />;
      }
      return component;
    }
    default: return component;
  }
}
