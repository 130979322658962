import { batch } from 'react-redux';
import { AppThunkAction } from 'store/types';
import { getStatusCodeFromError } from 'utils/errorUtils';
import logger from 'utils/logger';
import request from 'utils/request';
import { MakeActionType } from 'utils/typeUtils';

// action types for requesting a link to reset a password
export const reducerName = 'passwordResetState' as const;
export const SET_RESET_LINK_SENDING = `${reducerName}/SET_RESET_LINK_SENDING` as const;
export const SET_RESET_LINK_SENT = `${reducerName}/SET_RESET_LINK_SENT` as const;
export const SET_RESET_LINK_ERROR = `${reducerName}/SET_RESET_LINK_ERROR` as const;
export const SET_RESET_PASSWORD_EMAIL = `${reducerName}/SET_RESET_PASSWORD_EMAIL` as const;
export const RESET_FORGOT_STATE = `${reducerName}/RESET_FORGOT_STATE` as const;

// actions types for resetting a password
export const SET_CHANGE_PASSWORD_SENDING = `${reducerName}/SET_CHANGE_PASSWORD_SENDING` as const;
export const SET_CHANGE_PASSWORD_SUCCESS = `${reducerName}/SET_CHANGE_PASSWORD_SUCCESS` as const;
export const SET_CHANGE_PASSWORD_ERROR = `${reducerName}/SET_CHANGE_PASSWORD_ERROR` as const;
export const SET_CHANGE_PASSWORD_LINK_EXPIRED = `${reducerName}/SET_CHANGE_PASSWORD_LINK_EXPIRED` as const;
export const SET_INVALID_PASSWORD = `${reducerName}/SET_INVALID_PASSWORD` as const;
export const RESET_CHANGE_PASSWORD_STATE = `${reducerName}/RESET_CHANGE_PASSWORD_STATE` as const;

// reset state for both
export const RESET_ALL_PASSWORD_RESET_STATE = `${reducerName}/RESET_STATE` as const;

export const setResetLinkSending = (sending: boolean) => ({
  type: SET_RESET_LINK_SENDING,
  payload: { sending },
});

export const setResetLinkSent = (sent: boolean) => ({
  type: SET_RESET_LINK_SENT,
  payload: { sent },
});

export const setResetLinkError = (error: boolean) => ({
  type: SET_RESET_LINK_ERROR,
  payload: { error },
});

export const setResetPasswordEmail = (email: string) => ({
  type: SET_RESET_PASSWORD_EMAIL,
  payload: { email },
});

export const resetForgotPasswordState = () => ({
  type: RESET_FORGOT_STATE,
});

export const setChangePasswordSending = (sending: boolean) => ({
  type: SET_CHANGE_PASSWORD_SENDING,
  payload: { sending },
});

export const setChangePasswordSuccess = (success: boolean) => ({
  type: SET_CHANGE_PASSWORD_SUCCESS,
  payload: { success },
});

export const setChangePasswordError = (error: boolean) => ({
  type: SET_CHANGE_PASSWORD_ERROR,
  payload: { error },
});

export const setChangePasswordLinkExpired = (expired: boolean) => ({
  type: SET_CHANGE_PASSWORD_LINK_EXPIRED,
  payload: { expired },
});

export const resetChangePasswordState = () => ({
  type: RESET_CHANGE_PASSWORD_STATE,
});

export const setInvalidPassword = (invalidPassword: string) => ({
  type: SET_INVALID_PASSWORD,
  payload: { invalidPassword },
});

export const resetAllPasswordResetState = () => ({
  type: RESET_ALL_PASSWORD_RESET_STATE,
});

export type PasswordResetAction = MakeActionType<[
  typeof setResetLinkSending,
  typeof setResetLinkSent,
  typeof setResetLinkError,
  typeof setResetPasswordEmail,
  typeof resetForgotPasswordState,
  typeof setChangePasswordSending,
  typeof setChangePasswordSuccess,
  typeof setChangePasswordError,
  typeof setChangePasswordLinkExpired,
  typeof resetChangePasswordState,
  typeof setInvalidPassword,
  typeof resetAllPasswordResetState,
]>

/**
 * Requests CC Service to send an email to the user
 * containing a link for them to reset their password.
 */
export const requestResetLink = (email: string): AppThunkAction => async (dispatch) => {
  try {
    batch(() => {
      dispatch(setResetLinkError(false));
      dispatch(setResetLinkSent(false));
      dispatch(setResetLinkSending(true));
    });
    await request({
      method: 'PUT',
      url: '/users/reset-password',
      data: { email },
    });
  } catch (error) {
    const statusCode = getStatusCodeFromError(error);
    /*
      X - 404 if the email isn't found or isn't a native account
      X - 422 if the email address is syntactically invalid
      X - 429 if the email rate limit has been reached
      > - 500 if there is an internal error
      > - unknown error
    */
    if (statusCode === undefined || statusCode === 500) {
      logger.error('Error requesting password reset link', { error } as any);
      dispatch(setResetLinkError(true));
    }
  } finally {
    batch(() => {
      dispatch(setResetLinkSending(false));
      dispatch(setResetLinkSent(true));
    });
  }
};


/**
 * Sends a request to CC Service to update the user's password.
 */
export const resetPassword = (
  token: string, password: string,
): AppThunkAction => async (dispatch) => {
  try {
    dispatch(resetChangePasswordState());
    dispatch(setChangePasswordSending(true));
    await request({
      method: 'PUT',
      url: `/users/reset-password/${token}`,
      data: { password },
    });
    dispatch(setChangePasswordSuccess(true));
  } catch (error) {
    const statusCode = getStatusCodeFromError(error);
    /*
      X - 204 if the password is updated in the database
      X - 422 if the password is not a string
      X - 422 if the password length is < 8
      > - 400 if the token is invalid/expired
      > - 409 if the new password supplied matches any of the last 3 passwords used
      > - 500 internal server error
      > - unknown error
    */
    if (statusCode === 400) {
      dispatch(setChangePasswordLinkExpired(true));
    } else if (statusCode === 409) {
      // store the invalid password and show error in input
      // if user tries to use the same password
      dispatch(setInvalidPassword(password));
    } else {
      logger.error('Error resetting password', { error } as any);
      dispatch(setChangePasswordError(true));
    }
  } finally {
    dispatch(setChangePasswordSending(false));
  }
};
