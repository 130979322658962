import logger from 'utils/logger';
import { initialState as loginState } from 'reducers/loginReducer';
import { initialState as roomState } from 'reducers/roomReducer';
import { initialState as chatState } from 'reducers/chatReducer';
import { initialState as dashboardState } from 'reducers/dashboardReducer';
import { initialState as encoderState } from 'reducers/encoderReducer';
import { initialState as playersState } from 'reducers/playersReducer';
import { initialState as breakoutGroupsState } from 'reducers/breakoutGroupsReducer';
import { initialState as errorMessageState } from 'reducers/errorMessageReducer';
import { initialState as attendanceState } from 'reducers/attendanceReducer';
import { initialState as passwordResetState } from 'reducers/passwordResetReducer';
import { initialState as screenShareState } from 'reducers/screenShareReducer';
import { initialState as preferencesState } from 'reducers/preferencesReducer';
import { initialState as debugState } from 'reducers/debugReducer';
import { initialState as audioState } from 'reducers/audioReducer';
import { initialState as joinState } from 'reducers/joinReducer';
import { initialState as alertState } from 'reducers/alertReducer';
import { initialState as giphyState } from 'reducers/giphyReducer';
import { initialState as networkState } from 'reducers/networkReducer';
import { initialState as notificationState } from 'reducers/notificationReducer';
import { initialState as mobileState } from 'reducers/mobileReducer';
import { initialState as chalkboardState } from 'reducers/chalkboardReducer';
import { initialState as nudgeState } from 'reducers/nudgeReducer';
import { initialState as icebreakerState } from 'reducers/icebreakerReducer';
import { initialState as filterState } from 'reducers/filterReducer';
import {
  persistConfig, resetPersistedStore, getPersistedStore, PersistedStore,
} from './localStoragePersistMiddleware';
import { StoreState } from './types';

/**
 * initialize redux state to include any values persisted in local storage
 */
const initState = () => {
  /**
   * Check the version of local storage and clear it if different
   * The version should be updated every time we make a change
   * to the shape of the data we save to localStorage
   */
  const version = localStorage.getItem('version');
  if (version !== appConfig.persistedStoreVersion) {
    resetPersistedStore();
    localStorage.setItem('version', appConfig.persistedStoreVersion);
  }

  const initialState: Omit<StoreState, 'router' | 'browser'> = {
    dashboardState,
    loginState,
    roomState,
    chatState,
    encoderState,
    playersState,
    breakoutGroupsState,
    errorMessageState,
    attendanceState,
    passwordResetState,
    screenShareState,
    preferencesState,
    debugState,
    audioState,
    joinState,
    alertState,
    giphyState,
    networkState,
    notificationState,
    mobileState,
    chalkboardState,
    nudgeState,
    icebreakerState,
    filterState,
  };

  const persisted = getPersistedStore();

  if (!persisted) {
    // localStorage was cleared or nothing persisted
    // return default state without modification
    return initialState;
  }

  try {
    // loop over keys in persisConfig to know which values to replace
    Object.keys(persistConfig).forEach((state) => {
      persistConfig[state as keyof typeof persistConfig]?.forEach((key) => {
        if (persisted[key as keyof PersistedStore] !== undefined) {
          (initialState[state as keyof typeof initialState] as Record<string, any>) = {
            ...initialState[state as keyof typeof initialState],
            [key]: persisted[key as keyof PersistedStore],
          };
        }
      });
    });
    return initialState;
  } catch (error) {
    logger.warn('Error initializing state from localStorage', { error } as any);
    return initialState;
  }
};

export default initState;
