/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { string } from 'prop-types';
import Loader from 'components/Loader/Loader';
import { CSS } from 'types/css';

const containerStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
};

const loaderStyles: CSS = {
  mb: '3rem',
};

interface Props {
  message?: string,
  selenium?: string
}

// place holder loading component
const Loading = ({ message, selenium }: Props) => (
  <div sx={containerStyles}>
    <Loader sx={loaderStyles} />
    {message ? <p data-selenium={selenium}>{message}</p> : null}
  </div>
);

Loading.propTypes = {
  message: string,
};

Loading.defaultProps = {
  message: '',
};

export default Loading;
