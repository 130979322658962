import LivelyChat, { LivelyEmojiInfo } from '@livelyvideo/chat-core';
import { v4 as uuidv4 } from 'uuid';
import logger from 'utils/logger';
import request, { chatRequest } from 'utils/request';
import { handleError } from 'actions/sharedActions';
import { SoundFileEnum } from 'utils/soundPlayer';
import {
  LivelyChatMessage, AppThunkAction,
  StoreState, ChatMetadata, User, AppThunkDispatch, TempChatMessage,
  RealChatMessage, ConversationId,
  CONVO_ID_NOT_SELECTED, CONVO_ID_EVERYONE, CHAT_DM_PREFIX, CHAT_BG_PREFIX,
} from 'store/types';
import { findUserGroupId } from 'utils/breakoutGroupUtils';
import { MakeActionType } from 'utils/typeUtils';
import {
  isBreakoutGroupChatMeta, isDMChatMeta, isEveryoneChatMeta,
} from 'utils/typePredicates';
import { CCServiceSoundPreferencesEnum } from 'utils/ccService/types';
import mixpanel from 'utils/mixpanel';
import { batch } from 'react-redux';
import { getMessageFromError, getStatusCodeFromError } from 'utils/errorUtils';
import RouteEnum from 'utils/routeEnum';

export const reducerName = 'chatState' as const;
export const SET_CHAT_WS = `${reducerName}/SET_CHAT_WS` as const;
export const SET_UNSEEN_MESSAGE = `${reducerName}/SET_UNSEEN_MESSAGE` as const;
export const CLEAR_UNSEEN_MESSAGES = `${reducerName}/CLEAR_UNSEEN_MESSAGES` as const;
export const UPDATE_INPUT = `${reducerName}/UPDATE_INPUT` as const;
export const ADD_CHAT_MESSAGE = `${reducerName}/ADD_CHAT_MESSAGE` as const;
export const DELETE_CHAT_MESSAGE = `${reducerName}/DELETE_CHAT_MESSAGE` as const;
export const REPLACE_CHAT_MESSAGE = `${reducerName}/REPLACE_CHAT_MESSAGE` as const;
export const SET_CHAT_HISTORY = `${reducerName}/SET_CHAT_HISTORY` as const;
export const TOGGLE_SHOW_EMOJIS = `${reducerName}/TOGGLE_SHOW_EMOJIS` as const;
export const SET_EMOJIS = `${reducerName}/SET_EMOJIS` as const;
export const TOGGLE_CHAT_VISIBLE = `${reducerName}/TOGGLE_CHAT_VISIBLE` as const;
export const SET_CURRENT_CONVERSATION = `${reducerName}/SET_CURRENT_CONVERSATION` as const;
export const SET_RECENT_MESSAGE = `${reducerName}/SET_RECENT_MESSAGE` as const;
export const SET_PARSED_LIMIT = `${reducerName}/SET_PARSED_LIMIT` as const;
export const SET_SUBMIT_DISABLED = `${reducerName}/SET_SUBMIT_DISABLED` as const;
export const STOP_CHAT = `${reducerName}/STOP_CHAT` as const;

const dummyMessage: RealChatMessage = {
  id: '',
  actor: {
    avatar: '',
    color: '',
    id: '',
    role: '',
    roomuserMetadata: null,
    url: '',
    username: '',
    userMetadata: {
      displayName: '',
    },
  },
  message: '',
  timestamp: Math.floor(Date.now() / 1000),
  whisper: false,
  metadata: {
    tempId: '',
    senderUserId: '',
    recipientUserId: '',
  },
  emoticons: [],
  links: [],
  mentions: [],
  mode: '',
  pin: false,
  positions: {},
  recipient: null,
  tags: [],
  version: 'v1',
  length: 0,
};

/**
 * Adds a chat message to the history.
 */
export const addChatMessage = (message: LivelyChatMessage) => ({
  type: ADD_CHAT_MESSAGE,
  payload: { message },
});

/**
 * Replaces a message in state with the new one by comparing their tempIds.
 * @param {object} message
 */
export const replaceChatMessage = (message: LivelyChatMessage) => ({
  type: REPLACE_CHAT_MESSAGE,
  payload: { message },
});

/**
 * Updates an existing message in state or, if the message is not already there,
 * adds the new message to state.
 */
export const updateOrAddMessageToState = (
  newMessage: LivelyChatMessage,
): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { messages } = state.chatState;
  const newMessageTempId = newMessage.metadata?.tempId;
  let shouldReplace = false;
  if (newMessageTempId) {
    const tempMessageIndex = messages.findIndex((message) => (
      message.metadata?.tempId === newMessageTempId
    ));
    shouldReplace = tempMessageIndex > -1;
  }
  dispatch(shouldReplace ? replaceChatMessage(newMessage) : addChatMessage(newMessage));
};

/**
 * Sets the chat history to state.
 * @param {array} messages
 */
export const setHistory = (messages: LivelyChatMessage[]) => ({
  type: SET_CHAT_HISTORY,
  payload: { messages },
});

export const setChatWs = (chatClient: LivelyChat) => ({
  type: SET_CHAT_WS,
  payload: { chatClient },
});

export const stopChat = () => ({
  type: STOP_CHAT,
});

export const setUnseenMessageByIds = (messageId: string, conversationId: ConversationId) => ({
  type: SET_UNSEEN_MESSAGE,
  payload: {
    messageId,
    conversationId,
  },
});

export const setRecentMessage = (message: LivelyChatMessage | null) => ({
  type: SET_RECENT_MESSAGE,
  payload: { message },
});

export const resetRecentMessage = () => (
  setRecentMessage(null)
);

/**
 * cleats unseen message to remove the new message divider
 */
export const clearUnseenMessages = (conversationId: ConversationId) => ({
  type: CLEAR_UNSEEN_MESSAGES,
  payload: { conversationId },
});

/**
 * update the chatInput from an input event
 */
export const updateChatInput = (message: string) => ({
  type: UPDATE_INPUT,
  payload: { message },
});

export const setSubmitDisabled = (submitDisabled: boolean) => ({
  type: SET_SUBMIT_DISABLED,
  payload: { submitDisabled },
});

export const setParsedLimitReached = (parsedLimitReached: boolean) => ({
  type: SET_PARSED_LIMIT,
  payload: { parsedLimitReached },
});

/**
 * Set the current conversation
 */
export const selectConversation = (conversationId: ConversationId) => ({
  type: SET_CURRENT_CONVERSATION,
  payload: { conversationId },
});

/**
 * Deselect the current conversation
 */
export const deselectConversation = () => selectConversation(CONVO_ID_NOT_SELECTED);

/**
 * opens and closes the emoji selector
 */
export const toggleShowEmojis = (showEmojis: boolean) => ({
  type: TOGGLE_SHOW_EMOJIS,
  payload: { showEmojis },
});

export const setEmojis = (emojis: { [category: string]: { [id: string]: LivelyEmojiInfo } }) => ({
  type: SET_EMOJIS,
  payload: { emojis },
});

export const toggleChatVisible = () => ({
  type: TOGGLE_CHAT_VISIBLE,
});

export type ChatAction = MakeActionType<[
  typeof addChatMessage,
  typeof replaceChatMessage,
  typeof setHistory,
  typeof setChatWs,
  typeof stopChat,
  typeof setRecentMessage,
  typeof resetRecentMessage,
  typeof setUnseenMessageByIds,
  typeof clearUnseenMessages,
  typeof updateChatInput,
  typeof setSubmitDisabled,
  typeof setParsedLimitReached,
  typeof selectConversation,
  typeof toggleShowEmojis,
  typeof setEmojis,
  typeof toggleChatVisible,
]>

/**
 * Adds emoji to chatInput
 */
export const addEmojiToInput = (emoji: string): AppThunkAction => (dispatch, getState) => {
  const { chatInput } = getState().chatState;
  dispatch(updateChatInput(`${chatInput}${emoji}`));
};

/**
 * fetches the emoji options
 */
export const getEmojis = (): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  const userName = state.loginState.user?.userId || '';
  const { chatClient } = state.chatState;

  chatClient?.getAllEmoticons(
    roomId || '',
    userName,
    (error: Error, data: { emoticons: { [id: string]: LivelyEmojiInfo } }) => {
      if (error) {
        const errorMessage = getMessageFromError(error);
        return logger.warn('Error getting chat emoticons', { errorMessage });
      }

      if (data) {
        const { emoticons } = data;
        const ids = Object.keys(emoticons);

        const sortedEmojis = ids.reduce((result: {
          [category: string]: { [id: string]: LivelyEmojiInfo }
        }, id: string) => {
          const { category } = emoticons[id].metadata;

          if (!result[category]) {
            result[category] = {};
          }

          result[category][id] = emoticons[id];

          return result;
        }, {});

        return dispatch(setEmojis(sortedEmojis));
      }

      return null;
    },
  );
};

/**
 * sets the oldest unseen message ID to state so the
 * app knows where to put the new message divider
 */
export const setUnseenMessage = (
  msg: LivelyChatMessage,
  conversationId: ConversationId,
  didScrollUp?: boolean,
): AppThunkAction => (dispatch, getState) => {
  const {
    loginState: { user: currentUser },
    roomState: { ownerId },
    chatState: { selectedConversationId, isChatVisible },
    breakoutGroupsState: { isLaunched, launchedGroups },
  } = getState();

  let currentGroupId = null;
  if (isLaunched) {
    Object.keys(launchedGroups).forEach((id) => {
      if (launchedGroups[id].users.includes(currentUser?.userId as string)) {
        currentGroupId = id;
      }
    });
  }

  const isDiffUser = msg.actor.id !== currentUser?.userId;
  const isNotDisplayed = !isChatVisible || didScrollUp || !selectedConversationId || (
    (isBreakoutGroupChatMeta(msg.metadata) && `${CHAT_BG_PREFIX}${msg.metadata?.breakoutRoomId}` !== selectedConversationId)
    || (isDMChatMeta(msg.metadata) && `${CHAT_DM_PREFIX}${msg.actor.id}` !== selectedConversationId)
    || (isEveryoneChatMeta(msg.metadata) && selectedConversationId !== CONVO_ID_EVERYONE)
  );

  let shouldNotify = false;
  if (isDiffUser && isNotDisplayed) { // conditions that should always be met
    if (isBreakoutGroupChatMeta(msg.metadata)) { // you are the owner or the message goes to the group you are in
      shouldNotify = (currentUser && (currentUser.userId === ownerId)) || (currentGroupId === msg.metadata?.breakoutRoomId);
    } else {
      shouldNotify = true;
    }
  }

  if (shouldNotify) {
    dispatch(setUnseenMessageByIds(msg.id, conversationId));
  }
};

export const playChatMessageSound = (msg: LivelyChatMessage): AppThunkAction => (_, getState) => {
  const state = getState();
  const { user } = state.loginState;
  const currentUserId = user?.userId || '';
  const { pathname }: { pathname: string } = state.router.location;
  const isRoom = RouteEnum.ROOM.matchPath(pathname);
  const { launchedGroups } = state.breakoutGroupsState;
  const currentUserGroupId = findUserGroupId(launchedGroups, currentUserId);
  const everyoneChatSoundEnabled = state.preferencesState
    .soundPreferences[CCServiceSoundPreferencesEnum.ROOM_CHAT_MESSAGE]?.enabled;
  const directChatSoundEnabled = state.preferencesState
    .soundPreferences[CCServiceSoundPreferencesEnum.DIRECT_CHAT_MESSAGE]?.enabled;
  const groupChatSoundEnabled = directChatSoundEnabled;
  const { soundPlayer } = state.audioState;

  if ((isRoom && msg?.actor?.id !== currentUserId) && (
    (isEveryoneChatMeta(msg?.metadata) && everyoneChatSoundEnabled)
    || (isBreakoutGroupChatMeta(msg?.metadata) && msg.metadata.breakoutRoomId === currentUserGroupId && groupChatSoundEnabled)
    || (isDMChatMeta(msg?.metadata) && msg.metadata.recipientUserId === currentUserId && directChatSoundEnabled)
  )) {
    soundPlayer.play(SoundFileEnum.NEW_CHAT_MESSAGE);
  }
};

/**
 * starts the chat client and adds listeners
 */
export const startChatClient = (roomId: string): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { token: roomToken } = state.roomState.livelyRoomToken;
  const { user } = state.loginState;
  const chatOptions = {
    room: roomId,
    host: appConfig.chatHost,
    wshost: appConfig.chatWsHost,
    insecure: appConfig.chatInsecure,
    roleHistory: true,
    userMetadata: {
      displayName: true,
    },
  };

  const authOptions = {
    bootstrap: { token: roomToken },
  };

  const chatClient = new LivelyChat(chatOptions, authOptions);

  chatClient.on('history', async (history) => {
    const response = await chatRequest.get<{ whispers: any[][] }>(`/rooms/${roomId}/users/${user?.userId}/whispers`);
    const whispers = response.data.whispers[1];
    const formattedWhispers: LivelyChatMessage[] = whispers.map((whisper) => ({
      ...dummyMessage,
      actor: {
        ...dummyMessage.actor,
        id: whisper[14],
        role: whisper[4][2],
        userMetadata: whisper[4][6],
      },
      id: whisper[1],
      message: whisper[3],
      metadata: whisper[12],
      timestamp: whisper[2],
      version: whisper[0],
      whisper: true,
    }));
    const totalHistory = [...history.messages, ...formattedWhispers];
    dispatch(setHistory(totalHistory));
  });


  chatClient.on('chat', (msg) => {
    batch(() => {
      dispatch(updateOrAddMessageToState(msg));
      dispatch(setRecentMessage(msg));
      dispatch(playChatMessageSound(msg));
      if (msg.whisper && isDMChatMeta(msg?.metadata)) {
        dispatch(setUnseenMessage(msg, `${CHAT_DM_PREFIX}${msg.metadata.senderUserId}` as const));
      } else if (isBreakoutGroupChatMeta(msg?.metadata)) {
        dispatch(setUnseenMessage(msg, `${CHAT_BG_PREFIX}${msg.metadata.breakoutRoomId}` as const));
      } else {
        dispatch(setUnseenMessage(msg, CONVO_ID_EVERYONE));
      }
    });
  });

  chatClient.on('error', (error) => {
    const errorMessage = getMessageFromError(error);
    logger.warn('Chat Error', { errorMessage });
  });

  dispatch(setChatWs(chatClient));
};

/**
 * ends the chat session
 */
export const stopChatClient = (): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { chatClient } = state.chatState;

  if (chatClient && chatClient.destroy) {
    chatClient.destroy();
    dispatch({ type: STOP_CHAT });
  }
};

type Command = 'say' | 'whisper';

interface TempMessageData {
  metadata: ChatMetadata,
  command: Command,
  message: string,
  overrideUrl: string,
  tempId: string,
  conversationId: string,
  currentUser: User | null,
}

/**
 * Helper function to format the chat message's properties
 * consistently depending on what type of message it is.
 *
 * This allows placeholder messages and "real" chat messages to
 * have similar data structures.
 */
export const formatMessageData = (state: StoreState): TempMessageData => {
  const roomId = state.roomState.room.id;
  const { chatInput } = state.chatState;
  const conversationId = state.chatState.selectedConversationId;
  const { user: currentUser } = state.loginState;

  const tempId = uuidv4();
  const metadata: ChatMetadata = {
    tempId,
    senderUserId: currentUser?.userId || '',
  };
  let command: Command;
  if (conversationId === CONVO_ID_EVERYONE) {
    command = 'say';
  } else if (conversationId.startsWith(CHAT_BG_PREFIX)) {
    metadata.breakoutRoomId = conversationId.replace(CHAT_BG_PREFIX, '');
    command = 'say';
  } else {
    metadata.recipientUserId = conversationId.replace(CHAT_DM_PREFIX, '');
    command = 'whisper';
  }

  // this tempId allows the real message to replace a placeholder message in state
  metadata.tempId = tempId;

  const message = chatInput.trim();
  const overrideUrl = `${appConfig.ccServiceApi}/api/chalkcast/v1/rooms/${roomId}/chat`;

  return {
    metadata,
    command,
    message,
    overrideUrl,
    tempId,
    conversationId,
    currentUser,
  };
};

/**
 * Creates a new message object to temporarily add to state while waiting for the
 * history event to return the real message. Also used to display a "retry" message
 * when a message fails to send.
 *
 * This allows us to assume that the chat message worked for general chat messages
 * and also to display an error message locally if the message failed.
 *
 */
export const addPlaceholderMessageToState = ({
  message,
  tempId,
  metadata,
  currentUser,
  command,
}: TempMessageData,
unsent = false): AppThunkAction => (dispatch) => {
  const msg: TempChatMessage = {
    id: tempId,
    actor: {
      id: currentUser?.userId || '',
      userMetadata: {
        displayName: currentUser?.displayName || '',
      },
    },
    message,
    timestamp: Math.floor(Date.now() / 1000),
    whisper: command === 'whisper',
    metadata,
    unsent,
  };

  dispatch(updateOrAddMessageToState(msg));
};

/**
 * Handles success or failure of sending a chat message.
 * The tempId is the id of the temporary message that was added to state.
 * The 'chat' event is called BEFORE the send message callback.
 */
const createSendMessageCallBack = ({
  dispatch,
  tempMessageData,
  retry = false,
}: {
  dispatch: AppThunkDispatch,
  tempMessageData: TempMessageData,
  retry?: boolean,
}) => (
  error?: Error,
  res?: { // ? Unknown type
    message: string
    level: string
  }[],
) => {
  /* handle error cases */
  if (error) {
    const errorMessage = getMessageFromError(error);
    logger.warn('Error sending chat', { errorMessage });

    // if not a retry (new message failed to send)
    if (!retry) {
      // add the failed message to the history for the user to retry
      return dispatch(addPlaceholderMessageToState(tempMessageData, true));
    }

    /* @todo: Handle case where retry fails.
      For now this, doing nothing here is ok, since the
      error message just stays in the chat */
    return null;
  }

  if (res?.length && res[0]?.level === 'warn') {
    /* Warn will happen if text is too long.
      This shouldn't happen, since we validate length before sending */
    logger.warn('Chat message length is too long', { length: res[0].message.length });
  }

  // on success: do nothing, since this logic is handled in the chat event
  return null;
};

export const sendChatMessage = (): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { chatInput, chatClient } = getState().chatState;

  if (!chatInput.trim().length) return;

  const tempMessageData = formatMessageData(state);
  const {
    metadata, command, message, overrideUrl,
  } = tempMessageData;

  // assume the request worked
  dispatch(updateChatInput(''));
  dispatch(addPlaceholderMessageToState(tempMessageData));

  // send chat
  chatClient?.command({
    metadata, command, message, overrideUrl,
  }, createSendMessageCallBack({
    dispatch, tempMessageData,
  }));

  let userType: 'Everyone' | 'User' | 'Group' = 'Everyone';
  if (isDMChatMeta(metadata)) {
    userType = 'User';
  } else if (isBreakoutGroupChatMeta(metadata)) {
    userType = 'Group';
  }
  mixpanel.track('Chat Send', {
    'User Type': userType,
  });
};

/**
 * Fetches the length of the chat after it has been parsed
 * This length is different than the true length because of @ mentions, links, etc
 */
export const fetchParsedLength = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  const { chatInput } = state.chatState;

  dispatch(setSubmitDisabled(true));

  try {
    const response = await request({
      method: 'POST',
      url: `/rooms/${roomId}/validate-chat`,
      data: {
        chat: chatInput?.trim(),
      },
    });
    if (response?.status === 200) {
      dispatch(setParsedLimitReached(false));
      dispatch(setSubmitDisabled(false));
    }
  } catch (error) {
    if (getStatusCodeFromError(error) === 422) {
      // get the latest input in case user has drastically changed message while response arrived
      const { chatInput: latestChatInput } = getState().chatState;
      if (!latestChatInput || latestChatInput.length < 400) {
        dispatch(setParsedLimitReached(false));
      } else {
        dispatch(setParsedLimitReached(true));
      }
    } else {
      dispatch(handleError('Error fetching parsed length', { error }));
    }
  }
};

/**
 * Retry sending a message that failed to send.
 * @param {object} message
 */
export const resendFailedMessage = (msg: TempChatMessage): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { chatClient } = state.chatState;
  let tempMessageData = formatMessageData(state);

  // preserve as much info from the original message as possible
  const { message, id: tempId, metadata } = msg;
  tempMessageData = {
    ...tempMessageData, message, tempId, metadata,
  };
  const { command, overrideUrl } = tempMessageData;

  if (!message.trim().length) return;

  chatClient?.command({
    message,
    metadata,
    command,
    overrideUrl,
  }, createSendMessageCallBack({
    dispatch,
    tempMessageData,
    retry: true,
  }));
};

/**
 * Opens chat, selects conversation, and clears unseen message when clicking the chat notification
 */
export const handleNotificationClick = (convoId: ConversationId): AppThunkAction => (dispatch, getState) => {
  const { isChatVisible, unseenMessages } = getState().chatState;
  if (!isChatVisible) {
    dispatch(toggleChatVisible());
  }
  dispatch(selectConversation(convoId));
  if (unseenMessages[convoId]) {
    dispatch(clearUnseenMessages(convoId));
  }
};
