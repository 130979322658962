import { MakeActionType } from 'utils/typeUtils';
import retry from 'utils/retry';
import validateDisplayName from 'utils/validateDisplayName';
import mixpanel, { asOnOff } from 'utils/mixpanel';
import { AppThunkAction, LoginTypesEnum } from 'store/types';
import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import logger from 'utils/logger';
import { getMessageFromError } from 'utils/errorUtils';
import RouteEnum from 'utils/routeEnum';
import { getIsLockedFromJoining } from 'selectors';
import {
  JOIN_ROOM_COMPLETE, JOIN_ROOM_START, sendWebsocketMessage,
} from './roomActions';
import { setJoinedRoom, submitDisplayNameUpdate } from './loginActions';
import { handleError, setJoinError } from './sharedActions';


export const reducerName = 'joinState' as const;
export const SET_ROOM_NAME_FOR_REMOVED_MODAL = `${reducerName}/SET_ROOM_NAME_FOR_REMOVED_MODAL` as const;
export const TOGGLE_IS_NEW_DEVICE = `${reducerName}/TOGGLE_IS_NEW_DEVICE` as const;

export const setRoomNameForRemovedModal = (roomName: string) => ({
  type: SET_ROOM_NAME_FOR_REMOVED_MODAL,
  payload: { roomName },
});

export const toggleIsNewDevice = (isNewDevice?: boolean) => ({
  type: TOGGLE_IS_NEW_DEVICE,
  payload: { isNewDevice },
});

export type JoinAction = MakeActionType<[
  typeof setRoomNameForRemovedModal,
  typeof toggleIsNewDevice,
]>

/**
 * Sends request to CC Service via websocket to join the room.
 * If the user has edited their displayName, updates their displayName in CC Service.
 */
export const joinRoom = (roomId: string): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { displayName } = state.loginState.participantForm;
  const currentDisplayName = state.loginState.user?.displayName;
  const { loginType } = state.loginState;
  const { isVideoPaused, isAudioMuted } = state.encoderState;
  const { isWSReady } = state.roomState;
  const isLockedFromJoining = getIsLockedFromJoining(state);

  if (!isWSReady) {
    // The button should be disable so we should never hit this case
    logger.warn('User tried to join before websocket was ready', { isWSReady });
    return;
  }

  try {
    mixpanel.track('Join Session', {
      Video: asOnOff(!isVideoPaused),
      Microphone: asOnOff(!isAudioMuted),
    });

    dispatch({ type: JOIN_ROOM_START });
    logger.info('Joining room');
    // anonymous users cannot join a room
    if (loginType === LoginTypesEnum.ANON_USER) {
      logger.warn('Could not join room because anonymous users cannot join a room');
      dispatch(setJoinError('Anonymous users cannot join a room'));
      return;
    }
    // update participant's display name
    if (loginType === LoginTypesEnum.TEMP_USER && displayName !== currentDisplayName) {
      // update Temp user's display name
      if (validateDisplayName(displayName)) {
        logger.warn('Could not join room because invalid display name');
        dispatch(setJoinError('Invalid display name'));
        return;
      }
      await dispatch(submitDisplayNameUpdate());
    }
    // can't join room without room token or websocket
    const { socket, livelyRoomToken } = getState().roomState;

    if (!livelyRoomToken) {
      logger.warn('Could not join room because user has no livelyRoomToken');
      dispatch(setJoinError('Cannot join without a room token'));
      return;
    }

    if (!socket) {
      logger.warn('Could not join room because websocket is closed');
      dispatch(setJoinError('Cannot join on a closed websocket'));
      return;
    }

    await retry(() => sendWebsocketMessage(socket, isLockedFromJoining ? 'knock' : 'join'), { retries: 8 });

    batch(() => {
      dispatch(setJoinedRoom(roomId));
      dispatch(push(RouteEnum.ROOM.encodePath({ urlParams: { roomId } })));
    });
  } catch (error) {
    batch(() => {
      const msg = 'Error joining room';
      dispatch(handleError(msg, { error }));
      dispatch(setJoinError(getMessageFromError(error)));
    });
  } finally {
    dispatch({ type: JOIN_ROOM_COMPLETE });
  }
};
