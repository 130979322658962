import { createResponsiveStateReducer, IBreakPointResults } from 'redux-responsive';
import { BreakpointObj, breakpointObj } from 'theme/ui/breakpoints';

export default createResponsiveStateReducer(breakpointObj, {
  // this triggers .is._7 as true rather than .is.infinity when the browser widths exceeds the ._7 breakpoint
  infinity: '_7',

  // add minWidth and maxWidth properties: these map to the mediaMinWidth & mediaMaxWidth in styles/styles.ts
  extraFields: ({ greaterThan }) => {
    const minWidth = {} as IBreakPointResults<BreakpointObj>;
    const maxWidth = {} as IBreakPointResults<BreakpointObj>;
    (Object.keys(greaterThan) as (keyof typeof greaterThan)[]).forEach((key) => {
      minWidth[key] = greaterThan[key];
      maxWidth[key] = !greaterThan[key];
    });
    return { minWidth, maxWidth };
  },
});
