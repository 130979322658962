/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ElementType, ReactNode } from 'react';
import { CSS } from 'types/css';

const contentStyles: CSS = {
  margin: '0 auto',
  maxWidth: '1200px',
  px: ['2rem', '3.6rem', '4.8rem', '6.4rem'],
};

const notDenseStyles: CSS = {
  py: ['16px', '24px', '32px'],
};

interface Props {
  dense?: boolean,
  component?: ElementType,
  children?: ReactNode,
}

const Content = ({
  component: Component = 'div',
  dense = false,
  children,
}: Props) => {
  const styles = dense ? contentStyles : { ...contentStyles, ...notDenseStyles };
  return (
    <Component
      sx={{ ...styles }}
    >
      {children}
    </Component>
  );
};

export default Content;
