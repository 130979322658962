
const PRESET_GIFS = [
  'Vfie0DJryAde8', // Poke cat
  'qlrBlSDevEdFeW5JwV', // Andy NAILED IT
  'oMGQr23tRZogU', // Serena Williams Phone Down
  'u7hjTwuewz3Gw', // Kanye Approves
  'VdAvVcQLJDwKKDUDJR', // Yodel HEY
  '3o6fJb3wLZeWqHu5os', // Leah Remini Focus
];

export default PRESET_GIFS;
