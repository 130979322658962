
interface DropDuplicatesOptions<ItemType, MappingType = ItemType> {
  keep?: 'first' | 'last' | 'none'; // similar to Python's pandas DataFrame.drop_duplicates()
  mapping?: (item: ItemType) => MappingType;
}


const defaultOptions: DropDuplicatesOptions<unknown> = {
  keep: 'first',
};

/** Placeholder for items to be dropped when `keep: 'none'` given */
const DROP_ME = Symbol('DROP_ME');

/**
 * Take an array and clean out duplicates. Returns new array (does not edit array in place).
 *
 * Options:
 * * keep: `'first'` keeps the first of a duplicated set (default).
 * `'last'` keeps the last option of a duplicated set.
 * `'none'` removes all duplicated items.
 *
 * * mapping: A function to map each item to a different value for uniqueness validation.
 * Example: With an array of user objects, use mapping `(user) => user.id` to validate
 * uniqueness only on `id`.
 *
 * @example
 * const nums = [1,2,2,3,4]
 * dropDuplicates(nums) // [1,2,3,4]
 * dropDuplicates(nums, { keep: 'none' }) // [1,3,4]
 *
 * const users = [
 *  { id: 1, name: 'User 1' },
  * { id: 2, name: 'User 2A' },
  * { id: 2, name: 'User 2B' },
  * { id: 3, name: 'User 3' },
  * { id: 4, name: 'User 3' },
 * ]
 *
 * // returns same array since object references are unique
 * dropDuplicates(users)
 *
 * // removes User 2B (keep: 'first' default)
 * dropDuplicates(users, { mapping: (user) => user.id })
 *
 * // removes User 2A
 * dropDuplicates(users, { keep: 'last', mapping: (user) => user.id })
 *
 * // removes User 2A and User 2B
 * dropDuplicates(users, { keep: 'none', mapping: (user) => user.id })
 *
 * // removes User 3 with id 4
 * dropDuplicates(users, { mapping: (user) => user.name })
 */
export default function dropDuplicates<ItemType, MappingType = ItemType>(
  array: Array<ItemType>,
  options?: DropDuplicatesOptions<ItemType, MappingType>,
): Array<ItemType> {
  const keep = options?.keep || defaultOptions.keep;
  const mapping = (options?.mapping || defaultOptions.mapping) as (
    (item: ItemType) => MappingType | undefined
  );

  const hasMapping = typeof mapping === 'function';

  const map = new Map<ItemType | MappingType, ItemType | typeof DROP_ME>();
  array.forEach((item) => {
    const key = hasMapping ? mapping(item) : item;
    if (key && map.has(key)) {
      switch (keep) {
        case 'first': // map already has first entry
          break;
        case 'last': // set to latest item found
          map.set(key, item);
          break;
        case 'none': // to be filtered afterwards
          map.set(key, DROP_ME);
          break;
        default:
          break;
      }
    } else if (key) {
      map.set(key, item);
    }
  });

  const newArr = [...map.values()];

  return (keep === 'none' ? newArr.filter((val) => val !== DROP_ME) : newArr) as Array<ItemType>;
}
