/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  setIsAudioDebugModalOpen, toggleAllDebugTools, toggleBitrateOptionsModal, toggleDebugModal,
  toggleGrafanaOptionsModal, toggleEnableAddMockParticipants, toggleEnableDeleteAllRooms, toggleEnableSandbox,
  toggleEnableArcade, toggleEnableChalkboardStream, toggleFiltersModal, toggleEmitVideoClientStats,
} from 'actions/debugActions';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal/Modal';
import ModalBody from 'components/ui/Modal/ModalBody';
import ModalFooter from 'components/ui/Modal/ModalFooter';
import ModalTitle from 'components/ui/Modal/ModalTitle';
import Switch from 'components/ui/Switch';
import useKeyCombo from 'hooks/useKeyCombo';
import useTypedSelector from 'hooks/useTypedSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAllDebugToolsEnabled, getIsDebugModalOpen, getMockParticipantsEnabled,
  getDeleteAllRoomsEnabled, getSandboxEnabled, getArcadeEnabled, getChalkboardStreamEnabled, getEmitVDCStatsEnabled,
} from 'selectors';
import { CSS } from 'types/css';
import { GREY_30 } from 'theme/ui/colors';
import mixpanel from 'utils/mixpanel';

const footerStyles: CSS = {
  justifyContent: 'flex-end',
};

const modalBodyChildrenStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-evenly',
  gap: '2rem',
};

const hrStyles: CSS = {
  display: 'block',
  width: '100%',
  height: '1px',
  border: 0,
  outline: 0,
  bg: GREY_30,
  borderRadius: '10px',
};

const localLogsContainerStyles: CSS = {
  my: '2rem',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2rem',
};

const localLogsHeaderStyles: CSS = {
  variant: 'text.h5',
  width: '100%',
};

const DEBUG_KEY_COMBO = ['b', 'u', 'g'];

export default function DebugModal() {
  const dispatch = useDispatch();
  const toggle = useCallback(() => dispatch(toggleDebugModal()), [dispatch]);
  const isOpen = useTypedSelector(getIsDebugModalOpen);
  const sandboxEnabled = useTypedSelector(getSandboxEnabled);
  const mockParticipantsEnabled = useTypedSelector(getMockParticipantsEnabled);
  const deleteAllRoomsEnabled = useTypedSelector(getDeleteAllRoomsEnabled);
  const allDebugToolsEnabled = useTypedSelector(getAllDebugToolsEnabled);
  const arcadeEnabled = useTypedSelector(getArcadeEnabled);
  const chalkboardStreamEnabled = useTypedSelector(getChalkboardStreamEnabled);
  const emitVDCStats = useTypedSelector(getEmitVDCStatsEnabled);

  const turnOnLocalLogs = () => {
    mixpanel.api.set_config({ debug: true });
    localStorage.setItem('debug', '*');
  };
  const turnOffLocalLogs = () => {
    mixpanel.api.set_config({ debug: false });
    localStorage.removeItem('debug');
  };

  useKeyCombo(DEBUG_KEY_COMBO, useCallback(() => {
    dispatch(toggleDebugModal());
  }, [dispatch]));

  return (
    <Modal isOpen={isOpen} toggle={toggle} addFooterSpacing>
      <ModalTitle toggle={toggle}>Debug Tools</ModalTitle>
      <ModalBody>
        <div sx={modalBodyChildrenStyles}>
          <Switch
            variant="dark"
            onChange={() => {
              if (allDebugToolsEnabled) turnOffLocalLogs();
              else turnOnLocalLogs();
              dispatch(toggleAllDebugTools());
            }}
            id="enable-all-debug-tools"
            label="Enable All Debug Tools"
            checked={allDebugToolsEnabled}
          />
          <Switch
            variant="dark"
            onChange={() => dispatch(toggleEnableSandbox())}
            id="enable-sandbox"
            label="Enable /sandbox Route"
            checked={sandboxEnabled}
          />
          <Switch
            variant="dark"
            onChange={() => dispatch(toggleEnableArcade())}
            id="enable-arcade"
            label="Enable Arcade"
            checked={arcadeEnabled}
          />
          <Switch
            variant="dark"
            onChange={() => dispatch(toggleEnableChalkboardStream())}
            id="enable-chalkboard-stream"
            label="Enable Chalkboard Stream"
            checked={chalkboardStreamEnabled}
          />
          <Switch
            variant="dark"
            onChange={() => dispatch(toggleEnableAddMockParticipants())}
            id="enable-mock-participants"
            label="Enable Mock Participants"
            checked={mockParticipantsEnabled}
          />
          <Switch
            variant="dark"
            onChange={() => dispatch(toggleEnableDeleteAllRooms())}
            id="enable-delete-all-rooms"
            label="Enable Delete All Rooms"
            checked={deleteAllRoomsEnabled}
          />
          <div sx={localLogsContainerStyles}>
            <p sx={localLogsHeaderStyles}>Local logs: &#40;Requires refresh&#41;</p>
            <Button onClick={turnOnLocalLogs}>
              Turn Local Logs ON
            </Button>
            <Button variant="secondaryLight" onClick={turnOffLocalLogs}>
              Turn Local Logs OFF
            </Button>
          </div>

          <div sx={localLogsContainerStyles}>
            <p sx={localLogsHeaderStyles}>Emit Video Client Stats: &#40;Requires refresh&#41;</p>
            <Switch
              variant="dark"
              onChange={() => dispatch(toggleEmitVideoClientStats(!emitVDCStats))}
              id="enable-emit-client-stats"
              label="Enable Emit Video Client Stats"
              checked={emitVDCStats}
            />
          </div>
          <hr sx={hrStyles} />
          <Button onClick={() => {
            dispatch(toggleDebugModal(false));
            dispatch(toggleGrafanaOptionsModal(true));
          }}
          >
            Open Grafana Options
          </Button>
          <Button onClick={() => {
            dispatch(toggleDebugModal(false));
            dispatch(toggleBitrateOptionsModal(true));
          }}
          >
            Open Bitrate Options
          </Button>
          <Button onClick={() => {
            dispatch(toggleDebugModal(false));
            dispatch(setIsAudioDebugModalOpen(true));
          }}
          >
            Open Audio Tools
          </Button>
          <Button onClick={() => {
            dispatch(toggleDebugModal(false));
            dispatch(toggleFiltersModal(true));
          }}
          >
            Open Filter Tools
          </Button>
        </div>
      </ModalBody>
      <ModalFooter sx={footerStyles}>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
