/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { setOverallGainRatio } from 'actions/audioActions';
import { useDispatch, useSelector } from 'react-redux';
import { getSoundPlayerOptions } from 'selectors';
import { BLACK, WHITE } from 'theme/ui/colors';
import { MAX_GAIN_RATIO, MIN_GAIN_RATIO } from 'utils/soundPlayer';


const ratioToLevelPercent = (r: number) => Math.round((r * 100) - 100);

const rangeMin = ratioToLevelPercent(MIN_GAIN_RATIO);
const rangeMax = ratioToLevelPercent(MAX_GAIN_RATIO);

export default function AudioDebugOverallGain() {
  const dispatch = useDispatch();
  const { overallGainRatio } = useSelector(getSoundPlayerOptions);

  const pcnt = ratioToLevelPercent(overallGainRatio);

  return (
    <div sx={{ mt: '1rem', mb: '1rem' }}>
      <div>Overall Gain (proportionally boost/lower all sounds)</div>
      <div sx={{
        display: 'flex', alignItems: 'center', mt: '0.5rem', '> *': { mr: '1rem' },
      }}
      >
        <div sx={{ width: '4rem' }}>
          {pcnt}
          %
        </div>
        <input
          type="range"
          value={pcnt}
          min={rangeMin}
          max={rangeMax}
          step={1}
          onChange={({ target: { value } }) => dispatch(setOverallGainRatio((parseInt(value, 10) + 100) / 100))}
        />
        <button
          type="button"
          sx={{
            border: '1px solid', borderColor: BLACK, bg: WHITE, borderRadius: '0.5rem', '&:focus': { outline: 'none' },
          }}
          onClick={() => dispatch(setOverallGainRatio(1))}
        >
          Reset
        </button>
      </div>
    </div>
  );
}
