/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ComponentPropsWithoutRef,
} from 'react';
import {
  HEADER_HEIGHT, hideOnGreaterThanTablet, topnav,
} from 'styles/styles';
import Button from 'components/ui/Button';
import useTypedSelector from 'hooks/useTypedSelector';
import { getLoginType } from 'selectors';
import { LoginTypesEnum } from 'store/types';
import useLoginContext from 'hooks/useLoginContext';
import { CSS } from 'types/css';
import LogoLink from 'components/Logo/LogoLink';
import HamburgerButton from './HamburgerButton';
import AccountButton from './AccountButton';

const containerStyles: CSS = {
  width: '100%',
  height: HEADER_HEIGHT,
  mb: '2.5rem',
};

const headerStyles: CSS = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: topnav,
  width: '100vw',
  height: HEADER_HEIGHT,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: '2rem',
};

const logInButtonStyles: CSS = {
  ml: 'auto',
};

const hamburgerButtonStyles: CSS = {
  mr: '1rem',
};

const LoginButton = () => {
  const { toggleLoginModal } = useLoginContext();
  return <Button sx={logInButtonStyles} onClick={toggleLoginModal}>Log in</Button>;
};

interface TopNavProps extends ComponentPropsWithoutRef<'header'> {
  toggleSideNav?: (() => void) | null,
  allowLogin?: boolean,
  showLogo?: boolean,
}


/**
 * Allowing the user to login via modal requires that the LoginContext be present,
 * but (for now), there is no LoginContext on the Dashboard, so this component
 * should not (even momentarily) try to allow the user to login from there.
 * This is the reason that the LoginButton is in its own component.
 */
export default function TopNav({ toggleSideNav = null, allowLogin = false, showLogo = false }: TopNavProps) {
  const loginType = useTypedSelector(getLoginType);
  const isAuthUser = loginType === LoginTypesEnum.AUTH_USER;

  /**
   * Only tries to access the login context if the TopNav has explicitly been allowed to do so.
   */
  const loginButton = allowLogin ? <LoginButton /> : null;

  return (
    <div sx={containerStyles}>
      <header sx={headerStyles}>
        {showLogo && <LogoLink />}
        {isAuthUser && toggleSideNav ? (
          <HamburgerButton sx={{ ...hamburgerButtonStyles, ...hideOnGreaterThanTablet }} onClick={toggleSideNav || (() => { })} />
        ) : null}
        {isAuthUser ? <AccountButton componentStyles={{ ml: 'auto' }} /> : loginButton}
      </header>
    </div>
  );
}
