
/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { CSS } from 'types/css';
import { ReactComponent as LogoSymbolSVG } from 'assets/logos/logo-c.svg';
import { ReactComponent as LogoWordSVG } from 'assets/logos/logo-chalkcast.svg';
import { ColorsEnumValue, TEAL_TRUE } from 'theme/ui/colors';
import { ComponentProps, Fragment } from 'react';
import Svg from 'components/ui/Svg';
import { Either } from 'utils/typeUtils';

type LogoProps = ComponentProps<'div'> & {
  fill?: ColorsEnumValue,
  symbolStyles?: CSS,
  wordStyles?: CSS
} & Either<{
  svgTitles?: string,
  'aria-hidden'?: true,
}, 'svgTitles', 'aria-hidden'>


export default function Logo({
  fill = TEAL_TRUE, svgTitles, 'aria-hidden': ariaHidden, symbolStyles, wordStyles, ...props
}: LogoProps) {
  const logoSymbolStyles = {
    fill, mr: '1rem', ...symbolStyles,
  };
  const logoWordStyles = {
    '& path': { fill }, ...wordStyles,
  };
  return (
    <div sx={{ display: 'flex', alignItems: 'center' }} {...props}>
      {svgTitles ? (
        <Fragment>
          <Svg svg={LogoSymbolSVG} title={svgTitles} sx={logoSymbolStyles} />
          <Svg svg={LogoWordSVG} title={svgTitles} sx={logoWordStyles} />
        </Fragment>
      ) : (
        <Fragment>
          <Svg svg={LogoSymbolSVG} aria-hidden={ariaHidden ?? true} sx={logoSymbolStyles} />
          <Svg svg={LogoWordSVG} aria-hidden={ariaHidden ?? true} sx={logoWordStyles} />
        </Fragment>
      )}
    </div>
  );
}
