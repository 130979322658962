
import { DISMISS_MOBILE_REDIRECT, MobileAction } from 'actions/mobileActions';
import produce from 'immer';
import { persistConfig, persistReducer } from 'store/localStoragePersistMiddleware';
import { MobileState } from 'store/types';

export const initialState: MobileState = {
  dismissedMobileRedirect: false,
};

const reducer = produce((draft: MobileState, action: MobileAction) => {
  switch (action.type) {
    case DISMISS_MOBILE_REDIRECT:
      draft.dismissedMobileRedirect = true;
      break;
  }
}, initialState);

export default persistReducer<MobileState>(reducer, persistConfig.mobileState);
