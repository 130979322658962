import HtmlIdEnum from './htmlIds';

/** Creates anchor element, appends to body and clicks it */
const downloadFromHref = (filename: string, href: string) => {
  const element = document.createElement('a');
  element.id = HtmlIdEnum.DUMMY_DOWNLOAD_LINK;
  element.style.display = 'none';
  element.href = href;
  document.body.appendChild(element);
  element.setAttribute('download', filename);

  element.click();

  document.body.removeChild(element);
};

export function downloadLocalImage(filename: string, dataUrl: string) {
  const href = dataUrl
    .replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
    .replace(/^data:application\/octet-stream/,
      'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png');

  downloadFromHref(filename, href);
}


export function downloadCSVData(filename: string, csvString: string) {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const href = URL.createObjectURL(blob);

  downloadFromHref(filename, href);
}
