import { v4 as uuidv4 } from 'uuid';
import logger from 'utils/logger';
import request from 'utils/request';
import { handleError, setBreakoutGroupsLaunchError, setBreakoutGroupsError } from 'actions/sharedActions';
import {
  AppThunkAction, GroupIdToNameMap, GroupIdToUserIdsMap, GroupSelectionTypeEnum, StoreState,
  NameAndUsersGroup,
  BreakoutGroupsSettings,
  CHAT_BG_PREFIX,
  LaunchedGroups,
} from 'store/types';
import type { MakeActionType } from 'utils/typeUtils';
import { findUserGroupId } from 'utils/breakoutGroupUtils';
import { FetchBreakoutGroupsResponse } from 'utils/ccService/types';
import { batch } from 'react-redux';
import { getCurrentUserGroupId } from 'selectors';
import mixpanel from 'utils/mixpanel';
import { KNParticipant } from 'utils/roomState/knats';
import { getMessageFromError } from 'utils/errorUtils';
import { OwnerBroadcastTypes, toggleOwnerJoinUnassignedGroup } from './roomActions';
import { setRoomAlert } from './alertActions';
import { clearUnseenMessages, deselectConversation } from './chatActions';

export const reducerName = 'breakoutGroupsState';
export const SET_GROUPS_TYPE = `${reducerName}/SET_GROUPS_TYPE` as const;
export const GROUPS_SETTINGS_CHANGE = `${reducerName}/GROUPS_SETTINGS_CHANGE` as const;
export const SET_GROUP = `${reducerName}/SET_GROUP` as const;
export const ADD_GROUP = `${reducerName}/ADD_GROUP` as const;
export const DELETE_GROUP = `${reducerName}/DELETE_GROUP` as const;
export const SET_GROUP_NAME = `${reducerName}/SET_GROUP_NAME` as const;
export const SET_GROUPS_LAUNCH_LOADING = `${reducerName}/SET_GROUPS_LAUNCH_LOADING` as const;
export const GROUPS_LAUNCH_COMPLETE = `${reducerName}/GROUPS_LAUNCH_COMPLETE` as const;
export const TOGGLE_GROUPS_SETUP_VISIBLE = `${reducerName}/TOGGLE_GROUPS_SETUP_VISIBLE` as const;
export const SET_GROUPS_RECALL_TIMER = `${reducerName}/SET_GROUPS_RECALL_TIMER` as const;
export const RECALL_GROUPS = `${reducerName}/RECALL_GROUPS` as const;
export const SET_IS_RECALLING_GROUPS = `${reducerName}/SET_IS_RECALLING_GROUPS` as const;
export const SET_BREAKOUT_GROUPS = `${reducerName}/SET_BREAKOUT_GROUPS` as const;
export const SET_JOIN_AUDIO_ID = `${reducerName}/SET_JOIN_AUDIO_ID` as const;
export const SET_RECENT_GROUPS_LOADING = `${reducerName}/SET_RECENT_GROUPS_LOADING` as const;
export const BG_UNASSIGNED = 'unassigned' as const;

export const setGroupsType = (
  groupsType: GroupSelectionTypeEnum, groups: GroupIdToUserIdsMap, groupNames: GroupIdToNameMap,
) => ({
  type: SET_GROUPS_TYPE,
  payload: {
    groupsType,
    groups,
    groupNames,
  },
});

export const setGroupSettingsChange = <Key extends keyof BreakoutGroupsSettings>(field: Key, value: BreakoutGroupsSettings[Key]) => ({
  type: GROUPS_SETTINGS_CHANGE,
  payload: {
    field,
    value,
  },
});

export const setGroup = (groupId: string, participants: string[]) => ({
  type: SET_GROUP,
  payload: {
    groupId,
    participants,
  },
});

/**
 * Adds a group
 */
export const addGroup = () => ({
  type: ADD_GROUP,
});

/**
 * Deletes a group from draft state
 */
export const deleteGroup = (groupId: string) => ({
  type: DELETE_GROUP,
  payload: {
    groupId,
  },
});

export const setGroupName = (name: string, groupId: string) => ({
  type: SET_GROUP_NAME,
  payload: {
    name,
    groupId,
  },
});

export const setGroupsLaunchLoading = (loadingMessage: string, launchComplete?: boolean) => ({
  type: SET_GROUPS_LAUNCH_LOADING,
  payload: {
    loadingMessage,
    launchComplete,
  },
});

export const groupsLaunchComplete = () => ({ type: GROUPS_LAUNCH_COMPLETE });

/**
 * toggles the groups setup modal
 */
export const toggleGroupsModal = () => ({
  type: TOGGLE_GROUPS_SETUP_VISIBLE,
});

export const setGroupsRecallTimer = (recallTimer: Date | null) => ({
  type: SET_GROUPS_RECALL_TIMER,
  payload: { recallTimer },
});

export const recallGroups = () => ({
  type: RECALL_GROUPS,
});

export const setBreakoutGroups = (
  launchedGroups: LaunchedGroups, groups: GroupIdToUserIdsMap, groupNames: GroupIdToNameMap,
) => ({
  type: SET_BREAKOUT_GROUPS,
  payload: {
    launchedGroups,
    groups,
    groupNames,
  },
});

export const setJoinAudioId = (groupId: string | null) => ({
  type: SET_JOIN_AUDIO_ID,
  payload: {
    joinAudioId: groupId,
  },
});

export const setIsRecallingGroups = (isRecallingGroups: boolean) => ({
  type: SET_IS_RECALLING_GROUPS,
  payload: { isRecallingGroups },
});

export const setRecentGroupsLoading = (recentGroupsLoading: boolean) => ({
  type: SET_RECENT_GROUPS_LOADING,
  payload: { recentGroupsLoading },
});

export type BreakoutGroupsAction = MakeActionType<[
  typeof setGroupsType,
  typeof setGroupSettingsChange,
  typeof setGroup,
  typeof addGroup,
  typeof deleteGroup,
  typeof setGroupName,
  typeof setGroupsLaunchLoading,
  typeof groupsLaunchComplete,
  typeof setGroupsRecallTimer,
  typeof toggleGroupsModal,
  typeof recallGroups,
  typeof setBreakoutGroups,
  typeof setBreakoutGroupsError,
  typeof setBreakoutGroupsLaunchError,
  typeof setJoinAudioId,
  typeof setIsRecallingGroups,
  typeof setRecentGroupsLoading,
]>


/**
 * NOTE: Utility, not action
 *
 * Evenly assigns users to random groups
 */
export const createRandomGroups = (groups: GroupIdToUserIdsMap, state: StoreState) => {
  // create copy of object so it can be mutated
  const users = { ...state.roomState.room.participants };
  const currentUser = state.loginState.user;

  // remove teacher from users object
  if (currentUser?.userId) delete users[currentUser.userId];

  const groupIds = Object.keys(groups);
  const userIds = Object.keys(users);

  while (userIds.length) {
    groupIds.forEach((groupId) => {
      if (userIds.length) {
        // create random index
        const randomIndex = Math.floor(Math.random() * userIds.length);

        // push random user into group
        groups[groupId].push(userIds[randomIndex]);

        // remove user from array
        userIds.splice(randomIndex, 1);
      }
    });
  }

  return groups;
};

/**
 * Sets the group type and number of groups
 */
export const setGroups = (
  groupsType = GroupSelectionTypeEnum.NOT_SET, numGroups = 0,
): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { ownerId, room: { participants } } = state.roomState;
  const groups: GroupIdToUserIdsMap = {};
  const groupNames: GroupIdToNameMap = {};
  for (let i = 0; i < numGroups; i++) {
    const groupId = uuidv4();
    groups[groupId] = [];
    groupNames[groupId] = `Group ${i + 1}`;
  }

  mixpanel.track('Group Type Create', {
    'Group Type': groupsType === GroupSelectionTypeEnum.RANDOM ? 'Random' : 'Manually',
    'Number of groups': numGroups,
    'Number of members': Math.max(Object.keys(participants).length - 1, 0), // minus 1 for owner
  });

  if (groupsType === GroupSelectionTypeEnum.RANDOM) {
    const randomGroups = createRandomGroups(groups, state);
    return dispatch(setGroupsType(groupsType, randomGroups, groupNames));
  }
  groups[BG_UNASSIGNED] = Object.keys(participants)
    .filter((id) => id !== ownerId);

  // Set empty groups to state
  return dispatch(setGroupsType(groupsType, groups, groupNames));
};

/**
 * Deletes a group that has been launched
 */
export const deleteLaunchedGroup = (groupId: string): AppThunkAction => async (
  dispatch, getState,
) => {
  const roomId = getState().roomState.room.id;
  try {
    await request({
      method: 'DELETE',
      url: `/rooms/${roomId}/breakout-rooms/${groupId}`,
    });
  } catch (error) {
    dispatch(handleError('Error deleting group: ', { error }));
  }
};

/**
 * Adds a participant to the unassigned column
 */
export const addNewParticipant = (userId: string): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { groups } = state.breakoutGroupsState;

  // check if userId already has a saved assignment
  const isUserIdAssigned = (() => {
    const userIdArrs = Object.values(groups);
    for (let i = 0; i < userIdArrs.length; i++) {
      if (userIdArrs[i].includes(userId)) {
        return true;
      }
    }
    return false;
  })();

  if (!isUserIdAssigned) {
    const unassigned = groups && groups[BG_UNASSIGNED] ? [...groups[BG_UNASSIGNED]] : [];
    const participants = [...unassigned, userId];
    dispatch(setGroup(BG_UNASSIGNED, participants));
  }
};

/**
 * creates groups and sends them to CC Service
 */
export const launchGroups = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const {
    roomState: {
      room: { id: roomId, participants },
    },
    breakoutGroupsState: {
      groups, groupNames,
    },
  } = state;
  const groupIds = Object.keys(groupNames);

  dispatch(setGroupsLaunchLoading('Creating groups...'));

  // create array of groups to be sent to CC Service
  const groupsToBeLaunched = groupIds.reduce((result: NameAndUsersGroup[], groupId: string) => {
    const groupObject = {
      name: groupNames[groupId],
      users: groups[groupId],
    };

    result.push(groupObject);

    return result;
  }, []);

  mixpanel.track('Groups Launch', {
    'Number of groups': groupIds.length,
    'Number of members': Math.max(Object.keys(participants).length - 1, 0), // minus one for owner
  });

  try {
    await request({
      method: 'POST',
      url: `/rooms/${roomId}/breakout-rooms-multiple`,
      data: {
        breakoutRooms: groupsToBeLaunched,
      },
    });

    dispatch(setGroupsLaunchLoading('Launch complete!'));

    // Set timeout for 1 second so "Launch complete!" doesn't immediately disappear
    setTimeout(() => {
      dispatch(groupsLaunchComplete());
    }, 1000);
  } catch (error) {
    dispatch(handleError('Error launching breakout groups: ', { error }));
    dispatch(setBreakoutGroupsLaunchError('Oops, something went wrong!'));
  }
};

/**
 * Updates all launched groups that have been modified in the breakout groups modal
 */
export const updateLaunchedGroups = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  const { groupNames, launchedGroups } = state.breakoutGroupsState;
  const groupIds = Object.keys(groupNames);

  try {
    dispatch(setGroupsLaunchLoading('Updating groups'));

    /* create new groups */
    const newGroups = groupIds.filter((id) => !launchedGroups[id]);

    const createGroupPromises = newGroups.map((id) => request({
      method: 'POST',
      url: `/rooms/${roomId}/breakout-rooms`,
      data: {
        name: groupNames[id],
      },
    }));

    await Promise.all(createGroupPromises);

    /* Update existing groups */

    /*
      TODO
      We probably need a new endpoint for updating existing groups
      - update names
      - updates users
    */
  } catch (error) {
    dispatch(handleError('Error updating breakout groups: ', { error }));
  }
};

/**
 * Removes a participant from the breakout
 * groups when they have left
 */
export const removeLeavingParticipant = (userId: string): AppThunkAction => (
  dispatch, getState,
) => {
  const state = getState();
  const { groups } = state.breakoutGroupsState;
  let foundGroupId = '';
  let participants: string[] = [];

  // remove user from groups in modal
  Object.keys(groups).forEach((groupId) => {
    if (foundGroupId) return;
    const index = groups[groupId].findIndex((id) => id === userId);
    if (index >= 0) {
      foundGroupId = groupId;
      participants = groups[groupId].filter((id) => id !== userId);
    }
  });

  if (!foundGroupId) return;
  dispatch(setGroup(foundGroupId, participants));
};

/**
 * Remove a participant from a launched group
 */
export const removeParticipantFromLaunchedGroup = (
  groupId: string,
  participantId: string,
): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;

  try {
    await request({
      method: 'DELETE',
      url: `/rooms/${roomId}/breakout-rooms/${groupId}/users/${participantId}`,
    });
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error removing user', { errorMessage });
    dispatch(setBreakoutGroupsLaunchError('Error removing participant'));
  }
};

/**
 * Adds a new user to the launched breakout group
 */
export const addParticipantToLaunchedGroup = (
  groupId: string,
  participantId: string,
): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;

  try {
    await request({
      method: 'PUT',
      url: `/rooms/${roomId}/breakout-rooms/${groupId}/users/${participantId}`,
    });
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error adding user', { errorMessage });
    dispatch(setBreakoutGroupsLaunchError('Error adding participant'));
  }
};

/**
 * Sets a global timer for breakout groups
 * to return to the main session
 * @param {Date} recallTimer
 */
export const setRecallTimer = (recallTimer: Date | null): AppThunkAction => async (dispatch) => {
  try {
    // await request({
    //   method: 'POST',
    //   url: `/rooms/${roomId}/some-endpoint-tbd`,
    //   data: recallTimer,
    // })
    dispatch(setGroupsRecallTimer(recallTimer));
  } catch (error) {
    dispatch(handleError('Error setting recall timer:', { error }));
  }
};

/**
 * Bring groups back to main sessions
 */
export const recallBreakoutGroups = (): AppThunkAction => async (dispatch, getState) => {
  const { roomState: { room: { id: roomId } }, breakoutGroupsState: { isRecallingGroups } } = getState();
  if (isRecallingGroups) return;
  dispatch(setIsRecallingGroups(true));
  try {
    await request({
      method: 'DELETE',
      url: `/rooms/${roomId}/breakout-rooms`,
    });
    dispatch(recallGroups());
  } catch (error) {
    dispatch(handleError('Error recalling groups', { error }));

    // only set to false on error here because CC Service room state must update to confirm groups recalled
    dispatch(setIsRecallingGroups(false));
  }
};

export const resetGroupChatUnreads = (): AppThunkAction => (dispatch, getState) => {
  const { breakoutGroupsState: { launchedGroups } } = getState();
  batch(() => {
    for (const groupId of Object.keys(launchedGroups)) {
      dispatch(clearUnseenMessages(`${CHAT_BG_PREFIX}${groupId}` as const));
    }
  });
};

/**
 * Receives launched breakout groups from the websocket and updates state
 * Syncs redux with CC Service
 */
export const setGroupsFromWS = (
  newGroups: LaunchedGroups,
): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const {
    roomState: {
      room: { participants, ownerJoinUnassigned },
      ownerId,
    },
    breakoutGroupsState: { isRecallingGroups, isLaunched },
    chatState: { selectedConversationId },
    loginState: { user },
  } = state;

  const oldCurrentUserGroupId = getCurrentUserGroupId(state);

  const groupIds = Object.keys(newGroups);

  /** @todo Once CE-1313 completed and ChalkCast Service uses TypeScript to ensure room-state contract, this can be removed or edited */
  if (groupIds.length === 0 && Object.keys(newGroups).length > 0 && ownerId === user?.userId) {
    logger.warn('Recalling groups due to no valid groups in state');
    dispatch(recallBreakoutGroups());
    return;
  }

  let draftGroups: GroupIdToUserIdsMap = {};
  let groupNames: GroupIdToNameMap = {};
  const launchedGroups: LaunchedGroups = {};
  let unassigned: string[] = [];

  // create copy without owner
  const participantsCopy = Object.keys(participants)
    .reduce((result: { [id: string]: KNParticipant }, id) => {
      const participant = participants[id];
      if (id !== ownerId && participant) {
        result[id] = participant;
      }
      return result;
    }, {});

  let newCurrentUserGroupId: string | null = null;
  // only do all these loops if there are groups
  if (groupIds.length) {
    // iterate of CC Service groups to sync up redux
    groupIds.forEach((ccServiceId) => {
      const group = newGroups[ccServiceId];

      launchedGroups[ccServiceId] = group;

      // delete keys from participants copy
      // any remaining keys are IDs that do not exist in any group
      group.users.forEach((id) => {
        if (id === user?.userId) {
          newCurrentUserGroupId = ccServiceId;
        }
        delete participantsCopy[id];
      });

      // recreate draft groups with the new CC Service ID
      draftGroups[ccServiceId] = group.users;

      // recreate group names with the new CC Service ID
      groupNames[ccServiceId] = group.name;
    });

    if (!newCurrentUserGroupId) {
      newCurrentUserGroupId = BG_UNASSIGNED;
    }

    // if you changed groups, clear chat un-reads for the old group
    if (oldCurrentUserGroupId && newCurrentUserGroupId !== oldCurrentUserGroupId) {
      const convoId = `${CHAT_BG_PREFIX}${oldCurrentUserGroupId}` as const;
      dispatch(clearUnseenMessages(convoId));
      if (convoId === selectedConversationId) {
        dispatch(deselectConversation());
      }
    }

    // add ungrouped users to unassigned array
    unassigned = Object.keys(participantsCopy);
  } else {
    // add all users to unassigned if no breakout groups
    unassigned = Object.keys(participantsCopy);

    draftGroups = { ...state.breakoutGroupsState.groups };
    groupNames = { ...state.breakoutGroupsState.groupNames };

    if (isLaunched) dispatch(resetGroupChatUnreads());
    if (isRecallingGroups) dispatch(setIsRecallingGroups(false));
  }

  if (ownerJoinUnassigned && ownerId) {
    if (!unassigned.length) {
      /**
       * Prevents owner from being unexpectedly thrown back
       * into the unassigned group when participants join during
       * a breakout session
       */
      dispatch(toggleOwnerJoinUnassignedGroup(false));
    } else {
      unassigned.push(ownerId);
    }
  }

  launchedGroups[BG_UNASSIGNED] = {
    id: BG_UNASSIGNED,
    name: BG_UNASSIGNED,
    users: unassigned,
    active: true,
  };
  const newDraftUnassigned = state?.breakoutGroupsState.groups[BG_UNASSIGNED]
    ? [...state.breakoutGroupsState.groups[BG_UNASSIGNED]] : [];
  draftGroups[BG_UNASSIGNED] = newDraftUnassigned;

  dispatch(setBreakoutGroups(
    launchedGroups,
    draftGroups,
    groupNames,
  ));
};

/**
 * Update the name of a group that is already launched
 * @param {string} groupId
 */
export const updateLaunchedGroupName = (groupId: string): AppThunkAction => async (
  dispatch, getState,
) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  const name = state.breakoutGroupsState.groupNames[groupId];

  try {
    await request({
      method: 'PUT',
      url: `/rooms/${roomId}/breakout-rooms/${groupId}`,
      data: { name },
    });
  } catch (error) {
    dispatch(handleError('Error updating group name: ', { error }));
    dispatch(setBreakoutGroupsError('Error updating group name'));
  }
};

export const ownerJoinAudio = (groupId: OwnerBroadcastTypes['ownerJoinAudio']): AppThunkAction => (dispatch, getState) => {
  const {
    roomState: { ownerId, room: { participants } },
    breakoutGroupsState: { joinAudioId, launchedGroups },
    loginState: { user },
  } = getState();

  if (!ownerId) {
    logger.warn('ownerJoinAudio: Could not join audio', { reason: 'No owner ID', ownerId });
    return;
  }
  const owner = participants[ownerId];
  const ownerName = owner?.displayName;
  const currentUserId = user?.userId || '';
  const currentUserGroup = findUserGroupId(launchedGroups, currentUserId);

  dispatch(setJoinAudioId(groupId));
  if (groupId && groupId === currentUserGroup) {
    /**
     * The user received a message to unmute the owner
     * This means the owner has joined audio for their breakout groups
     */
    dispatch(setRoomAlert(`${ownerName} has joined your group's audio`));
  } else if (joinAudioId && joinAudioId === currentUserGroup && joinAudioId !== groupId) {
    // owner left audio
    dispatch(setRoomAlert(`${ownerName} has disconnected from your group's audio`));
  }
};

export const fetchPreviousGroups = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { id: roomId, participants } = getState().roomState.room;
  const { ownerId } = state.roomState;

  try {
    dispatch(setRecentGroupsLoading(true));
    const response = await request<FetchBreakoutGroupsResponse>({
      method: 'GET',
      url: `/rooms/${roomId}/breakout-rooms?collection=true`,
    });

    const { breakoutRooms } = response.data;

    if (!Array.isArray(breakoutRooms)) {
      logger.warn('Previous breakout groups response is not an array', { breakoutRooms });
    } else if (breakoutRooms.length) {
      logger.info('Previous groups successfully fetched');
      const groups: GroupIdToUserIdsMap = {};
      const groupNames: GroupIdToNameMap = {};

      // set group IDs and names
      breakoutRooms.forEach((group) => {
        const { id, slug } = group;
        groups[id] = [];
        groupNames[id] = slug;
      });

      groups[BG_UNASSIGNED] = [];

      const userIds = Object.keys(participants);

      /**
       * Loop over all users that are currently in the session and see if any
       * of their IDs match with those from the previously launched groups.
       * Any ID without a match will be added to unassigned.
       */
      userIds.forEach((userId) => {
        let idFound = false;
        breakoutRooms.forEach((group) => {
          const { id, users } = group;
          users.forEach((user) => {
            // make sure user is still in room
            if (userId === `${user.id}`) {
              idFound = true;
              if (ownerId !== userId) {
                groups[id].push(`${user.id}`);
              }
            }
          });
        });

        // user id was not found in previous groups, put in unassigned
        if (!idFound && ownerId !== userId) {
          groups[BG_UNASSIGNED].push(userId);
        }
      });

      logger.info('Setting previous breakout groups', { groups, groupNames });
      dispatch(setGroupsType(GroupSelectionTypeEnum.MANUAL, groups, groupNames));
    }
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error fetching previous breakout groups', { errorMessage });
  } finally {
    dispatch(setRecentGroupsLoading(false));
  }
};

/**
 * Resets the state of the breakout groups modal
 */
export const resetGroupsModal = (): AppThunkAction => (dispatch) => {
  dispatch(setGroupsType(
    GroupSelectionTypeEnum.NOT_SET,
    {},
    {},
  ));
};
