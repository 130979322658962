
import { RenderItemFunc } from '../common';
import renderFreeDraw from './renderFreeDraw';

const renderLine: RenderItemFunc<'line'> = (item, theme, existing) => renderFreeDraw({
  ...item,
  kind: 'freeDraw',
  drawData: {
    strokeColor: item.drawData.strokeColor,
    strokeWidth: item.drawData.strokeWidth,
    pathPoints: [item.drawData.pointA, item.drawData.pointB],
  },
}, theme, existing, false);

export default renderLine;
