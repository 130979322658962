import { ChalkboardTheme } from 'store/types';
import { ChalkboardItem, ChalkboardItemKind } from 'components/Chalkboard/itemData';
import { RenderItemFunc } from './common';
import renderEllipse from './itemKinds/renderEllipse';
import renderFreeDraw from './itemKinds/renderFreeDraw';
import renderLine from './itemKinds/renderLine';
import renderRect from './itemKinds/renderRect';
import renderText from './itemKinds/renderText';
import { EDITOR_SIZE } from '../config';

const RENDER_CONFIG: { [key in ChalkboardItemKind]: RenderItemFunc<any, any> } = {
  freeDraw: renderFreeDraw,
  rect: renderRect,
  ellipse: renderEllipse,
  line: renderLine,
  text: renderText,
};

const renderItem = async <Item extends ChalkboardItem>(item: Item, theme: ChalkboardTheme, view: HTMLDivElement, translateByEditorSize = true) => {
  const existing = document.getElementById(item.id);

  const el = RENDER_CONFIG[item.kind](item, theme, existing);

  el.style.top = `${Math.round(item.bounds.topLeft.y) + (translateByEditorSize ? (EDITOR_SIZE / 2) : 0)}px`;
  el.style.left = `${Math.round(item.bounds.topLeft.x + (translateByEditorSize ? (EDITOR_SIZE / 2) : 0))}px`;

  if (!existing) {
    el.id = item.id;
    el.style.position = 'absolute';
    view.appendChild(el);
  }
};

export default renderItem;
