/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import Layout from 'components/Layout/Layout';
import Content from 'components/Layout/Content';
import { GREY_100, TEAL_DARK } from 'theme/ui/colors';
import Card from 'components/Card/Card';
import { ReactNode } from 'react';
import {
  gradientBackground, mediaMaxWidth, mediaMinWidth,
} from 'styles/styles';
import TopNav from 'components/TopNav/TopNav';
import { CSS } from 'types/css';

const containerStyles: CSS = {
  mt: '4rem',
};


const backdropStyles: CSS = {
  position: 'fixed',
  background: gradientBackground,
  width: '100%',
  height: '100%',
  zIndex: -3,
  top: 0,
  left: 0,
};

const titleStyles: CSS = {
  color: `${TEAL_DARK} !important`,
  textAlign: 'center',
  [mediaMaxWidth._0]: {
    variant: 'text.h4',
  },
  [mediaMinWidth._0]: {
    variant: 'text.h3',
  },
  [mediaMinWidth._1]: {
    variant: 'text.h2',
  },
};

const subtitleStyles: CSS = {
  '&, p, ul, ol': {
    opacity: 0.8,
  },
  '&, p': {
    variant: 'text.body',
    color: GREY_100,
    textAlign: 'center',
  },
  mt: '4rem',
  mb: '2rem',
  maxWidth: '57rem',
  mx: 'auto',
};

interface Props {
  children: ReactNode,
  title?: string,
  subtitle?: ReactNode,
  wide?: boolean,
}

function AuthFormWrapper({
  children, title = '', subtitle = '', wide = false,
}: Props) {
  return (
    <Layout>
      <span sx={backdropStyles} />
      <Content>
        <TopNav showLogo />
        <article sx={containerStyles}>
          <header sx={{ mb: '4rem' }}>
            <h1 sx={titleStyles}>{title}</h1>
            {typeof subtitle === 'string' ? (
              <p sx={subtitleStyles}>
                {subtitle}
              </p>
            ) : (
              <div sx={subtitleStyles}>
                {subtitle}
              </div>
            )}
          </header>
          <Card sx={{ width: '100%', maxWidth: wide ? '100rem' : '50.3rem', mx: 'auto' }}>
            {children}
          </Card>
        </article>
      </Content>
    </Layout>
  );
}

export default AuthFormWrapper;
