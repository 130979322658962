/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ComponentProps,
  forwardRef, memo, ReactNode,
} from 'react';
import { ReactComponent as Close } from 'assets/icons/modalClose.svg';
import { CSS } from 'types/css';
import { ModalVariantsShort } from 'theme/ui/modal';
import Button from '../Button';
import Svg from '../Svg';

const closeButtonStyles: CSS = {
  ml: '1.5rem',
  minHeight: '3rem',
  minWidth: '3rem',
  height: '3rem',
  width: '3rem',
  p: 0,
};

export interface ModalTitleProps extends ComponentProps<'div'> {
  toggle: (() => void) | null,
  variant?: ModalVariantsShort,
  showCloseButton?: boolean,
  children?: ReactNode,
  'data-selenium'?: string,
}

/**
 * This is used to provide accessibility information to the Modal component.
 */
export const MODAL_TITLE_ID = 'modalTitle';

/**
 * Renders the title and the close button of a the Modal component.
 *
 * The "toggle" props is always required, but passing in null means that the
 * modal will not be closable via the close button. This is useful for when
 * a Modal is required to be interacted with before closing.
 */
const ModalTitle = forwardRef<HTMLButtonElement, ModalTitleProps>(({
  toggle, showCloseButton = true, children, variant = 'light', 'data-selenium': selenium, ...rest
}, closeButtonRef) => (
  <div sx={{ variant: `modalTitle.${variant}`, justifyContent: children ? 'space-between' : 'flex-end' }} id={MODAL_TITLE_ID} {...rest}>
    {children && <h2 data-selenium={selenium}>{children}</h2>}
    {showCloseButton && (
    <Button
      onClick={toggle || (() => {})}
      variant="neutral"
      ref={closeButtonRef}
      sx={closeButtonStyles}
      aria-label="Close"
      data-selenium={`${selenium}-close-button`}
    >
      <Svg svg={Close} title="Close" />
    </Button>
    )}
  </div>
));

ModalTitle.displayName = 'ModalTitle';

export default memo(ModalTitle);
