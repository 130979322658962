import request from 'utils/request';
import mixpanel from 'utils/mixpanel';
import { handleError, LOGOUT } from 'actions/sharedActions';
import { clearPersistedStoreForLogout } from 'store/localStoragePersistMiddleware';
import { v4 as uuidv4 } from 'uuid';
import {
  LoginTypesEnum, LivelyToken, User, ParticipantFormFields, AppThunkAction, TempUser, GoogleUser, NativeUser,
} from 'store/types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  CreateTempAccountResponse, DisplayNameUpdateResponse,
  FetchLivelyTokenResponse, FetchUserResponse, PostLoginResponse,
} from 'utils/ccService/types';
import { MakeActionType } from 'utils/typeUtils';
import {
  isAuthUser, isGoogleUserData, isTempUser, isNativeUserData, isAxiosError,
} from 'utils/typePredicates';
import logger from 'utils/logger';
import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import facebookTrack, { fbTrackCodesEnum } from 'utils/facebookTrack';
import { getJWT } from 'selectors';
import RouteEnum from 'utils/routeEnum';
import validateEmail from 'utils/validateEmail';
import validatePassword from 'utils/validatePassword';
import validateName from 'utils/validateName';

export const reducerName = 'loginState' as const;
export const SET_ERROR = `${reducerName}/SET_ERROR` as const;
export const UPDATE_AUTH_DATA = `${reducerName}/UPDATE_AUTH_DATA` as const;
export const UPDATE_PARTICIPANT_FORM = `${reducerName}/UPDATE_PARTICIPANT_FORM` as const;
export const SET_JOINED_ROOM = `${reducerName}/SET_JOINED_ROOM` as const;
export const SET_AUTH_FORM_ERROR = `${reducerName}/SET_AUTH_FORM_ERROR` as const;
export const TOGGLE_AUTH_FORM_SENDING = `${reducerName}/TOGGLE_AUTH_FORM_SENDING` as const;
export const TOGGLE_REGISTRATION_COMPLETE_MODAL = `${reducerName}/TOGGLE_REGISTRATION_COMPLETE_MODAL` as const;
export const SET_LOGIN_TYPE = `${reducerName}/SET_LOGIN_TYPE` as const;
export const SET_FETCHING_USER = `${reducerName}/SET_FETCHING_USER` as const;
export const SET_FETCHING_TOKENS = `${reducerName}/SET_FETCHING_TOKENS` as const;
export const SET_REDIRECT_AFTER_LOGIN_PATH = `${reducerName}/SET_REDIRECT_AFTER_LOGIN_PATH` as const;

export const setAuthFormError = (error: string) => ({
  type: SET_AUTH_FORM_ERROR,
  payload: { error },
});

export const changeParticipantForm = (value: string, field: ParticipantFormFields) => ({
  type: UPDATE_PARTICIPANT_FORM,
  payload: {
    field,
    value,
  },
});

export const setJoinedRoom = (roomId: string | null) => ({
  type: SET_JOINED_ROOM,
  payload: { joinedRoom: roomId },
});

export const toggleRegistrationCompleteModal = () => ({
  type: TOGGLE_REGISTRATION_COMPLETE_MODAL,
});

/**
 * If a boolean is provided, sets sending to that boolean.
 * Otherwise, it toggles whether the auth form is sending.
 */
export const toggleAuthFormSending = (sending?: boolean) => ({
  type: TOGGLE_AUTH_FORM_SENDING,
  payload: { sending },
});

export const setLoginType = (loginType: LoginTypesEnum) => ({
  type: SET_LOGIN_TYPE,
  payload: { loginType },
});


/**
 * This is the shape of the data expected by the updateAuthData function.
 */
export interface AuthData {
  user?: User,
  jwt?: string,
  livelyToken?: LivelyToken,
}

/**
 * Updates the user object, JWT, and/or livelyToken.
 *
 * Exported as a single action to prevent Redux race conditions in the future.
 * These login details should never be set sequentially when they are meant to be updated
 * simultaneously, since this can cause weird race conditions with our useSyncAuthState.
 */
export const updateAuthData = (authData: AuthData) => ({
  type: UPDATE_AUTH_DATA,
  payload: authData,
});

export const setFetchingUser = (fetching: boolean) => ({
  type: SET_FETCHING_USER,
  payload: { fetching },
});

export const setFetchingTokens = (fetching: boolean) => ({
  type: SET_FETCHING_TOKENS,
  payload: { fetching },
});

export const setRedirectAfterLoginPath = (path: string) => ({
  type: SET_REDIRECT_AFTER_LOGIN_PATH,
  payload: { path },
});

export type LoginAction = MakeActionType<[
  typeof toggleAuthFormSending,
  typeof setAuthFormError,
  typeof changeParticipantForm,
  typeof setJoinedRoom,
  typeof toggleRegistrationCompleteModal,
  typeof setLoginType,
  typeof updateAuthData,
  typeof setFetchingUser,
  typeof setFetchingTokens,
  typeof setRedirectAfterLoginPath
]>

export const resetAuthFormError = (): AppThunkAction => (dispatch) => {
  dispatch(setAuthFormError(''));
};

export interface LogoutOptions {
  redirect?: typeof RouteEnum.LOGOUT | typeof RouteEnum.SESSION_EXPIRED,
}

export const setCurrentPathAsPostLoginRedirect = (): AppThunkAction => (dispatch, getState) => {
  const { location } = getState().router;
  dispatch(setRedirectAfterLoginPath(location.pathname + location.search));
};

/**
 * Logs the user out, clears Redux, and  clears the persisted store.
 * Optionally redirect to a different page as a batched state update.
 */
export const logout = ({ redirect }: LogoutOptions = {}): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  logger.info('Logging out');
  clearPersistedStoreForLogout();
  batch(() => {
    dispatch({ type: LOGOUT });
    if (redirect) {
      mixpanel.api.reset();
      if (!state.loginState.redirectAfterLoginPath) dispatch(setCurrentPathAsPostLoginRedirect());
      dispatch(push(redirect.encodePath()));
    }
  });
};

/**
 * Submits the form that allows a user to edit their display name.
 */
export const submitParticipantForm = (): AppThunkAction => async (
  dispatch, getState,
) => {
  const state = getState();
  const { user } = state.loginState;
  if (!user) return;
  const { displayName, school } = state.loginState.participantForm;
  // const roomId = state.roomState.room.id;
  const newUser = {
    ...user,
    displayName,
    school,
  };

  dispatch(updateAuthData({ user: newUser }));
};

/**
 * Sends PUT request to CC Service to update the user's display name.
 */
export const submitDisplayNameUpdate = (): AppThunkAction => async (
  dispatch, getState,
) => {
  const {
    loginState: {
      user,
      participantForm: {
        displayName,
      },
      livelyToken: initialLivelyToken,
    },
    roomState: {
      livelyRoomToken,
    },
  } = getState();

  if (!user) return;
  const data = { name: displayName };

  /* Per Vineetha: users must update their display name using the ROOM token
  if they have one, so that ccService updates the participant list accordingly */
  const livelyToken = livelyRoomToken.token || initialLivelyToken;
  const options: AxiosRequestConfig = {
    method: 'PUT',
    url: '/users/me',
    data,
    headers: { livelyToken },
  };

  const newUser = {
    ...user,
    displayName,
  };

  logger.info('Updating user', { user: newUser });

  try {
    // assume the request worked
    dispatch(updateAuthData({ user: newUser }));

    /* the lively token and JWT that CC Service returns are
    associated with the user's new display name */
    const { data: { jwtToken, livelyToken: newLivelyToken } }: DisplayNameUpdateResponse = await request(options);
    dispatch(updateAuthData({ livelyToken: newLivelyToken, jwt: jwtToken }));

    mixpanel.setProfile({ 'Display Name': displayName }); // user should have a profile, so profile property should be updated
    mixpanel.setSuperEventProperties({ 'Display Name': displayName });
  } catch (error) {
    // revert to previous state on failure
    dispatch(updateAuthData({ user }));
    dispatch(handleError('Error updating user', { error }));
  }
};

/**
 * Arranges the user's data in the shape necessary for storing in Redux.
 * Used in fetchUser and MergeAccountForm.js
 */
export const extractDataFromFetchUser = (res: FetchUserResponse): User => {
  const { data: { data, exp } } = res;

  const sharedUserData: Pick<User, 'userId' | 'source' | 'exp'> = {
    userId: data.userId.toString(),
    source: data.source,
    exp,
  };

  if (isGoogleUserData(data)) {
    const { user } = data;
    const googleUser: GoogleUser = {
      ...sharedUserData,
      newGoogleUser: data.newUser,
      sanitizedEmail: user.sanitizedEmail,
      displayName: user.profile.displayName,
      provider: user.profile.provider,
      photos: user.profile.photos,
    };
    return googleUser;
  } if (isNativeUserData(data)) {
    const { user } = data;
    const nativeUser: NativeUser = {
      ...sharedUserData,
      newGoogleUser: false,
      sanitizedEmail: user.sanitizedEmail,
      displayName: user.displayName,
      provider: 'chalkcast',
    };
    return nativeUser;
  }
  const { user } = data;
  const tempUser: TempUser = {
    ...sharedUserData,
    newGoogleUser: false,
    displayName: user.displayName,
    provider: 'chalkcast',
  };
  return tempUser;
};

const trackSignUp = ({ userId, displayName }: { userId: string, displayName: string }) => {
  mixpanel.api.identify(userId);
  mixpanel.enableProfile();
  mixpanel.setSuperEventProperties({ 'Display Name': displayName });
  mixpanel.setProfile({
    Speakers: 'Default',
    Camera: 'Default',
    Microphone: 'Default',
    'Display Name': displayName,
    'Called On': 'On',
    'Guest Raises Hand': 'On',
    'New Direct Message': 'On',
    'New Everyone Message': 'On',
    'Someone Joins': 'On',
    'Someone Leaves': 'On',
  });
  mixpanel.setProfile({
    'Sign Up Date': new Date().toISOString(),
  }, { once: true });
  mixpanel.track('Sign Up');
};

/**
 * Sends the JWT to CC Service to be decoded.
 *
 * `shouldLogout` is useful when switching user accounts to make sure that existing user state
 * is not mixed with any new user state. This update must happen immediately before updating the user's
 * authentication data with the new user, rather than when the login/signup request is first sent
 * to prevent race conditions/state bugs.
 * @param {string} token
 */
export const fetchUser = (opts?: { token?: string, shouldLogout?: boolean }): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { loginState: { fetchingUser, redirectAfterLoginPath } } = state;
  if (fetchingUser) {
    logger.warn('fetchUser: Can\'t fetch a new user while an existing request is being processed');
    return;
  }

  const token = opts?.token || getJWT(state);

  if (!token) {
    logger.warn('Cannot fetchUser without token present', { opts, token });
    return;
  }

  const options: AxiosRequestConfig = {
    method: 'GET',
    url: '/auth/jwtdecode',
    headers: { token },
  };

  logger.info('Fetching user with token', { token });

  try {
    dispatch(setFetchingUser(true));
    const response: FetchUserResponse = await request(options);
    const user = extractDataFromFetchUser(response);
    const { data: { data: { livelyToken } } } = response;
    const { userId } = user;

    if (isAuthUser(user)) {
      mixpanel.api.identify(userId); // must be called before setting profile is effective. Creates and/or associates user profile
      mixpanel.enableProfile();
      mixpanel.setProfile({
        'Display Name': user.displayName || '', // in case isn't up to date
      });
    } else {
      mixpanel.disableProfile();
    }

    mixpanel.setSuperEventProperties({ 'Display Name': user.displayName || '' });

    batch(() => {
      if (opts?.shouldLogout) dispatch(logout());

      dispatch(updateAuthData({ user, livelyToken, jwt: token }));

      if (isAuthUser(user)) dispatch(setLoginType(LoginTypesEnum.AUTH_USER));
      else if (isTempUser(user)) dispatch(setLoginType(LoginTypesEnum.TEMP_USER));

      if (user.newGoogleUser) {
        // This function will check to see if the user came to us through a facebook ad
        // and track that they completed registration
        facebookTrack(fbTrackCodesEnum.COMPLETE_REGISTRATION);

        trackSignUp({ userId: user.userId, displayName: user.displayName });

        // Toggle open the registration complete modal
        dispatch(toggleRegistrationCompleteModal());
      }
    });

    // NOTE: MUST HAPPEN AFTER setLoginType
    if (redirectAfterLoginPath && isAuthUser(user)) {
      dispatch(push(redirectAfterLoginPath));
      dispatch(setRedirectAfterLoginPath(''));
    }
    logger.info('Successfully fetched user', { user: JSON.stringify(user), livelyToken: JSON.stringify(livelyToken), jwt: token });
  } catch (error) {
    dispatch(handleError('Error fetching user', { error }));
  } finally {
    dispatch(setFetchingUser(false));
  }
};

/**
 * Sends a request to CC Service to create a temporary user account for the user and
 * updates state with the user's new lively token and user information.
 */
export const createTempAccount = (roomId: string): AppThunkAction => async (dispatch, getState) => {
  try {
    const state = getState();
    const { loginState } = state;
    const { participantForm: { school, displayName }, loginType } = loginState;
    if (loginType !== LoginTypesEnum.ANON_USER) throw new Error('Can\'t create a temporary account for a non-anonymous user.');

    const userType = 'conference-participant';
    const newUser = {
      userId: uuidv4(),
      exp: (Date.now() + 1000 * 60 * 60 * 24) / 1000,
      displayName,
      school,
      userType,
    };

    logger.info('Creating temp account', { user: newUser });

    /* create a temporary user account
    this livelyToken is identical to the one returned from /auth/jwtdecode */
    const { data: { jwtToken, livelyToken } }: CreateTempAccountResponse = await request({
      method: 'POST',
      url: `/rooms/${roomId}/user-join`,
      data: newUser,
    });

    // fetch details about user's account
    const res: FetchUserResponse<'temp'> = await request({
      method: 'GET',
      url: '/auth/jwtdecode',
      headers: {
        token: jwtToken,
      },
    });

    const userData = extractDataFromFetchUser(res);
    batch(() => {
      dispatch(logout());
      dispatch(updateAuthData({ user: userData, livelyToken, jwt: jwtToken }));
      dispatch(setLoginType(LoginTypesEnum.TEMP_USER));
    });
  } catch (error) {
    dispatch(handleError('Error creating a temporary user account', { error }));
  }
};

/** Returns any errors found in user's data when signing them up */
export const validateSignUpData = ({
  email, firstName, lastName, password,
}: {
  email: string, firstName: string, lastName: string, password: string,
}) => {
  const errors = ({
    emailError: validateEmail(email),
    passwordError: validatePassword(password),
    firstNameError: validateName(firstName),
    lastNameError: validateName(lastName),
  });
  const containsError = !!Object.values(errors).find((error) => !!error);
  return { ...errors, containsError };
};

/** Registers a new user by email and password. */
export const signupUser = ({
  email, firstName, lastName, password,
}: {
  email: string, firstName: string, lastName: string, password: string,
  }): AppThunkAction => async (dispatch, getState) => {
  const { loginState: { authFormSending } } = getState();

  if (authFormSending) {
    logger.warn('Can\'t sign up user', {
      reasons: 'Authentication is already sending',
      authFormSending,
      firstName,
      lastName,
      email,
    });
    return;
  }

  const signupErrors = validateSignUpData({
    email, firstName, lastName, password,
  });
  if (signupErrors.containsError) {
    logger.warn('Can\'t sign up user', {
      reasons: 'Error found in the user\'s signup input fields',
      signupErrors,
    });
    return;
  }

  logger.info('Signing up user', { email });

  const displayName = `${firstName} ${lastName}`;
  const signup: AxiosRequestConfig = {
    method: 'POST',
    url: '/users/register',
    data: {
      email, password, firstName, lastName, displayName,
    },
  };

  let success = true;
  let errorMessage = '';
  try {
    batch(() => {
      dispatch(setAuthFormError(''));
      dispatch(toggleAuthFormSending(true));
    });

    const response = await request<PostLoginResponse>(signup);

    if (response.data?.userId) {
      trackSignUp({ userId: response.data.userId, displayName: response.data?.livelyToken?.data?.displayName || '' });
    }

    await dispatch(fetchUser({ token: response.data.jwtToken, shouldLogout: true }));
    dispatch(toggleRegistrationCompleteModal());
  } catch (error) {
    errorMessage = isAxiosError(error) && (error.response?.status === 409 || error.response?.status === 401)
      ? 'We already have this email on file'
      : 'We are sorry, something went wrong';
    success = false;
    dispatch(setAuthFormError(errorMessage));
  } finally {
    dispatch(toggleAuthFormSending(false));
    mixpanel.track('Account Create', {
      Status: success ? 'Success' : 'Failure',
      Reason: errorMessage,
    });

    if (success) {
      // This function will check to see if the user came to us through a facebook ad
      // and track that they completed registration
      facebookTrack(fbTrackCodesEnum.COMPLETE_REGISTRATION);
    }
  }
};

/**
 * Logs in a user with email
 */
export const loginUser = (
  email: string, password: string,
): AppThunkAction => async (dispatch) => {
  const login: AxiosRequestConfig = {
    method: 'POST',
    url: '/users/login',
    data: { email, password },
  };
  try {
    batch(() => {
      dispatch(setAuthFormError(''));
      dispatch(toggleAuthFormSending(true));
    });

    logger.info('Logging in with email and password', { email });
    const response = await request<PostLoginResponse>(login);
    await dispatch(fetchUser({ token: response.data.jwtToken, shouldLogout: true }));
  } catch (error) {
    batch(() => {
      dispatch(handleError('Error attempting to log user in', { error, email }));
      let errorReason = 'We are sorry, something went wrong';

      if (isAxiosError(error)
        && (error.response?.status === 400
        || error.response?.status === 404
        || error.response?.status === 422
        || error.response?.status === 401)) {
        /**
         * This will be changing to always use 401 but until then we must account
         * for these other codes:
         * 400 email is found but password is incorrect
         * 404 email not found
         * 422 password not at least 8 characters
         */
        errorReason = 'Oops. Incorrect email or password';
      }

      dispatch(setAuthFormError(errorReason));
    });
  } finally {
    dispatch(toggleAuthFormSending(false));
  }
};

/**
 * Fetches a new lively token and JWT
 * Prevents the user from being logged out because of an expired livelyToken
 */
export const fetchNewTokens = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { fetchingTokens, user } = state.loginState;
  const prevJWT = state.loginState.token;
  if (fetchingTokens) {
    logger.warn('fetchNewTokens: Can\'t fetch new tokens while an existing request is already being processed');
    return;
  }
  if (!prevJWT) {
    logger.warn('fetchNewTokens: Can\'t fetch new tokens without an existing JWT', { prevJWT });
    return;
  }

  logger.info('Fetching new livelyToken');

  try {
    dispatch(setFetchingTokens(true));
    const { data: { livelyToken, token } }: AxiosResponse<FetchLivelyTokenResponse> = await request({
      method: 'GET',
      url: '/auth/token',
    });
    dispatch(updateAuthData({ livelyToken, jwt: token }));

    if (isAuthUser(user)) {
      mixpanel.api.identify(user.userId);
      mixpanel.enableProfile();
      mixpanel.setProfile({
        'Display Name': livelyToken?.data?.displayName || '',
      });
    }
    mixpanel.setSuperEventProperties({ 'Display Name': livelyToken?.data?.displayName || '' });
  } catch (error) {
    dispatch(handleError('Error fetching lively token', { error }));
  } finally {
    dispatch(setFetchingTokens(false));
  }
};
