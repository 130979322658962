
import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { SoundFileEnum } from 'utils/soundPlayer';
import { defaultFilePlaybackConfig } from 'utils/soundPlayer/soundPlayerOptions';

export const getSoundPlayer = (state: StoreState) => state.audioState.soundPlayer;

export const getSoundPlayerOptions = (state: StoreState) => state.audioState.soundPlayerOptions;

export const getFilePlaybackConfig = (sound: SoundFileEnum) => createSelector(
  getSoundPlayerOptions,
  ({ filePlaybackConfigs }) => ({ ...defaultFilePlaybackConfig, ...filePlaybackConfigs[sound.keyName] }),
);
