import { CSS } from 'types/css';


const BUTTON_SIZE = '3.9rem';

// eslint-disable-next-line import/prefer-default-export
export const attendanceStatusIconDefaultStyles: CSS = {
  position: 'relative',
  height: BUTTON_SIZE,
  width: BUTTON_SIZE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
};
