/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import Loading from 'components/Loading/Loading';
import {
  Fragment,
  memo, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateToClockTime, msToDurationString } from 'utils/dateUtils';
import {
  getUserViewNumRows,
  getUserViewSessionsLoading,
  getUserViewSessionsError,
  getUserViewSessionsFetched,
  getUserViewSessionIdLabels,
  getUserViewSessions,
  getUserViewDisplayName,
  getUserViewCSVDataLoading,
  getUserViewFirstName,
  getUserViewLastName,
} from 'selectors';
import { CSS } from 'types/css';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { GREY_80, TEAL_DARK } from 'theme/ui/colors';
import { mediaMaxWidth, mediaMinWidth, textEllipsis } from 'styles/styles';
import ErrorMessage from 'components/ui/Messages/ErrorMessage';
import { userViewFetchAndDownloadAttendanceCSV, userViewFetchSessions, userViewResetState } from 'actions/attendanceActions';
import Svg from 'components/ui/Svg';
import { Link } from 'react-router-dom';
import { formatNameData } from 'utils/stringUtils';
import logger from 'utils/logger';
import RouteEnum from 'utils/routeEnum';
import { useRoomManagerContext } from 'views/RoomManager/roomManagerContext';
import { OwnerStatusEnum } from 'hooks/useIsOwner';
import AttendanceDateLabel from '../AttendanceDateLabel';
import {
  attendanceCardBaseStyles, mainHeadingStylesBase, attendanceContainerStyles,
  darkerRowStyles, spacerStyles, bottomBorderRadius, TABLE_ROW_HEIGHT, noSessionsStyles, headerStyles,
} from '../common';
import AttendanceStatusIcon from '../AttendanceStatusIcon/AttendanceStatusIcon';
import AttendanceUserViewError from './AttendanceUserViewError';
import AttendanceDownloadCSVButton from '../AttendanceDownloadCSVButton';
import OverallAttendanceCard from './OverallAttendanceCard';

export const USER_VIEW_ROWS_FOR_USER_DATA_LABELS = 1;
const GRID_INDEX_OFFSET = 1;
const DATA_COLS = 3;

const h1Styles: CSS = {
  ...mainHeadingStylesBase,
  width: '100%',
  mx: 'auto',
  textAlign: 'left',
  ...textEllipsis,
  '& a': {
    color: TEAL_DARK,
  },
};

const chevronSvgStyles: CSS = {
  transform: 'rotate(180deg)',
  verticalAlign: 'middle',
  fill: GREY_80,
  mx: '2rem',
};

const tableStyles: CSS = {
  ...attendanceCardBaseStyles,
  maxWidth: '59rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  placeItems: 'center',
  height: 'fit-content',
};

const labelStyles: CSS = {
  variant: 'text.body_small_semibold',
  [mediaMinWidth._1]: {
    variant: 'text.body_semibold',
  },
  textAlign: 'center',
  px: '1rem',
  maxWidth: '100%',
  ...textEllipsis,
};

const gridTemplateColumns = `repeat(${USER_VIEW_ROWS_FOR_USER_DATA_LABELS + DATA_COLS}, minmax(7rem, 16rem))`;

const topRowStyles: CSS = {
  minHeight: '6.8rem',
  gridArea: `1 / 1 / 2 / ${DATA_COLS + GRID_INDEX_OFFSET}`,
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns,
  maxWidth: '100%',
  placeItems: 'center',
};

const bodyRowStyles: CSS = {
  display: 'grid',
  height: TABLE_ROW_HEIGHT,
  maxHeight: TABLE_ROW_HEIGHT,
  gridTemplateRows: TABLE_ROW_HEIGHT,
  gridTemplateColumns,
  justifyContent: 'center',
  placeItems: 'center',
  maxWidth: '100%',
};

const dataCellStyles: CSS = {
  variant: 'text.caption',
  [mediaMinWidth._1]: {
    variant: 'text.body_small',
  },
  height: '100%',
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& time': {
    maxWidth: '100%',
    ...textEllipsis,
  },
};

/** Contains attendance chart and the "Overall attendance" card */
const attendanceFlexContainerStyles: CSS = {
  display: 'flex',
  [mediaMaxWidth._5]: {
    flexDirection: 'column',
  },
  [mediaMinWidth._5]: {
    flexDirection: 'row',
  },
};

const TABLE_TITLE_ID = 'attendance__user-view__title';

interface Props {
  userId: string
  ownerStatus: OwnerStatusEnum,
}

/** Displays all attendance reports for the currently selected user */
function AttendanceUserView({ userId, ownerStatus }: Props) {
  const dispatch = useDispatch();
  const loading = useSelector(getUserViewSessionsLoading);
  const error = useSelector(getUserViewSessionsError);
  const sessionsFetched = useSelector(getUserViewSessionsFetched);
  const sessionIdLabels = useSelector(getUserViewSessionIdLabels);
  const rows = useSelector(getUserViewNumRows);
  const sessionMap = useSelector(getUserViewSessions);
  const displayName = useSelector(getUserViewDisplayName);
  const firstName = useSelector(getUserViewFirstName);
  const lastName = useSelector(getUserViewLastName);
  const csvDataLoading = useSelector(getUserViewCSVDataLoading);
  const { roomId } = useRoomManagerContext();
  const handleDownloadCSVClick = useCallback(() => {
    dispatch(userViewFetchAndDownloadAttendanceCSV(roomId, userId, ownerStatus));
  }, [roomId, userId, ownerStatus, dispatch]);
  const isOwner = ownerStatus === OwnerStatusEnum.OWNER;

  useEffect(() => {
    dispatch(userViewFetchSessions(roomId, userId, ownerStatus));
    return () => { dispatch(userViewResetState()); };
  }, [roomId, userId, ownerStatus, dispatch]);

  const tableBodyStyles: CSS = useMemo(() => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: `repeat(${rows - USER_VIEW_ROWS_FOR_USER_DATA_LABELS}, ${TABLE_ROW_HEIGHT})`,
    ...bottomBorderRadius,
  }), [rows]);


  if (error) {
    return (
      <Fragment>
        <ErrorMessage type="attendance" useTimer />
        <AttendanceUserViewError userId={userId} ownerStatus={ownerStatus} />
      </Fragment>
    );
  }

  if (!sessionsFetched) {
    return <Loading />;
  }

  if (sessionsFetched && sessionIdLabels.length === 0) {
    return <p sx={noSessionsStyles}>There are no attendance reports to show for this user.</p>;
  }

  return (
    <ErrorBoundary fallback={<AttendanceUserViewError userId={userId} ownerStatus={ownerStatus} />}>
      <ErrorMessage type="attendance" useTimer />
      <main sx={attendanceContainerStyles}>

        {/* Heading */}
        <header sx={headerStyles}>
          <h1 sx={h1Styles} id={TABLE_TITLE_ID}>
            {isOwner ? (
              <Fragment>
                <Link to={RouteEnum.ROOM_MANAGER__ATTENDANCE.encodePath({ urlParams: { roomId } })}>Attendance</Link>
                <Svg svg={Chevron} sx={chevronSvgStyles} aria-hidden />
                {formatNameData(firstName, lastName, displayName)}
              </Fragment>
            ) : 'Attendance'}
          </h1>
          <AttendanceDownloadCSVButton
            csvDataLoading={csvDataLoading}
            handleDownloadCSVClick={handleDownloadCSVClick}
          />
        </header>

        <div sx={attendanceFlexContainerStyles}>
          <OverallAttendanceCard userId={userId} roomId={roomId} />

          {/* Attendance chart */}
          <table sx={tableStyles} aria-labelledby={TABLE_TITLE_ID} summary={`"Attendance data for ${displayName}`}>
            <thead>
              {/* Top Table Labels */}
              <tr sx={topRowStyles}>
                <td />
                <th sx={labelStyles}>Joined</th>
                <th sx={labelStyles}>Left</th>
                <th sx={labelStyles}>Total Time</th>
              </tr>
            </thead>

            <tbody sx={tableBodyStyles}>
              {sessionIdLabels.map((sessionId, i) => {
                const session = sessionMap[sessionId];
                if (!session) {
                  logger.error('Session id was not found in UserView session map');
                  return null;
                }
                const { attendanceStatus, startTimeDate } = session;
                const startTimeDateString = startTimeDate.toDateString();
                const startTimeClockTimeString = dateToClockTime(startTimeDate);
                const rowIsDark = i % 2 === 0;
                return session && (
                <tr
                  key={sessionId}
                  sx={{
                    ...bodyRowStyles,
                    ...(rowIsDark && darkerRowStyles),
                    ...(i === sessionIdLabels.length - 1 && bottomBorderRadius), // curved bottom corners of last row
                  }}
                >
                  {/* Date Label */}
                  <AttendanceDateLabel
                    scope="col"
                    key={sessionId}
                    startTime={session.startTime}
                    startTimeDate={startTimeDate}
                    loading={loading}
                    sessionId={sessionId}
                    variant="user"
                    attendanceStatus={session?.attendanceStatus}
                  />

                  {/* Attendance Absent Status / Table Data */}
                  {attendanceStatus === 'absent' ? (
                    <Fragment>
                      <td />
                      {/* Only show icon if user is absent (i.e. no table data) */}
                      <AttendanceStatusIcon
                        key={`${userId}${sessionId}`}
                        loading={loading}
                        attendanceStatus={attendanceStatus || ''}
                        aria-label={`${displayName} was ${attendanceStatus} on ${startTimeDateString} at ${startTimeClockTimeString}`}
                        userId={userId}
                        sessionId={sessionId}
                        rowIsDark={rowIsDark}
                      />
                      <td />
                    </Fragment>
                  ) : (
                  /* Only show detailed table data if user is present (i.e. don't show the icon) */
                    <Fragment>
                      <td sx={dataCellStyles}><time dateTime={session.joinTime}>{dateToClockTime(new Date(session.joinTime || ''))}</time></td>
                      <td sx={dataCellStyles}><time dateTime={session.leaveTime}>{dateToClockTime(new Date(session.leaveTime || ''))}</time></td>
                      <td sx={dataCellStyles}><time>{msToDurationString(session.totalTime)}</time></td>
                    </Fragment>
                  )}
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div sx={spacerStyles} />
      </main>
    </ErrorBoundary>
  );
}

export default memo(AttendanceUserView);
