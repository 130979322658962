/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  toggleDebugModal, toggleGrafanaOptionsModal, toggleEnableGrafanaLinks,
} from 'actions/debugActions';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal/Modal';
import ModalBody from 'components/ui/Modal/ModalBody';
import ModalFooter from 'components/ui/Modal/ModalFooter';
import ModalTitle from 'components/ui/Modal/ModalTitle';
import Switch from 'components/ui/Switch';
import useTypedSelector from 'hooks/useTypedSelector';
import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';
import {
  getIsGrafanaOptionsModalOpen, getRoomId, getGrafanaLinksEnabled, getCurrentUserName,
} from 'selectors';
import { textEllipsis } from 'styles/styles';
import { CSS } from 'types/css';
import formatGrafanaLink, { GrafanaDashboardsEnum } from './formatGrafanaLink';

const bodyContainerStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '2rem',
};

const buttonStyles: CSS = {
  maxWidth: '100%',
  '& div': {
    display: 'block',
    ...textEllipsis,
  },
};


export default function GrafanaOptionsModal() {
  const dispatch = useDispatch();
  const isOpen = useTypedSelector(getIsGrafanaOptionsModalOpen);
  const toggle = useCallback(() => dispatch(toggleGrafanaOptionsModal()), [dispatch]);
  const goBack = useCallback(() => {
    batch(() => {
      dispatch(toggleGrafanaOptionsModal(false));
      dispatch(toggleDebugModal(true));
    });
  }, [dispatch]);

  const roomId = useTypedSelector(getRoomId) || '';
  const currentUserDisplayName = useTypedSelector(getCurrentUserName) || '';
  const grafanaLinksEnabled = useTypedSelector(getGrafanaLinksEnabled);

  const toggleRoomLinks = useCallback(() => {
    dispatch(toggleEnableGrafanaLinks());
  }, [dispatch]);

  const goToGrafanaStats = useCallback(() => {
    const grafanaLink = formatGrafanaLink(GrafanaDashboardsEnum.PRODUCER_CONSUMER_STATS, { roomId, producingDisplayName: currentUserDisplayName });
    window.open(grafanaLink, '_blank');
  }, [roomId, currentUserDisplayName]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} minContentHeight addFooterSpacing>
      <ModalTitle toggle={toggle}>Grafana Options</ModalTitle>
      <ModalBody>
        <div sx={bodyContainerStyles}>
          <Switch
            variant="dark"
            onChange={toggleRoomLinks}
            id="grafana-links-enabled"
            label="Enable Links to Grafana"
            checked={grafanaLinksEnabled}
          />
          <Button sx={buttonStyles} onClick={goToGrafanaStats} disabled={!roomId || !currentUserDisplayName}>
            See Consumer-Producer Stats for Current User
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondaryLight" onClick={goBack}>Back</Button>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
