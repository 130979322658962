
import mixpanel, { Callback as MixpanelCallback } from 'mixpanel-browser';
import logger from 'utils/logger';
import { MixpanelEvents, MixpanelSuperEventProperties, MixpanelUserProfile } from './types';

mixpanel.init(appConfig.mixpanelToken, { debug: localStorage.getItem('debug') === '*' || process.env.NODE_ENV !== 'production' });

type Page = MixpanelEvents['View Page']['Page']

const ROOM_PAGES: Page[] = ['Room', 'Room Settings', 'Join', 'Attendance'];

class WrappedMixpanelClient {
  /** Allows direct use of the mixpanel api */
  public readonly api: typeof mixpanel
  private isProfileEnabled: boolean

  constructor() {
    this.api = mixpanel;
    this.isProfileEnabled = false;
  }

  /** Custom method: Flags that the current user should have a mixpanel profile */
  public enableProfile() {
    this.isProfileEnabled = true;
  }

  /** Custom method: Flags that the current user should NOT have a mixpanel profile */
  public disableProfile() {
    this.isProfileEnabled = false;
  }

  /**
   * Wraps mixpanel.track to restrict event names and metadata to expected values.
   * Has same basic function signature as the original mixpanel track method.
   *
   * For eventProperties, you can also override the "super event properties"
   * defined in MixpanelSuperEventProperties for any event.
   */
  public track<EventName extends keyof MixpanelEvents>(
    event: EventName,
    ...args: MixpanelEvents[EventName] extends undefined ?
      [superEventPropertyOverrides?: Partial<MixpanelSuperEventProperties>, mixpanelCallback?: MixpanelCallback] :
      [eventProperties: MixpanelEvents[EventName] & Partial<MixpanelSuperEventProperties>, mixpanelCallback?: MixpanelCallback]
  ): void {
    this.api.track(event, ...args);
  }

  /** Wraps the track method for page views, helps ensure Role and Room ID super event properties are correct. */
  public trackPageView(page: Page) {
    if (!ROOM_PAGES.includes(page)) {
      this.setSuperEventProperties({ Role: '', 'Room ID': '' });
    }
    this.track('View Page', { Page: page });
  }

  /** Custom method: Wraps mixpanel.people.set to type profile properties. Only sets profile if method .enableProfile has been called */
  public setProfile(profile: Partial<MixpanelUserProfile>, { once } = { once: false }) {
    if (!this.isProfileEnabled) return;

    logger.info('Updating mixpanel profile', { profile, once });
    if (once) {
      this.api.people.set_once(profile);
    } else {
      this.api.people.set(profile);
    }
  }

  /** Custom method: Wraps mixpanel.register to type super event properties */
  public setSuperEventProperties(properties: Partial<MixpanelSuperEventProperties>) {
    logger.info('Setting mixpanel super event properties', { properties });
    this.api.register(properties);
  }
}

/**
 * Wraps the mixpanel api to provide app-specific typing to event and user properties
 * with some custom methods.
 */
const client = new WrappedMixpanelClient();

export default client;
