import { Point } from 'components/Chalkboard/itemData';
import { getHexByTheme } from '../../../common/colorUtils';
import { RenderItemFunc } from '../common';

const DATASET_NEXT_INDEX_KEY = 'nextIndex';
const DATASET_TOP_LEFT_X = 'topLeftX';
const DATASET_TOP_LEFT_Y = 'topLeftY';

const formatPointsToSVGPath = (points: Point[], starting: boolean) => points.reduce((str, { x, y }, i) => (
  `${str}${(i === 0 && starting) ? 'M' : 'L'} ${x} ${y} `
), '');

const renderFreeDraw: RenderItemFunc<'freeDraw', [boolean]> = (item, theme, existing, optimizePath = true) => {
  const svg = existing || document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const path = existing ? (svg.firstElementChild || document.createElement('path')) : document.createElement('path');

  const { size: { width: _width, height: _height }, topLeft } = item.bounds;

  // prevents issue where valid item doesn't appear due to line that is parallel with x or y axis
  const width = _width || 1;
  const height = _height || 1;

  const setPath = (startingIndex: number) => {
    const existingD = path.getAttribute('d');
    const d = formatPointsToSVGPath(
      item.drawData.pathPoints.slice(startingIndex), startingIndex === 0,
    );
    path.setAttribute('d', startingIndex > 0 ? existingD + d : d);
  };

  if (existing) {
    const topLeftX = parseInt(svg.dataset[DATASET_TOP_LEFT_X] || '', 10);
    const topLeftY = parseInt(svg.dataset[DATASET_TOP_LEFT_Y] || '', 10);
    if (optimizePath && !item.isSmartShape && topLeft.x === topLeftX && topLeft.y === topLeftY) {
      // can optimize and only draw new points if svg position is the same
      setPath(parseInt(svg.dataset[DATASET_NEXT_INDEX_KEY] || '', 10) || 0);
    } else {
      setPath(0);
    }
  } else {
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
    svg.setAttribute('version', '1.1');
    svg.style.overflow = 'visible';

    svg.appendChild(path);
    path.setAttribute('fill', 'none');
    setPath(0);
  }

  let widthOrHeightChanged = false;
  if (svg.getAttribute('width') !== `${width}`) {
    svg.setAttribute('width', `${width}`);
    svg.style.width = `${width}px`;
    widthOrHeightChanged = true;
  }
  if (svg.getAttribute('height') !== `${height}`) {
    svg.setAttribute('height', `${height}`);
    svg.style.height = `${height}px`;
    widthOrHeightChanged = true;
  }

  if (widthOrHeightChanged) {
    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  svg.dataset[DATASET_NEXT_INDEX_KEY] = `${item.drawData.pathPoints.length}`;
  svg.dataset[DATASET_TOP_LEFT_X] = `${item.bounds.topLeft.x}`;
  svg.dataset[DATASET_TOP_LEFT_Y] = `${item.bounds.topLeft.y}`;

  path.setAttribute('stroke', getHexByTheme(item.drawData.strokeColor, theme));
  path.setAttribute('stroke-width', `${item.drawData.strokeWidth}`);

  // fixes issue where doesn't render
  svg.innerHTML += '';

  return svg;
};

export default renderFreeDraw;
