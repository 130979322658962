import produce from 'immer';
import {
  SET_VIDEO_PAUSED,
  SET_VIDEO_MEDIA_STREAM_CONTROLLER,
  SET_DEVICE_ID, SET_VIDEO_UI_STATE,
  MEDIA_CONTROLLER_INITIALIZED,
  SET_VIDEO_MEDIA_STREAM_CONTROLLER_ERROR,
  SET_VIDEO_CLIENT,
  SET_CALL,
  SET_DEVICE_PERMISSION,
  SET_VIDEO_MEDIA_STREAM_CONTROLLER_LOADING,
  SET_RETRYING_TO_CONNECT,
  SET_NUM_BITRATES,
  SET_HAS_TRIED_UPSHIFT,
  SET_UPSHIFT_UNSUCCESSFUL_TIME,
  SET_FILTER_UI_STATE,
  SET_BROADCAST,
  SET_FILTER_MEDIA_STREAM_CONTROLLER,
  SET_FILTER_MEDIA_STREAM_CONTROLLER_ERROR,
  SET_FILTER_MEDIA_STREAM_CONTROLLER_LOADING,
  SET_FILTER_PORTAL_ID,
  SET_AUDIO_MUTED,
  EncoderAction,
  FILTER_DEFAULT_MAX_BITRATE,
  SET_FILTER_MAX_BITRATE,
  SET_DOMINANT_SPEAKER,
  SET_DEVICE_ID_PREF,
} from 'actions/encoderActions';
import { RESET_ROOM, RESET_ALL_MEDIA, SharedAction } from 'actions/sharedActions';
import { BrowserPermissions, PermissionStateEnum } from 'utils/browserPermissions';
import { persistReducer, persistConfig } from 'store/localStoragePersistMiddleware';
import {
  EncoderState, EncoderRenderIds, EncoderRenderIdsEnumAsArray, MutableEncoderState, NoResetEncoderState, StaticEncoderState,
} from 'store/types';

/** All encoderRenderId render locations are false to start with */
export const initialEncoderPortalIds: EncoderRenderIds = EncoderRenderIdsEnumAsArray.reduce((map, enumStringKey) => {
  map[enumStringKey] = false;
  return map;
}, {} as EncoderRenderIds);

export const staticEncoderState: StaticEncoderState = {
  browserPermissions: new BrowserPermissions(),
};

export const noResetEncoderState: NoResetEncoderState = {
  mediaControllerInitialized: false,
  isVideoPaused: null,
  isAudioMuted: null,
  devicePermission: PermissionStateEnum.PROMPT,
  videoDeviceIdPref: null,
  audioDeviceIdPref: null,
  videoMediaStreamControllerLoading: false,
  filterMediaStreamControllerLoading: false,
};

export const mutableEncoderState: MutableEncoderState = {
  call: null,
  videoClient: null,
  isRetrying: false,
  numBitrates: 1,
  videoDeviceId: null,
  audioDeviceId: null,
  hasTriedUpshift: false,
  upshiftUnsuccessfulTime: 0,
  dominantSpeaker: null,

  // video encoder state
  videoMediaStreamControllerError: null,
  videoMediaStreamController: null,
  videoUiState: null,

  // filter encoder state
  filterMaxBitrate: FILTER_DEFAULT_MAX_BITRATE,
  broadcast: null,
  encoderRenderIds: initialEncoderPortalIds,
  filterUiState: null,
  filterMediaStreamController: null,
  filterMediaStreamControllerError: '',
};

export const initialState: EncoderState = {
  ...staticEncoderState,
  ...noResetEncoderState,
  ...mutableEncoderState,
};

const reducer = produce((draft: EncoderState, action: EncoderAction | SharedAction) => {
  switch (action.type) {
    case SET_VIDEO_PAUSED:
      draft.isVideoPaused = action.payload.isVideoPaused;
      break;
    case SET_VIDEO_MEDIA_STREAM_CONTROLLER:
      draft.videoMediaStreamController = action.mediaStreamController;
      break;
    case SET_VIDEO_MEDIA_STREAM_CONTROLLER_LOADING:
      draft.videoMediaStreamControllerLoading = action.payload.mediaStreamControllerLoading;
      break;
    case SET_VIDEO_UI_STATE:
      draft.videoUiState = action.payload.encoderUiState;
      break;
    case SET_DEVICE_ID: {
      draft[action.payload.deviceType] = action.payload.deviceId;
      draft[(`${action.payload.deviceType}Pref` as const)] = action.payload.deviceId;
      break;
    }
    case SET_DEVICE_ID_PREF:
      draft[action.payload.deviceType] = action.payload.deviceId;
      break;
    case MEDIA_CONTROLLER_INITIALIZED:
      draft.mediaControllerInitialized = true;
      break;
    case SET_VIDEO_MEDIA_STREAM_CONTROLLER_ERROR:
      draft.videoMediaStreamControllerError = action.payload.error;
      break;
    case SET_VIDEO_CLIENT:
      draft.videoClient = action.payload.videoClient;
      break;
    case RESET_ROOM: {
      Object.assign(draft, mutableEncoderState);
      break;
    }
    case SET_CALL:
      draft.call = action.payload.call;
      break;
    case SET_DEVICE_PERMISSION:
      draft.devicePermission = action.payload.devicePermission;
      break;
    case SET_RETRYING_TO_CONNECT:
      draft.isRetrying = action.payload.isRetrying;
      break;
    case SET_NUM_BITRATES:
      draft.numBitrates = action.payload.numBitrates;
      break;
    case SET_HAS_TRIED_UPSHIFT: {
      console.log('useManageCPU set', action.payload.hasTriedUpshift);
      draft.hasTriedUpshift = action.payload.hasTriedUpshift;
      break;
    }
    case SET_UPSHIFT_UNSUCCESSFUL_TIME:
      draft.upshiftUnsuccessfulTime = action.payload.upshiftUnsuccessfulTime;
      break;
    case RESET_ALL_MEDIA:
      draft.broadcast = null;
      draft.call = null;
      if (action.payload.resetVideoClient) {
        draft.videoClient = null;
      }
      break;
    case SET_FILTER_UI_STATE:
      draft.filterUiState = action.payload.filterUiState;
      break;
    case SET_BROADCAST:
      draft.broadcast = action.payload.broadcast;
      break;
    case SET_FILTER_MEDIA_STREAM_CONTROLLER:
      draft.filterMediaStreamController = action.payload.mediaStreamController;
      break;
    case SET_FILTER_MEDIA_STREAM_CONTROLLER_ERROR:
      draft.filterMediaStreamControllerError = action.payload.error;
      break;
    case SET_FILTER_MEDIA_STREAM_CONTROLLER_LOADING:
      draft.filterMediaStreamControllerLoading = action.payload.loading;
      break;
    case SET_FILTER_PORTAL_ID:
      draft.encoderRenderIds[action.payload.encoderRenderId] = action.payload.isRendering;
      break;
    case SET_AUDIO_MUTED:
      draft.isAudioMuted = action.payload.muted;
      break;
    case SET_FILTER_MAX_BITRATE:
      draft.filterMaxBitrate = action.payload.bitrate;
      break;
    case SET_DOMINANT_SPEAKER:
      draft.dominantSpeaker = action.payload.dominantSpeaker;
      break;
    default:
      break;
  }

  /* HACK: Similar to screenShareReducer.ts.
  From what I can tell, Redux doesn't like receiving a class instance in TypeScript.
  This shouldn't impact our type inference, since the StoreState is our source of truth for
  types rather than the return type of this reducer. Using unknown here just in case */
}, (initialState as unknown));

export default persistReducer(reducer, persistConfig.encoderState);
