import { OwnerBroadcastTypes } from 'actions/roomActions';
import { createSelector } from 'reselect';
import {
  RoomPermissionName, StoredKNKnocker, StoredKNParticipant, StoreState,
} from 'store/types';
import { KNRosterUser } from 'utils/roomState/knats';
import { sortByDisplayName } from 'utils/sortParticipants';

export const getParticipants = (state: StoreState) => state.roomState.room?.participants || {};

export const getOwnerId = (state: StoreState) => state.roomState.ownerId;

export const getOwnerName = (state: StoreState) => state.roomState.ownerName;

export const getPresenterId = (state: StoreState) => state.roomState.presenterId;

/** @todo this was done this way to quickly support the contract change with KNParticipant. Perhaps there's a better use of the new type */
export const getRoomScreenSharers = (state: StoreState) => (Object.values(state.roomState.room?.participants || {}) as StoredKNParticipant[])
  .filter(({ isScreenShared }) => isScreenShared)
  .map(({ userId }) => userId);

export const getRoomLayout = (state: StoreState) => state.roomState.layout;

export const getRoomId = (state: StoreState) => state.roomState.room?.id;

export const getLivelyRoomTokenString = (state: StoreState) => state.roomState.livelyRoomToken.token;

export const getRoomPermissions = (state: StoreState) => state.roomState.permissions;

export const getPermissionsLoading = (state: StoreState) => state.roomState.permissionsLoading;

export const getPermissionsUpdating = (state: StoreState) => state.roomState.permissionsUpdating;

export const getRoomJoinedDate = (state: StoreState) => state.roomState.roomJoinedTimestamp;

export const getFullscreenUserId = (state: StoreState) => state.roomState.fullscreenUserId;

export const getIsRoomJoinable = (state: StoreState) => state.roomState.isJoinable;

export const getIsRoomSessionLocked = (state: StoreState) => state.roomState.room?.isLocked;

export const getIsRoomLockLoading = (state: StoreState) => state.roomState.isRoomLockLoading;

export const getIsLockedFromJoining = (state: StoreState) => state.roomState.isLockedFromJoining;

export const getIsRequireLoginLoading = (state: StoreState) => state.roomState.isRequireLoginLoading;

export const getCallId = (state: StoreState) => state.roomState.room?.callId;

export const getKnockers = (state: StoreState) => state.roomState.room?.knockers;

export const getRoomWebsocket = (state: StoreState) => state.roomState.socket;

export const getIsDisabled = (state: StoreState) => state.roomState.isDisabled;

export const getJoinLoading = (state: StoreState) => state.roomState.loading;

export const getHeartbeatFailure = (state: StoreState) => state.roomState.heartbeatFailure;

export const getRoster = (state: StoreState) => sortByDisplayName(Object.values(state.roomState.room.roomRoster) as KNRosterUser[]);

export const getCalledOnId = (state: StoreState) => state.roomState.room.calledOnId;

export const getIsWSReady = (state: StoreState) => state.roomState.isWSReady;

export const getRoomRequiresLogin = (state: StoreState) => state.roomState.room.isLoginRequired;

export const getInitialRoomStateReceived = (state: StoreState) => state.roomState.initialRoomStateReceived;

export const getChaperoneLoading = (state: StoreState) => state.roomState.isChaperoneUpdateLoading;

export const getRoomRecordingStart = (state: StoreState) => Math.max(0, state.roomState.room.recordingStart);

export const getRoomHighlights = (state: StoreState) => state.roomState.room.highlights;

/** `openroom` and `chaperoneRequired` have opposite meanings:
openroom=0 and chaperoneRequired=true means the room closes when the owner leaves
openroom=1 and chaperoneRequired=false means the room stays open when the owner leaves */
export const getChaperoneRequired = (state: StoreState) => !state.roomState.room?.openroom;

export const getKnockerList = createSelector(
  getKnockers,
  (knockers) => Object.entries(knockers).reduce<Array<StoredKNKnocker & { userId: string }>>((arr, [userId, knocker]) => {
    arr.push({ ...knocker as StoredKNKnocker, userId });
    return arr;
  }, []),
);

export const getOwnerBroadcastLoading = (
  state: StoreState,
) => state.roomState.ownerBroadcastLoading;

export const getParticipantIds = createSelector(
  getParticipants,
  (participants) => Object.keys(participants),
);

export const getParticipantById = (participantId: string) => createSelector(
  getParticipants,
  (participants) => participants[participantId],
);

export const getParticipantsByIds = (ids: string[]) => createSelector(
  getParticipants,
  (participants) => ids.map((id) => participants[id]),
);

export const getParticipantsLength = createSelector(
  getParticipantIds,
  (ids) => ids.length,
);

export const getRoomOwner = createSelector(
  getParticipants,
  getOwnerId,
  (participants, ownerId) => (ownerId ? participants[ownerId] : null),
);

export const getPresenterUser = createSelector(
  getPresenterId,
  getParticipants,
  (userId, users) => (userId && users[userId] ? users[userId] : null),
);

export const getIsUserInFilmStrip = (userId: string) => createSelector(
  getRoomLayout,
  getPresenterId,
  (layout, id) => layout !== 1 && id !== userId,
);

/**
 * returns boolean for a single permission
 */
export const getRoomPermission = (permissionName: RoomPermissionName) => createSelector(
  getRoomPermissions,
  (permissions) => {
    if (permissions && permissionName in permissions) {
      return !!permissions[permissionName].permissionGranted;
    }
    return null;
  },
);

/**
 * Returns boolean for a particular userId and messageType of the ownerBroadcastLoadingState.
 */
export const getBroadcastMessageLoading = (userId: string, messageType: keyof OwnerBroadcastTypes) => createSelector(
  getOwnerBroadcastLoading,
  (ownerBroadcastLoading): boolean => {
    const hash = `${userId}${messageType}`;
    if (ownerBroadcastLoading[hash] === undefined) return false;
    return ownerBroadcastLoading[hash];
  },
);

export const getIsUserPresenter = (userId: string) => createSelector(
  getPresenterId,
  (presenterId) => presenterId === userId,
);

export const getRosterAsMap = createSelector(
  getRoster,
  (roster) => {
    const rosterMap: {[userId: string]: KNRosterUser} = {};
    roster.forEach((rosterUser) => {
      rosterMap[rosterUser.id] = rosterUser;
    });
    return rosterMap;
  },
);

export const getIsParticipantHandRaised = (id:string) => createSelector(
  getParticipantById(id),
  (user) => (user ? user.isHandRaised : false),
);

export const getDisplayNameById = (id:string) => createSelector(
  getParticipantById(id),
  (user) => (user ? user.displayName : ''),
);

export const getIsMasterView = createSelector(
  getRoomLayout,
  (layout) => layout === 5,
);

// returns the userId if the user is still in the room, false if the user is not in the room
export const getHighlightedId = createSelector(
  getRoomHighlights,
  getParticipants,
  (id, participants) => (participants[id] ? id : false),
);
