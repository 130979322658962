import { AxiosRequestConfig } from 'axios';
import request from 'utils/request';
import {
  SoundPreferences, AppThunkAction,
} from 'store/types';
import type { MakeActionType } from 'utils/typeUtils';
import { GetSoundPreferences, CCServiceSoundPreference, CCServiceSoundPreferencesEnum } from 'utils/ccService/types';
import mixpanel, { convertSoundPreferencesMeta } from 'utils/mixpanel';
import { handleError } from './sharedActions';

export const reducerName = 'preferencesState' as const;
export const SET_SOUND_SETTINGS = `${reducerName}/SET_SOUND_SETTINGS` as const;
export const SET_SOUND_SETTINGS_LOADING = `${reducerName}/SET_SOUND_SETTINGS_LOADING` as const;

export const setSoundPreferences = (soundPreferences: SoundPreferences) => ({
  type: SET_SOUND_SETTINGS,
  payload: { soundPreferences },
});

export const setSoundPreferencesLoading = (isLoading: boolean) => ({
  type: SET_SOUND_SETTINGS_LOADING,
  payload: { isLoading },
});

// provides strongly typed actions and autocomplete inside the reducer
export type PreferencesAction = MakeActionType<[
  typeof setSoundPreferences,
  typeof setSoundPreferencesLoading,
]>

/**
 * Fetch the user's room sound settings from CC Service--this determines
 * whether a particular sound notification will play for various events
 * that take place in the room.
 */
export const fetchSoundPreferences = (): AppThunkAction => async (dispatch) => {
  dispatch(setSoundPreferencesLoading(true));
  try {
    const { data: { globalSoundPreferences } } = await request<GetSoundPreferences>({ method: 'GET', url: 'users/sound-preferences' });

    const soundPreferences = {} as SoundPreferences;
    globalSoundPreferences.forEach((item) => {
      soundPreferences[item.name] = item;
    });

    dispatch(setSoundPreferences(soundPreferences));

    const meta = convertSoundPreferencesMeta(soundPreferences);

    mixpanel.setProfile(meta);
  } catch (error) {
    dispatch(handleError('Error fetching sound preferences', { error }));
  } finally {
    dispatch(setSoundPreferencesLoading(false));
  }
};

type SoundPreferencesRequest = {
  soundPreferenceId: CCServiceSoundPreference['soundPreferenceId'],
  enabled: CCServiceSoundPreference['enabled'],
}[];

/**
 * Sends a request to CC Service to update the user's sound notification preferences.
*/
export const updateSoundPreferences = (
  settingName: CCServiceSoundPreferencesEnum, boolean: boolean, trackingSource: 'App Settings' | 'Session',
): AppThunkAction => async (dispatch, getState) => {
  const { soundPreferences: prevSoundPreferences } = getState().preferencesState;

  const nextSoundPreferences: SoundPreferences = {
    ...prevSoundPreferences,
    [settingName]: {
      ...prevSoundPreferences[settingName],
      enabled: boolean ? 1 : 0,
    },
  };

  const mixpanelMeta = convertSoundPreferencesMeta(nextSoundPreferences);

  mixpanel.track('Sound Settings Change', { Source: trackingSource, ...mixpanelMeta });

  try {
    // we assume the request will succeed
    dispatch(setSoundPreferences(nextSoundPreferences));

    // CC Service requires preferences in array rather than object
    const soundPreferences = Object.keys(nextSoundPreferences).reduce(
      (array: SoundPreferencesRequest, key) => {
        const enumKey = key as CCServiceSoundPreferencesEnum;
        array.push({
          soundPreferenceId: nextSoundPreferences[enumKey].soundPreferenceId,
          enabled: nextSoundPreferences[enumKey].enabled,
        });
        return array;
      }, [],
    );

    const options: AxiosRequestConfig = {
      method: 'PUT',
      url: '/users/sound-preferences',
      data: { soundPreferences },
    };
    await request(options);

    mixpanel.track('Sound Settings Change', { Source: trackingSource, ...mixpanelMeta });
    mixpanel.setProfile(mixpanelMeta);
  } catch (error) {
    dispatch(handleError('Error updating room sound settings', { error }));
    // revert to previous state on failure
    dispatch(setSoundPreferences(prevSoundPreferences));
  }
};
