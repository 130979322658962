/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ComponentProps, forwardRef } from 'react';
import { tooltip } from 'styles/styles';
import {
  ColorsEnum, GREY_0, GREY_10, GREY_70, GREY_80, WHITE,
} from 'theme/ui/colors';
import { CSS } from 'types/css';

export type TooltipPositions = 'top' | 'right' | 'bottom' | 'left';

// create objects based off of the possible tooltip positions
type MappedPositions<K> = {
  [key in TooltipPositions]: K;
}

type TooltipVariant = 'white' | 'light' | 'dark';

interface TooltipProps extends ComponentProps<'div'>{
  active?: boolean,
  position?: TooltipPositions,
  variant?: TooltipVariant,
  zIndex?: number,
}

const tooltipLocation: MappedPositions<CSS> = {
  top: {
    top: '',
    bottom: 'calc(100% + 1.6rem)',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  right: {
    top: '50%',
    bottom: '50%',
    left: 'calc(100% + 1.6rem)',
    transform: 'translateY(-50%)',
  },
  bottom: {
    top: 'calc(100% + 1.6rem)',
    bottom: '',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  left: {
    top: '50%',
    bottom: '50%',
    left: '-1.6rem',
    transform: 'translate(-100%, -50%)',
  },
};

const triangleLocation: MappedPositions<CSS> = {
  top: {
    top: '100%',
    bottom: '100%',
    left: '50%',
  },
  right: {
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: '50%',
    left: '-1.1rem',
  },
  bottom: {
    top: '',
    bottom: '100%',
    left: '50%',
  },
  left: {
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: '50%',
    left: 'calc(100% + 1rem)',
  },
};

const borderIndexes: MappedPositions<number> = {
  top: 0,
  right: 1,
  bottom: 2,
  left: 3,
};


const DEFAULT_TRIANGLE_COLOR = ['transparent', 'transparent', 'transparent', 'transparent'];

export const TOOLTIP_TRIANGLE_2_CLASS = 'tooltip-triangle-2';

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({
  children = null, active = true, className = '', position = 'top', variant = 'dark', zIndex = tooltip, ...rest
}, ref) => {
  const triangleColor = [...DEFAULT_TRIANGLE_COLOR];
  let backgroundColor: string;
  let color: string;
  switch (variant) {
    case 'light':
      backgroundColor = GREY_0;
      color = GREY_70;
      break;
    case 'white':
      backgroundColor = WHITE;
      color = GREY_80;
      break;
    default:
      backgroundColor = ColorsEnum.BLACK.withOpacity(0.8);
      color = WHITE;
      break;
  }
  triangleColor[borderIndexes[position]] = backgroundColor;
  const triangleOutlineColor = [...DEFAULT_TRIANGLE_COLOR];
  triangleOutlineColor[borderIndexes[position]] = GREY_10;

  const triangleStyles: CSS = {
    content: "' '",
    position: 'absolute',
    marginLeft: '-1.1rem',
    borderWidth: '1.1rem',
    borderStyle: 'solid',
  };

  const tooltipStyles: CSS = {
    color,
    display: active ? 'flex' : 'none',
    flexDirection: 'column',
    position: 'absolute',
    zIndex,
    borderRadius: '0.8rem',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor,
    backdropFilter: variant === 'dark' ? 'blur(3rem)' : null,
    transformStyle: 'preserve-3d', // allows child to be positioned behind parent
    ...tooltipLocation[position],
    '& hr': {
      width: '100%',
      margin: 0,
      border: 'none',
      borderBottom: `1px solid ${GREY_70}`,
    },

    // main triangle
    '&:after': {
      ...triangleStyles,
      borderColor: triangleColor.join(' '),
      ...triangleLocation[position],
    },
  };

  // positioned behind the tooltip to create outline or box-shadow
  const triangle2Styles: CSS = {
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    transform: 'translateZ(-1px)', // positions child behind parent
    borderRadius: '0.9rem',
    backgroundColor: GREY_10,
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 2px)',
    '&:after': {
      ...triangleStyles,
      borderColor: triangleOutlineColor.join(' '),
      ...triangleLocation[position],
    },
  };

  return (
    <div role="tooltip" ref={ref} sx={tooltipStyles} className={className} {...rest}>
      {/* `light` uses this for outline & `white` uses this for box-shadow effect */}
      {variant !== 'dark' && <div className={TOOLTIP_TRIANGLE_2_CLASS} sx={triangle2Styles} />}
      {children}
    </div>
  );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;
