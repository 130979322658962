import { createSelector } from 'reselect';
import { StoredKNParticipant, StoreState } from 'store/types';
import { getParticipants } from './roomSelectors';

export const getNudges = (state: StoreState) => state.nudgeState.nudges;

export const getNudgingUserId = (state: StoreState) => state.nudgeState.nudgingUserId;

export const getSelectedNudgeGif = (state: StoreState) => state.nudgeState.selectedNudgeGif;

export const getSelectedNudgeEmoji = (state: StoreState) => state.nudgeState.selectedNudgeEmoji;

export const getNudge = (userId: string) => createSelector(
  getNudges,
  (nudges) => nudges[userId],
);

export const getNudgingUser = createSelector(
  getNudgingUserId,
  getParticipants,
  (userId, participants): StoredKNParticipant | null => participants[userId] || null,
);
