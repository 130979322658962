import { SoundPreferences } from 'store/types';

type OnOff = 'On' | 'Off'
type YesNo = 'Yes' | 'No'

export const asOnOff = (b: boolean): OnOff => (b ? 'On' : 'Off');
export const asYesNo = (b: boolean): YesNo => (b ? 'Yes' : 'No');

interface PermissionsMetadata {
  'Screen Share': OnOff,
  'Chat with Everyone': OnOff,
  'Direct Message': OnOff,
  'Private Nudge': OnOff
}

interface DevicePreferencesMetadata {
  'Microphone': string,
  'Speakers': string,
  'Camera': string
}

interface GroupNumbersMetadata {
  'Number of groups': number,
  'Number of members': number
}

interface SoundPreferencesMetadata {
  'Someone Joins': OnOff,
  'Someone Leaves': OnOff,
  'New Everyone Message': OnOff,
  'New Direct Message': OnOff,
  'Guest Raises Hand': OnOff,
  'Called On': OnOff
}

export const convertSoundPreferencesMeta = (s: SoundPreferences): SoundPreferencesMetadata => ({
  'Someone Joins': asOnOff(!!s.join.enabled),
  'Someone Leaves': asOnOff(!!s.leave.enabled),
  'New Everyone Message': asOnOff(!!s.roomChatMessage.enabled),
  'New Direct Message': asOnOff(!!s.directChatMessage.enabled),
  'Guest Raises Hand': asOnOff(!!s.raiseHand.enabled),
  'Called On': asOnOff(!!s.calledOn.enabled),
});

export interface MixpanelUserProfile extends
  SoundPreferencesMetadata,
  DevicePreferencesMetadata {
  'Display Name': string,
  'Sign Up Date': string, // should use Date.toISOString
}

export interface MixpanelSuperEventProperties {
  'Room ID': string,
  Role: 'Owner' | 'Guest' | '',
  'Display Name': string
}

/** This type maps expected event names to the expected metadata to be given */
export interface MixpanelEvents {
  'Create Room Submit': PermissionsMetadata & { 'Login Required': YesNo, 'Locked': YesNo, Chaperone: OnOff },
  'Join Session': {
    'Microphone': OnOff,
    'Video': OnOff,
  },
  'Leave Session': {
    'Microphone': OnOff,
    'Video': OnOff,
    'Leave Type': 'Call End' | 'Close Tab'
    // Unsupported:
    // 'Number of Participants': number
    // 'Total Time': Date
  },
  'View Page': {
    Page: 'Room' | 'Dashboard' | 'Join' | 'Attendance' |
      'Room Settings' | 'Mobile Download' | 'Log In' | 'Log Out' |
      'Privacy Policy' | 'Session Expired' | 'Privacy Policy' |
      'Sign Up' | 'Forgot Password' | 'Change Password'
  },
  'Apple App Store Link Click': undefined,
  'Google Play Store Link Click': undefined,
  'Sign Up': undefined,
  'Log Out': undefined,
  'Registration Complete Screen View': undefined,
  'Sign Up Button Click': undefined,
  'Account Create': {
    Status: 'Success' | 'Failure',
    Reason: string
  },
  'Create New Room Click': undefined,
  'Share Screen Click': undefined,
  'Share Screen Starts': {
    'Screen Type': 'Entire Screen' | 'Window' | 'Tab' | 'Application' | ''
  },
  'Share Screen Stop': undefined,
  'Chat Click': undefined,
  'Chat Send': {
    'User Type': 'Everyone' | 'User' | 'Group'
  },
  'Nudge Click': undefined,
  'Nudge Sent': {
    Message: YesNo,
    Gif: YesNo
  },
  'Room Session Settings Click': undefined,
  'Room Delete': undefined,
  'Room Delete Confirm': undefined,
  'Guest Features Click': PermissionsMetadata,
  'Guest Feature Change': PermissionsMetadata & {
    'Source': 'Room Settings' | 'Session'
  },
  'App Settings Click': undefined,
  'Sound Settings Click': {
    'Source': 'App Settings' | 'Session',
  },
  'Sound Settings Change': SoundPreferencesMetadata & {
    'Source': 'App Settings' | 'Session'
  },
  'Chalkboard Button Click': undefined,
  'Preferences Click': {
    'Source': 'App Settings' | 'Join View'
  },
  'Preference Change': DevicePreferencesMetadata,
  'Audio On': undefined,
  'Audio Off': undefined,
  'Video On': undefined,
  'Video Off': undefined,
  'Breakout Groups Click': undefined,
  'Group Type Create': GroupNumbersMetadata & {
    'Group Type': 'Random' | 'Manually'
  },
  'Groups Launch': GroupNumbersMetadata,
  'Listen in Group': undefined,
  'Recall Groups Click': GroupNumbersMetadata,
  'Recall Groups Confirm': GroupNumbersMetadata,
  'Recall Groups Now': GroupNumbersMetadata,
  'Group Delete': undefined,
  'Copy Link': {
    'Source': 'Join View' | 'Session'
  },
  'Raises Hand': undefined,
  'Icebreaker Button Click': undefined,
}
