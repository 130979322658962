import { EnumBase } from 'utils/enum';
import { Any } from 'ts-toolbelt';
import constrainNum from 'utils/constrainNum';

/**
 * This creates a `string` sub-type that is unique to ColorsEnumValue.
 * This allows us to require a `ColorsEnumValue` prop in components that should be
 * receiving a color guide color rather than any generic string.
 *
 * For more info on this utility,
 * @see https://github.com/millsp/ts-toolbelt/blob/319e55123b9571d49f34eca3e5926e41ca73e0f3/sources/Any/Type.ts
 */
export type ColorsEnumValue = Any.Type<string, 'ColorEnumValue'>;

/**
 * # Style Guide Colors
 *
 * Defining our Style Guide colors as an enum and then extracting into their own variables allows us
 * to provide strong typing where a style guide `ColorEnumValue` is expected as a prop, since our extracted
 * style guide colors retain their TypeScript identity as `ColorsEnumValue`s (rather than plain strings).
 *
 * ## Examples
 *
 * ### Prop Types
 * if `ColorsEnum` is used as a prop type, then that prop can receive either an enum variant:
 *
 * ```js
 * <Component prop={ColorsEnum.TEAL_LIGHT.value} />
 * ```
 *
 * or the variable directly, since the variable's type is still tied to the Enum:
 *
 * ```js
 * <Component prop={TEAL_LIGHT} />
 * ```
 *
 * ### Converting to String
 *
 * The stand-alone variable will only ever return a string value:
 * ```
 *  GREY_0 // "#F2F2F2", type ColorsEnumValue
 * GREY_0.toString() // "#F2F2F2", type string
 * `${GREY_0}` // "#F2F2F2", type string
 * ```
 *
 * The ColorsEnum color property will return the ColorsEnum class, unless
 * `ColorsEnum.prototype.toString()` method is called, which will format the
 * color with the default value as a string:
 * ```
 * ColorsEnum.GREY_0 // ColorsEnum {value: "#F2F2F2"}", type ColorsEnum
 * ColorsEnum.GREY_0.value // "#F2F2F2", type ColorsEnumValue
 * ColorsEnum.GREY_0.withOpacity(0.5) // "#F2F2F27F", type ColorsEnumValue
 * ColorsEnum.GREY_0.withOpacity(1) // "#F2F2F2FF", type ColorsEnumValue
 * ColorsEnum.GREY_0.toString() // "#F2F2F2", type ColorsEnumValue
 * `${ColorsEnum.GREY_0}` // "#F2F2F2", type string
 *```
 */
export class ColorsEnum extends EnumBase {
  public static readonly = '';
  public static readonly TEAL_LIGHT = new ColorsEnum('#AEE0DB' as ColorsEnumValue)
  public static readonly TEAL_MEDIUM = new ColorsEnum('#86D0CA' as ColorsEnumValue)
  public static readonly TEAL_TRUE = new ColorsEnum('#5DC1B8' as ColorsEnumValue)
  public static readonly TEAL_DARK = new ColorsEnum('#35B1A6' as ColorsEnumValue)
  public static readonly BLUE_LIGHT = new ColorsEnum('#A1B9E8' as ColorsEnumValue)

  public static readonly BLUE_MEDIUM = new ColorsEnum('#829ED6' as ColorsEnumValue)
  public static readonly BLUE_TRUE = new ColorsEnum('#6182C1' as ColorsEnumValue)
  public static readonly BLUE_DARK = new ColorsEnum('#4D6DAC' as ColorsEnumValue)

  public static readonly PURPLE_LIGHT = new ColorsEnum('#C9AFF3' as ColorsEnumValue)
  public static readonly PURPLE_MEDIUM = new ColorsEnum('#B394E8' as ColorsEnumValue)
  public static readonly PURPLE_TRUE = new ColorsEnum('#9E7AD9' as ColorsEnumValue)
  public static readonly PURPLE_DARK = new ColorsEnum('#8364B8' as ColorsEnumValue)

  public static readonly ORANGE_LIGHT = new ColorsEnum('#F7BDAF' as ColorsEnumValue)
  public static readonly ORANGE_MEDIUM = new ColorsEnum('#F39C87' as ColorsEnumValue)
  public static readonly ORANGE_TRUE = new ColorsEnum('#EF7B5F' as ColorsEnumValue)
  public static readonly ORANGE_DARK = new ColorsEnum('#EB5A37' as ColorsEnumValue)

  public static readonly YELLOW_LIGHT = new ColorsEnum('#FFE799' as ColorsEnumValue)
  public static readonly YELLOW_MEDIUM = new ColorsEnum('#FFDB66' as ColorsEnumValue)
  public static readonly YELLOW_TRUE = new ColorsEnum('#FFCF33' as ColorsEnumValue)
  public static readonly YELLOW_DARK = new ColorsEnum('#FFC300' as ColorsEnumValue)

  public static readonly RED_LIGHT = new ColorsEnum('#F59595' as ColorsEnumValue)
  public static readonly RED_MEDIUM = new ColorsEnum('#E26B6B' as ColorsEnumValue)
  public static readonly RED_TRUE = new ColorsEnum('#DB4646' as ColorsEnumValue)
  public static readonly RED_DARK = new ColorsEnum('#AF2323' as ColorsEnumValue)

  public static readonly GREY_100 = new ColorsEnum('#1C1C1C' as ColorsEnumValue)
  public static readonly GREY_90 = new ColorsEnum('#333333' as ColorsEnumValue)
  public static readonly GREY_80 = new ColorsEnum('#4D4D4D' as ColorsEnumValue)
  public static readonly GREY_70 = new ColorsEnum('#585858' as ColorsEnumValue)
  public static readonly GREY_60 = new ColorsEnum('#7F7F7F' as ColorsEnumValue)
  public static readonly GREY_50 = new ColorsEnum('#949494' as ColorsEnumValue)
  public static readonly GREY_40 = new ColorsEnum('#B3B3B3' as ColorsEnumValue)
  public static readonly GREY_30 = new ColorsEnum('#CCCCCC' as ColorsEnumValue)
  public static readonly GREY_20 = new ColorsEnum('#D9D9D9' as ColorsEnumValue)
  public static readonly GREY_10 = new ColorsEnum('#E0E0E0' as ColorsEnumValue)
  public static readonly GREY_5 = new ColorsEnum('#E6E6E6' as ColorsEnumValue)
  public static readonly GREY_0 = new ColorsEnum('#F2F2F2' as ColorsEnumValue)

  public static readonly BLACK = new ColorsEnum('#000000' as ColorsEnumValue)
  public static readonly WHITE = new ColorsEnum('#ffffff' as ColorsEnumValue)

  // BELOW: OLD GUIDE
  public static readonly BLUE_100 = new ColorsEnum('#144196' as ColorsEnumValue)
  public static readonly BLUE_90 = new ColorsEnum('#2C54A1' as ColorsEnumValue)
  public static readonly BLUE_80 = new ColorsEnum('#4367AB' as ColorsEnumValue)
  public static readonly BLUE_70 = new ColorsEnum('#5B7AB6' as ColorsEnumValue)
  public static readonly BLUE_60 = new ColorsEnum('#728DC0' as ColorsEnumValue)
  public static readonly BLUE_50 = new ColorsEnum('#899FCA' as ColorsEnumValue)
  public static readonly BLUE_40 = new ColorsEnum('#A1B3D5' as ColorsEnumValue)
  public static readonly BLUE_30 = new ColorsEnum('#B9C6E0' as ColorsEnumValue)
  public static readonly BLUE_20 = new ColorsEnum('#D0D9EA' as ColorsEnumValue)
  public static readonly BLUE_10 = new ColorsEnum('#DCE3EF' as ColorsEnumValue)
  public static readonly BLUE_5 = new ColorsEnum('#E8ECF5' as ColorsEnumValue)
  public static readonly BLUE_0 = new ColorsEnum('#E8ECF5' as ColorsEnumValue)

  public static readonly TEAL_100 = new ColorsEnum('#35B1A6' as ColorsEnumValue)
  public static readonly TEAL_90 = new ColorsEnum('#4AB9AF' as ColorsEnumValue)
  public static readonly TEAL_80 = new ColorsEnum('#5DC1B8' as ColorsEnumValue)
  public static readonly TEAL_70 = new ColorsEnum('#72C9C1' as ColorsEnumValue)
  public static readonly TEAL_60 = new ColorsEnum('#86D0CA' as ColorsEnumValue)
  public static readonly TEAL_50 = new ColorsEnum('#99D7D2' as ColorsEnumValue)
  public static readonly TEAL_40 = new ColorsEnum('#AEE0DB' as ColorsEnumValue)
  public static readonly TEAL_30 = new ColorsEnum('#C3E8E5' as ColorsEnumValue)
  public static readonly TEAL_20 = new ColorsEnum('#D7EFED' as ColorsEnumValue)
  public static readonly TEAL_10 = new ColorsEnum('#E1F4F2' as ColorsEnumValue)
  public static readonly TEAL_5 = new ColorsEnum('#EBF8F7' as ColorsEnumValue)
  public static readonly TEAL_0 = new ColorsEnum('#F5FBFA' as ColorsEnumValue)

  public static readonly PURPLE_100 = new ColorsEnum('#A31D82' as ColorsEnumValue)
  public static readonly PURPLE_90 = new ColorsEnum('#AD348F' as ColorsEnumValue)
  public static readonly PURPLE_80 = new ColorsEnum('#B54A9B' as ColorsEnumValue)
  public static readonly PURPLE_70 = new ColorsEnum('#BF61A8' as ColorsEnumValue)
  public static readonly PURPLE_60 = new ColorsEnum('#C877B4' as ColorsEnumValue)
  public static readonly PURPLE_50 = new ColorsEnum('#D08DC0' as ColorsEnumValue)
  public static readonly PURPLE_40 = new ColorsEnum('#DAA5CD' as ColorsEnumValue)
  public static readonly PURPLE_30 = new ColorsEnum('#E4BCDA' as ColorsEnumValue)
  public static readonly PURPLE_20 = new ColorsEnum('#EDD2E6' as ColorsEnumValue)
  public static readonly PURPLE_10 = new ColorsEnum('#F1DDEC' as ColorsEnumValue)
  public static readonly PURPLE_5 = new ColorsEnum('#F6E9F3' as ColorsEnumValue)
  public static readonly PURPLE_0 = new ColorsEnum('#FAF3F8' as ColorsEnumValue)

  public static readonly ORANGE_100 = new ColorsEnum('#EB5A37' as ColorsEnumValue)
  public static readonly ORANGE_90 = new ColorsEnum('#ED6B4B' as ColorsEnumValue)
  public static readonly ORANGE_80 = new ColorsEnum('#EF7B5F' as ColorsEnumValue)
  public static readonly ORANGE_70 = new ColorsEnum('#F18C73' as ColorsEnumValue)
  public static readonly ORANGE_60 = new ColorsEnum('#F39C87' as ColorsEnumValue)
  public static readonly ORANGE_50 = new ColorsEnum('#F4AC9A' as ColorsEnumValue)
  public static readonly ORANGE_40 = new ColorsEnum('#F7BDAF' as ColorsEnumValue)
  public static readonly ORANGE_30 = new ColorsEnum('#F9CEC3' as ColorsEnumValue)
  public static readonly ORANGE_20 = new ColorsEnum('#FBDED7' as ColorsEnumValue)
  public static readonly ORANGE_10 = new ColorsEnum('#FCE6E1' as ColorsEnumValue)
  public static readonly ORANGE_5 = new ColorsEnum('#FDEFEB' as ColorsEnumValue)
  public static readonly ORANGE_0 = new ColorsEnum('#FEF7F5' as ColorsEnumValue)

  public static readonly YELLOW_100 = new ColorsEnum('#FFC300' as ColorsEnumValue)
  public static readonly YELLOW_90 = new ColorsEnum('#FFC91A' as ColorsEnumValue)
  public static readonly YELLOW_80 = new ColorsEnum('#FFCF33' as ColorsEnumValue)
  public static readonly YELLOW_70 = new ColorsEnum('#FFD54D' as ColorsEnumValue)
  public static readonly YELLOW_60 = new ColorsEnum('#FFDB66' as ColorsEnumValue)
  public static readonly YELLOW_50 = new ColorsEnum('#FFE07F' as ColorsEnumValue)
  public static readonly YELLOW_40 = new ColorsEnum('#FFE799' as ColorsEnumValue)
  public static readonly YELLOW_30 = new ColorsEnum('#FFEDB3' as ColorsEnumValue)
  public static readonly YELLOW_20 = new ColorsEnum('#FFF3CC' as ColorsEnumValue)
  public static readonly YELLOW_10 = new ColorsEnum('#FFF6D9' as ColorsEnumValue)
  public static readonly YELLOW_5 = new ColorsEnum('#FFF9E6' as ColorsEnumValue)
  public static readonly YELLOW_0 = new ColorsEnum('#FFFCF2' as ColorsEnumValue)

  public static readonly PINK_100 = new ColorsEnum('#EC1A72' as ColorsEnumValue)
  public static readonly PINK_90 = new ColorsEnum('#EE3181' as ColorsEnumValue)
  public static readonly PINK_80 = new ColorsEnum('#F0488E' as ColorsEnumValue)
  public static readonly PINK_70 = new ColorsEnum('#F25F9D' as ColorsEnumValue)
  public static readonly PINK_60 = new ColorsEnum('#F476AA' as ColorsEnumValue)
  public static readonly PINK_50 = new ColorsEnum('#F58CB8' as ColorsEnumValue)
  public static readonly PINK_40 = new ColorsEnum('#F7A3C7' as ColorsEnumValue)
  public static readonly PINK_30 = new ColorsEnum('#FABBD5' as ColorsEnumValue)
  public static readonly PINK_20 = new ColorsEnum('#FBD1E3' as ColorsEnumValue)
  public static readonly PINK_10 = new ColorsEnum('#FCDDEA' as ColorsEnumValue)
  public static readonly PINK_5 = new ColorsEnum('#FEE9F1' as ColorsEnumValue)
  public static readonly PINK_0 = new ColorsEnum('#FEF3F8' as ColorsEnumValue)

  public static readonly RED_100 = new ColorsEnum('#832A2A' as ColorsEnumValue)
  public static readonly RED_90 = new ColorsEnum('#993131' as ColorsEnumValue)
  public static readonly RED_80 = new ColorsEnum('#AF3838' as ColorsEnumValue)
  public static readonly RED_70 = new ColorsEnum('#C53F3F' as ColorsEnumValue)
  public static readonly RED_60 = new ColorsEnum('#DB4646' as ColorsEnumValue)
  public static readonly RED_50 = new ColorsEnum('#DF5959' as ColorsEnumValue)
  public static readonly RED_40 = new ColorsEnum('#E26B6B' as ColorsEnumValue)
  public static readonly RED_30 = new ColorsEnum('#E67E7E' as ColorsEnumValue)
  public static readonly RED_20 = new ColorsEnum('#E99090' as ColorsEnumValue)
  public static readonly RED_10 = new ColorsEnum('#ECA2A2' as ColorsEnumValue)
  public static readonly RED_5 = new ColorsEnum('#F1B5B5' as ColorsEnumValue)
  public static readonly RED_0 = new ColorsEnum('#F5C8C8' as ColorsEnumValue)

  protected constructor(public value: ColorsEnumValue) {
    super();
  }

  public static asArray() {
    return super.asArray() as Array<ColorsEnum>;
  }

  public static asObject() {
    return super.asObject() as { [key: string]: ColorsEnum };
  }

  /**
   * Returns the stringified hex value of the color value.
   */
  public toString() {
    return `${this.value}` as ColorsEnumValue;
  }

  /**
   * Returns the stringified hex value of the color, with the
   * decimal opacity converted to hex and appended to the string.
   *
   * For more information about specifying opacity in hex values,
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb_colors
   */
  public withOpacity(decimalOpacity: number) {
    const constrainedOpacity = constrainNum(decimalOpacity, 0, 1);
    const opacityAsHex = Math.floor(constrainedOpacity * 255).toString(16).toUpperCase().padStart(2, '0');
    return `${this.value}${opacityAsHex}` as ColorsEnumValue;
  }
}


export const {
  TEAL_LIGHT: { value: TEAL_LIGHT },
  TEAL_MEDIUM: { value: TEAL_MEDIUM },
  TEAL_TRUE: { value: TEAL_TRUE },
  TEAL_DARK: { value: TEAL_DARK },

  BLUE_LIGHT: { value: BLUE_LIGHT },
  BLUE_MEDIUM: { value: BLUE_MEDIUM },
  BLUE_TRUE: { value: BLUE_TRUE },
  BLUE_DARK: { value: BLUE_DARK },

  PURPLE_LIGHT: { value: PURPLE_LIGHT },
  PURPLE_MEDIUM: { value: PURPLE_MEDIUM },
  PURPLE_TRUE: { value: PURPLE_TRUE },
  PURPLE_DARK: { value: PURPLE_DARK },

  ORANGE_LIGHT: { value: ORANGE_LIGHT },
  ORANGE_MEDIUM: { value: ORANGE_MEDIUM },
  ORANGE_TRUE: { value: ORANGE_TRUE },
  ORANGE_DARK: { value: ORANGE_DARK },

  YELLOW_LIGHT: { value: YELLOW_LIGHT },
  YELLOW_MEDIUM: { value: YELLOW_MEDIUM },
  YELLOW_TRUE: { value: YELLOW_TRUE },
  YELLOW_DARK: { value: YELLOW_DARK },

  RED_LIGHT: { value: RED_LIGHT },
  RED_MEDIUM: { value: RED_MEDIUM },
  RED_TRUE: { value: RED_TRUE },
  RED_DARK: { value: RED_DARK },

  GREY_100: { value: GREY_100 },
  GREY_90: { value: GREY_90 },
  GREY_80: { value: GREY_80 },
  GREY_70: { value: GREY_70 },
  GREY_60: { value: GREY_60 },
  GREY_50: { value: GREY_50 },
  GREY_40: { value: GREY_40 },
  GREY_30: { value: GREY_30 },
  GREY_20: { value: GREY_20 },
  GREY_10: { value: GREY_10 },
  GREY_5: { value: GREY_5 },
  GREY_0: { value: GREY_0 },

  BLACK: { value: BLACK },
  WHITE: { value: WHITE },

  // BELOW: OLD GUIDE

  BLUE_100: { value: BLUE_100 },
  BLUE_90: { value: BLUE_90 },
  BLUE_80: { value: BLUE_80 },
  BLUE_70: { value: BLUE_70 },
  BLUE_60: { value: BLUE_60 },
  BLUE_50: { value: BLUE_50 },
  BLUE_40: { value: BLUE_40 },
  BLUE_30: { value: BLUE_30 },
  BLUE_20: { value: BLUE_20 },
  BLUE_10: { value: BLUE_10 },
  BLUE_5: { value: BLUE_5 },
  BLUE_0: { value: BLUE_0 },

  TEAL_100: { value: TEAL_100 },
  TEAL_90: { value: TEAL_90 },
  TEAL_80: { value: TEAL_80 },
  TEAL_70: { value: TEAL_70 },
  TEAL_60: { value: TEAL_60 },
  TEAL_50: { value: TEAL_50 },
  TEAL_40: { value: TEAL_40 },
  TEAL_30: { value: TEAL_30 },
  TEAL_20: { value: TEAL_20 },
  TEAL_10: { value: TEAL_10 },
  TEAL_5: { value: TEAL_5 },
  TEAL_0: { value: TEAL_0 },

  PURPLE_100: { value: PURPLE_100 },
  PURPLE_90: { value: PURPLE_90 },
  PURPLE_80: { value: PURPLE_80 },
  PURPLE_70: { value: PURPLE_70 },
  PURPLE_60: { value: PURPLE_60 },
  PURPLE_50: { value: PURPLE_50 },
  PURPLE_40: { value: PURPLE_40 },
  PURPLE_30: { value: PURPLE_30 },
  PURPLE_20: { value: PURPLE_20 },
  PURPLE_10: { value: PURPLE_10 },
  PURPLE_5: { value: PURPLE_5 },
  PURPLE_0: { value: PURPLE_0 },

  ORANGE_100: { value: ORANGE_100 },
  ORANGE_90: { value: ORANGE_90 },
  ORANGE_80: { value: ORANGE_80 },
  ORANGE_70: { value: ORANGE_70 },
  ORANGE_60: { value: ORANGE_60 },
  ORANGE_50: { value: ORANGE_50 },
  ORANGE_40: { value: ORANGE_40 },
  ORANGE_30: { value: ORANGE_30 },
  ORANGE_20: { value: ORANGE_20 },
  ORANGE_10: { value: ORANGE_10 },
  ORANGE_5: { value: ORANGE_5 },
  ORANGE_0: { value: ORANGE_0 },

  YELLOW_100: { value: YELLOW_100 },
  YELLOW_90: { value: YELLOW_90 },
  YELLOW_80: { value: YELLOW_80 },
  YELLOW_70: { value: YELLOW_70 },
  YELLOW_60: { value: YELLOW_60 },
  YELLOW_50: { value: YELLOW_50 },
  YELLOW_40: { value: YELLOW_40 },
  YELLOW_30: { value: YELLOW_30 },
  YELLOW_20: { value: YELLOW_20 },
  YELLOW_10: { value: YELLOW_10 },
  YELLOW_5: { value: YELLOW_5 },
  YELLOW_0: { value: YELLOW_0 },

  PINK_100: { value: PINK_100 },
  PINK_90: { value: PINK_90 },
  PINK_80: { value: PINK_80 },
  PINK_70: { value: PINK_70 },
  PINK_60: { value: PINK_60 },
  PINK_50: { value: PINK_50 },
  PINK_40: { value: PINK_40 },
  PINK_30: { value: PINK_30 },
  PINK_20: { value: PINK_20 },
  PINK_10: { value: PINK_10 },
  PINK_5: { value: PINK_5 },
  PINK_0: { value: PINK_0 },

  RED_100: { value: RED_100 },
  RED_90: { value: RED_90 },
  RED_80: { value: RED_80 },
  RED_70: { value: RED_70 },
  RED_60: { value: RED_60 },
  RED_50: { value: RED_50 },
  RED_40: { value: RED_40 },
  RED_30: { value: RED_30 },
  RED_20: { value: RED_20 },
  RED_10: { value: RED_10 },
  RED_5: { value: RED_5 },
  RED_0: { value: RED_0 },
} = ColorsEnum;
