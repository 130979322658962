
import { getHexByTheme } from '../../../common/colorUtils';
import { RenderItemFunc } from '../common';

const renderEllipse: RenderItemFunc<'ellipse'> = (item, theme, existing) => {
  const el = existing || document.createElement('div');

  const { size } = item.bounds;

  el.style.border = `${item.drawData.strokeWidth}px solid ${getHexByTheme(item.drawData.strokeColor, theme)}`;
  el.style.width = `${size.width}px`;
  el.style.height = `${size.height}px`;

  if (!existing) {
    el.style.borderRadius = '50%';
  }

  return el;
};

export default renderEllipse;
