
import produce from 'immer';
import {
  ChalkboardAction,
  SET_IS_CHALKBOARD_OPEN,
  TOGGLE_CHALKBOARD_TIP_DISMISSED,
  SET_TOOL_COLOR,
  SET_THEME,
  SET_TEMPLATE,
  SET_TOOL_MODE,
  SET_AWAITING_CLEAR,
  SET_ITEMS,
  REMOVE_ITEMS,
  SET_IS_DOWNLOADING,
} from 'actions/chalkboardActions';
import { ChalkboardState } from 'store/types';
import { persistReducer } from 'store/localStoragePersistMiddleware';
import { RESET_ROOM } from 'actions/sharedActions';
import { Action } from 'redux';

export const initialState: ChalkboardState = {
  isChalkboardOpen: false,
  tips: {
    smartShape: { dismissed: false },
  },
  template: null,
  theme: 'dark',
  toolColor: 'primary',
  toolMode: 'freeDraw',
  isAwaitingClear: false,
  committedItems: {},
  isDownloading: false,
};

const reducer = produce((draft: ChalkboardState, action: ChalkboardAction | Action<typeof RESET_ROOM>) => {
  switch (action.type) {
    case SET_IS_CHALKBOARD_OPEN:
      draft.isChalkboardOpen = action.payload.isOpen;
      break;
    case TOGGLE_CHALKBOARD_TIP_DISMISSED:
      draft.tips[action.payload.tip].dismissed = !draft.tips[action.payload.tip].dismissed;
      break;
    case SET_TOOL_COLOR:
      draft.toolColor = action.payload.color;
      break;
    case SET_THEME:
      draft.theme = action.payload.theme;
      break;
    case SET_TEMPLATE:
      draft.template = action.payload.template;
      break;
    case SET_TOOL_MODE:
      draft.toolMode = action.payload.mode;
      break;
    case RESET_ROOM:
      draft.isChalkboardOpen = false;
      draft.template = initialState.template;
      draft.theme = initialState.theme;
      draft.toolColor = initialState.toolColor;
      draft.committedItems = {};
      break;
    case SET_AWAITING_CLEAR:
      draft.isAwaitingClear = action.payload.awaiting;
      break;
    case SET_ITEMS:
      for (const item of action.payload.items) {
        draft.committedItems[item.id] = item;
      }
      break;
    case REMOVE_ITEMS:
      for (const item of action.payload.items) {
        delete draft.committedItems[item.id];
      }
      break;
    case SET_IS_DOWNLOADING:
      draft.isDownloading = action.payload.isDownloading;
      break;
    default:
      break;
  }
}, initialState);

export default persistReducer<ChalkboardState>(reducer, ['tips']);
