import {
  DecodedValueMap, QueryParamConfigMap, encodeQueryParams, useQueryParams, StringParam, QueryParamConfig, withDefault, BooleanParam,
} from 'use-query-params';
import { stringify } from 'query-string';
import {
  ExtractRouteParams, generatePath, matchPath, useParams,
} from 'react-router';
import { EnumBase } from './enum';

type UrlParamOptionsRequired<Path extends string> = keyof ExtractRouteParams<Path> extends undefined ? false : true

/** For making a StringParam for a specific string union */
type CustomStringParam<S extends string> = QueryParamConfig<S | null | undefined>

export const customizeStringParam = <S extends string>() => StringParam as CustomStringParam<S>;

export type RoomManagerSettingsSection = 'room' | 'security' | 'guest-features'
/**
 * Configuration of client-side routing. Includes
 * methods for matching and generating paths, validating URL
 * and query params with types specific for each route.
 *
 * Path matching and URL parameter typing/logic are handled by react-router
 * utilities. See https://github.com/pbeshai/use-query-params/tree/master/packages/use-query-params#readme
 * for docs about the query parameter configuration.
 */
export default class RouteEnum<Path extends string, QPC extends QueryParamConfigMap> extends EnumBase {
  static readonly HOME = new RouteEnum('/', { code: StringParam, err: StringParam, token: StringParam })
  static readonly DASHBOARD = new RouteEnum('/dashboard', {})
  static readonly MOBILE_DOWNLOAD = new RouteEnum('/mobile-app', {})
  static readonly SIGN_UP = new RouteEnum('/signup', {})
  static readonly SESSION_EXPIRED = new RouteEnum('/session-expired', {})
  static readonly FORGOT_PASSWORD = new RouteEnum('/forgot-password', {})
  static readonly RESET_PASSWORD = new RouteEnum('/reset/:roomId', {})
  static readonly LOGOUT = new RouteEnum('/logout', {})
  static readonly TOKEN_AUTH = new RouteEnum('/token-auth', {})
  static readonly ROOM = new RouteEnum('/room/:roomId', {})

  // Tests are in place and should be kept up-to-date to help ensure these following related paths do not contain discrepancies
  static readonly ROOM_MANAGER = new RouteEnum('/room-manager/:view/:roomId/:userId?', {})
  static readonly ROOM_MANAGER__JOIN = new RouteEnum('/room-manager/join/:roomId?', { denied: withDefault(BooleanParam, false) })
  static readonly ROOM_MANAGER__ATTENDANCE = new RouteEnum('/room-manager/attendance/:roomId/:userId?', {})
  static readonly ROOM_MANAGER__ATTENDANCE_NO_USER_ID = new RouteEnum('/room-manager/attendance/:roomId/', {})
  static readonly ROOM_MANAGER__ATTENDANCE_WITH_USER_ID = new RouteEnum('/room-manager/attendance/:roomId/:userId', {})
  static readonly ROOM_MANAGER__SETTINGS = new RouteEnum('/room-manager/settings/:roomId', {
    section: withDefault(customizeStringParam<RoomManagerSettingsSection>(), 'room'),
  })

  static readonly ERROR = new RouteEnum('/error', {})
  static readonly PRIVACY = new RouteEnum('/privacy', {})

  static readonly SANDBOX = new RouteEnum('/sandbox', {})
  static readonly SNAKE = new RouteEnum('/snake', {})
  static readonly NOT_SUPPORTED = new RouteEnum('/browsernotsupported', {});

  /**
   * Create encoded path with given query and/or url parameter values.
   *
   * Query and URL parameters are type-validated based on each route's config.
   *
   * If the path includes url parameters, such as ":roomId", the options arg
   * is required with `urlParams`. Otherwise, the options arg
   * can be omitted when you do not intend to pass `queryParams`.
   */
  encodePath(...[opts]: UrlParamOptionsRequired<Path> extends true ? [{
    queryParams?: Partial<DecodedValueMap<QPC>>,
    urlParams: ExtractRouteParams<Path>
  }]: [{ queryParams?: Partial<DecodedValueMap<QPC>>, urlParams?: never }] | []) {
    let encodedPath = generatePath(this.path, opts?.urlParams);

    if (opts?.queryParams) {
      // Implementation based on: https://www.npmjs.com/package/use-query-params#encodequeryparams
      encodedPath = `${encodedPath}?${stringify(encodeQueryParams(this.queryParamConfig, opts.queryParams))}`;
    }

    return encodedPath;
  }

  /** Wrapper around matchPath from react-router, using configured path for route. */
  matchPath(path: string, exact = true) {
    return matchPath(path, {
      path: this.path,
      exact,
    });
  }

  /** Wrapper around useQueryParams from use-query-params, using the route's query parameter config. */
  useQueryParams() {
    return useQueryParams(this.queryParamConfig);
  }

  // eslint-disable-next-line class-methods-use-this
  useURLParams() {
    return useParams<{ [key in keyof ExtractRouteParams<Path>]: string }>();
  }

  protected constructor(
    public readonly path: Path,
    public readonly queryParamConfig: QPC,
  ) {
    super();
  }
}
