/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { memo, ReactNode } from 'react';
import { modalFooterHeight } from 'styles/styles';
import AriaModal from 'react-aria-modal';
import { ModalUnderlayVariantsLong, ModalVariantsLong, ModalVariantsShort } from 'theme/ui/modal';
import { CSS } from 'types/css';
import { MODAL_TITLE_ID } from './ModalTitle';
import ClassNamePasser from '../ClassNamePasser';

const PAGE_HEIGHT = 'calc(100vh - 5rem)' as const;
const PAGE_WIDTH = 'calc(100vw - 5rem)' as const;

const widths = {
  ultraNarrow: `min(30rem, ${PAGE_WIDTH})`,
  extraNarrow: `min(40rem, ${PAGE_WIDTH})`,
  narrow: `min(52.4rem, ${PAGE_WIDTH})`,
  semiNarrow: `min(60.8rem, ${PAGE_WIDTH})`,
  normal: `min(73.4rem, ${PAGE_WIDTH})`,
  semiWide: `min(91rem, ${PAGE_WIDTH})`,
  wide: `min(108rem, ${PAGE_WIDTH})`,
} as const;

type Width = keyof typeof widths;

const heights = {
  short: `min(28rem, ${PAGE_HEIGHT})`,
  semiShort: `min(44rem, ${PAGE_HEIGHT})`,
  normal: `min(61.4rem, ${PAGE_HEIGHT})`,
  semiTall: `min(77.6rem, ${PAGE_HEIGHT})`,
  tall: `min(93.8rem, ${PAGE_HEIGHT})`,
} as const;

type Height = keyof typeof heights;

export type ModalProps = {
  toggle: (() => void) | null,
  isOpen: boolean,
  width?: Width,
  height?: Height,
  minContentHeight?: boolean,
  componentStyles?: CSS,
  children?: ReactNode,
  variant?: ModalVariantsShort,
  useDimmer?: boolean,
  addFooterSpacing?: boolean,
};

/**
 * Renders a Modal dialog.
 *
 * Notes:
 * - The ModalTitle sub-component is required, or else the developer must supply a "titleText" prop for accessibility purposes.
 * - The "toggle" is always required, but passing null will make the modal not closable by the user.
 * - By default, the first focusable child of the component will receive focus when mounted, but this can be customized with the initialFocus prop.
 */
function Modal({
  toggle,
  isOpen,
  minContentHeight = false,
  width = 'normal',
  height = 'normal',
  children = null,
  variant = 'light',
  useDimmer = true,
  addFooterSpacing = false,
  componentStyles = {},
}: ModalProps) {
  if (!isOpen) {
    return null;
  }

  const underlayStyles: CSS = {
    variant: `modalUnderlay.${variant}` as ModalUnderlayVariantsLong,
    cursor: toggle ? 'pointer' : 'default !important',
  };

  if (!useDimmer) {
    underlayStyles.backgroundColor = 'transparent';
  }

  const modalStyles: CSS = {
    variant: `modal.${variant}` as ModalVariantsLong,

    // width:
    maxWidth: widths[width],

    // height:
    minHeight: minContentHeight ? undefined : '50%',
    height: minContentHeight ? 'min-content' : '100%',
    maxHeight: heights[height],

    pb: addFooterSpacing ? modalFooterHeight : null,

    ...componentStyles,
  };

  /**
   * Allows the modal to apply "aria-hidden" to the rest of the app.
   */
  const getApplicationNode = () => document.getElementById('root') as HTMLDivElement;

  return (
    <ClassNamePasser sx={modalStyles}>
      {(dialogClass) => (
        <ClassNamePasser sx={underlayStyles}>
          {(underlayClass) => (
            <AriaModal
              onExit={toggle || (() => {})}
              underlayClass={underlayClass}
              dialogClass={dialogClass}
              getApplicationNode={getApplicationNode}
              includeDefaultStyles={false}
              titleId={MODAL_TITLE_ID}
            >
              {children}
            </AriaModal>
          )}
        </ClassNamePasser>
      )}
    </ClassNamePasser>
  );
}

export default memo(Modal);
