import {
  AppThunkAction, AllViewSessions, AllViewDetailActionType, AllViewDetailInfo, AttendanceState, UserViewSessions,
} from 'store/types';
import {
  GetAllViewAttendance, CCServiceAttendanceLinks, CCServiceAllViewAttendanceSession, CCServiceAttendanceRosterUser,
  CCServiceUserViewAttendanceSession, GetUserViewAttendance, GetMyAttendance,
} from 'utils/ccService/types';
import { MakeActionType, Overwrite } from 'utils/typeUtils';
import request from 'utils/request';
import { batch } from 'react-redux';
import logger from 'utils/logger';
import constrainNum from 'utils/constrainNum';
import {
  getAllViewCanFetchAttendanceCSVData,
  getAllViewCanGoToNextPage,
  getAllViewCanGoToPrevPage,
  getAllViewCanRecalibrateSessionId,
  getAllViewNumDataCols, getAllViewSessionIndex, getAllViewShowingTooFewCols, getUserViewCanFetchAttendanceCSVData, getUserViewDisplayName,
} from 'selectors';
import { sortByNameData } from 'utils/sortParticipants';
import { ALL_VIEW_ERROR_MAP, USER_VIEW_ERROR_MAP } from 'reducers/attendanceReducer';
import {
  dateToClockTime, dateToWeekdayString, msToDurationString,
} from 'utils/dateUtils';
// only the TYPE should be imported here since we dynamically import the library itself
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { unparse as Unparse } from '@types/papaparse';
import { downloadCSVData } from 'utils/download';
import { OwnerStatusEnum } from 'hooks/useIsOwner';
import { handleError, setAttendanceError } from './sharedActions';
import { resetErrorMessage } from './errorMessageActions';

export const reducerName = 'attendanceState' as const;

// all view actions
export const ALL_VIEW_SET_PREV_SESSIONS_LINK = `${reducerName}/ALL_VIEW_SET_PREV_SESSIONS_LINK` as const;
export const ALL_VIEW_SET_SESSIONS = `${reducerName}/ALL_VIEW_SET_SESSIONS` as const;
export const ALL_VIEW_TOGGLE_SESSIONS_LOADING = `${reducerName}/ALL_VIEW_TOGGLE_SESSIONS_LOADING` as const;
export const ALL_VIEW_SET_SESSIONS_ERROR = `${reducerName}/ALL_VIEW_SET_SESSIONS_ERROR` as const;
export const ALL_VIEW_SET_SESSION_ID = `${reducerName}/ALL_VIEW_SET_SESSION_ID` as const;
export const ALL_VIEW_SET_SESSIONS_FETCHED = `${reducerName}/ALL_VIEW_SET_SESSIONS_FETCHED` as const;
export const ALL_VEW_SET_USER_ID_LABELS = `${reducerName}/ALL_VEW_SET_USER_ID_LABELS` as const;
export const ALL_VIEW_SET_SESSION_ID_LABELS = `${reducerName}/ALL_VIEW_SET_SESSION_ID_LABELS` as const;
export const ALL_VIEW_SET_DETAIL_STATUS_HOVERED = `${reducerName}/ALL_VIEW_SET_DETAIL_STATUS_HOVERED` as const;
export const ALL_VIEW_SET_DETAIL_STATUS = `${reducerName}/ALL_VIEW_SET_DETAIL_STATUS` as const;
export const ALL_VIEW_SET_CSV_DATA_LOADING = `${reducerName}/ALL_VIEW_SET_CSV_DATA_LOADING` as const;

// user view actions
export const USER_VIEW_SET_SESSIONS = `${reducerName}/USER_VIEW_SET_SESSIONS` as const;
export const USER_VIEW_TOGGLE_SESSIONS_LOADING = `${reducerName}/USER_VIEW_TOGGLE_SESSIONS_LOADING` as const;
export const USER_VIEW_SET_SESSIONS_ERROR = `${reducerName}/USER_VIEW_SET_SESSIONS_ERROR` as const;
export const USER_VIEW_SET_SESSIONS_FETCHED = `${reducerName}/USER_VIEW_SET_SESSIONS_FETCHED` as const;
export const USER_VIEW_SET_SESSION_ID_LABELS = `${reducerName}/USER_VIEW_SET_SESSION_ID_LABELS` as const;
export const USER_VIEW_RESET_STATE = `${reducerName}/USER_VIEW_RESET_STATE` as const;
export const USER_VIEW_SET_CSV_DATA_LOADING = `${reducerName}/USER_VIEW_SET_CSV_DATA_LOADING` as const;
export const USER_VIEW_SET_NAME_DATA = `${reducerName}/USER_VIEW_SET_NAME_DATA` as const;

export const RESET_ATTENDANCE_STATE = `${reducerName}/RESET_ATTENDANCE_STATE` as const;

/** CC-service thinks of sessions that occurred earlier as being "next" */
export const allViewSetPrevSessionsLink = (allViewPrevSessionsLink: CCServiceAttendanceLinks['next']) => ({
  type: ALL_VIEW_SET_PREV_SESSIONS_LINK,
  payload: { allViewPrevSessionsLink },
});

/** Stores CC Service attendance sessions in state */
export const allViewSetSessions = (sessions: AllViewSessions) => ({
  type: ALL_VIEW_SET_SESSIONS,
  payload: { sessions },
});

export const allViewToggleSessionsLoading = (loading?: boolean) => ({
  type: ALL_VIEW_TOGGLE_SESSIONS_LOADING,
  payload: { loading },
});

export const allViewSetSessionsError = (error: string) => ({
  type: ALL_VIEW_SET_SESSIONS_ERROR,
  payload: { error },
});

/** The leftmost sessionId is the id of the session on the leftmost side of the grid */
export const allViewSetSessionId = (sessionId: string) => ({
  type: ALL_VIEW_SET_SESSION_ID,
  payload: { sessionId },
});

export const allViewSetSessionsFetched = (fetched: boolean) => ({
  type: ALL_VIEW_SET_SESSIONS_FETCHED,
  payload: { fetched },
});

export const allViewSetUserIdLabels = (userIds: string[]) => ({
  type: ALL_VEW_SET_USER_ID_LABELS,
  payload: { userIds },
});

export const allViewSetSessionIdLabels = (sessionIds: string[]) => ({
  type: ALL_VIEW_SET_SESSION_ID_LABELS,
  payload: { sessionIds },
});

export const allViewSetCSVDataLoading = (loading: AttendanceState['allViewCSVDataLoading']) => ({
  type: ALL_VIEW_SET_CSV_DATA_LOADING,
  payload: { loading },
});

interface AllViewSetDetailStatusReturnType {
  type: typeof ALL_VIEW_SET_DETAIL_STATUS,
  payload: {
    detailInfo: AllViewDetailInfo | null,
    active: boolean,
    actionType: AllViewDetailActionType,
  }
}

export function allViewSetDetailStatus(
  actionType: AllViewDetailActionType, active: false, detailInfo?: null,
): AllViewSetDetailStatusReturnType;
export function allViewSetDetailStatus(
  actionType: AllViewDetailActionType, active: true, detailInfo: AllViewDetailInfo,
): AllViewSetDetailStatusReturnType;
export function allViewSetDetailStatus(
  actionType: AllViewDetailActionType, active: boolean, detailInfo: AllViewDetailInfo | null = null,
): AllViewSetDetailStatusReturnType {
  return ({
    type: ALL_VIEW_SET_DETAIL_STATUS,
    payload: { detailInfo, active, actionType },
  });
}


export const userViewSetSessions = (sessions: UserViewSessions) => ({
  type: USER_VIEW_SET_SESSIONS,
  payload: { sessions },
});

export const userViewToggleSessionsLoading = (loading?: boolean) => ({
  type: USER_VIEW_TOGGLE_SESSIONS_LOADING,
  payload: { loading },
});

export const userViewSetSessionsError = (error: string) => ({
  type: USER_VIEW_SET_SESSIONS_ERROR,
  payload: { error },
});

export const userViewSetSessionsFetched = (fetched: boolean) => ({
  type: USER_VIEW_SET_SESSIONS_FETCHED,
  payload: { fetched },
});

export const userViewSetSessionIdLabels = (labels: string[]) => ({
  type: USER_VIEW_SET_SESSION_ID_LABELS,
  payload: { labels },
});

export const userViewResetState = () => ({
  type: USER_VIEW_RESET_STATE,
});

export const userViewSetCSVDataLoading = (loading: boolean) => ({
  type: USER_VIEW_SET_CSV_DATA_LOADING,
  payload: { loading },
});

export const userViewSetNameData = ({ displayName, firstName, lastName }: {
  displayName: string
  firstName: string,
  lastName: string,
}) => ({
  type: USER_VIEW_SET_NAME_DATA,
  payload: { displayName, firstName, lastName },
});


export const _resetAttendanceState = () => ({
  type: RESET_ATTENDANCE_STATE,
});

export type AttendanceAction = MakeActionType<[
  typeof allViewSetPrevSessionsLink,
  typeof allViewSetSessions,
  typeof allViewToggleSessionsLoading,
  typeof allViewSetSessionsError,
  typeof allViewSetSessionId,
  typeof allViewSetSessionsFetched,
  typeof allViewSetUserIdLabels,
  typeof allViewSetSessionIdLabels,
  typeof allViewSetDetailStatus,
  typeof allViewSetCSVDataLoading,

  typeof userViewSetSessions,
  typeof userViewToggleSessionsLoading,
  typeof userViewSetSessionsError,
  typeof userViewSetSessionsFetched,
  typeof userViewSetSessionIdLabels,
  typeof userViewResetState,
  typeof userViewSetCSVDataLoading,
  typeof userViewSetNameData,

  typeof _resetAttendanceState,
]>;

export const resetAttendanceState = (): AppThunkAction => (dispatch) => {
  batch(() => {
    dispatch(_resetAttendanceState());
    dispatch(resetErrorMessage('attendance'));
  });
};


/** Format array of sessions as a hashmap of sessionIds -> sessions */
export const formatSessionsAsMap = <T extends { sessionId: string, startTime: string, startTimeDate?: never }>(
  fetchedSessions: readonly T[], prevSessionMap: { [sessionId: string]: T } = {},
) => {
  type FormattedSession = Overwrite<T, { startTimeDate: Date }>;
  type SessionMap = { [sessionId: string]: FormattedSession };
  return (
    fetchedSessions.reduce((map, session) => {
      const formattedSession: FormattedSession = { ...session, startTimeDate: new Date(session.startTime) };
      if (!(session.sessionId in prevSessionMap)) map[formattedSession.sessionId] = formattedSession;
      return map;
    }, {} as SessionMap)
  );
};

const sortSessionIdsByStartTime = <T extends { startTimeDate: Date }>(
  sessionIdSet: Set<string>, newUserViewSessionMap: { [sessionId: string]: T },
) => Array.from(sessionIdSet).sort(
    (a, b) => newUserViewSessionMap[a].startTimeDate.valueOf() - newUserViewSessionMap[b].startTimeDate.valueOf(),
  );

const allViewValidateAttendanceSession = (session: CCServiceAllViewAttendanceSession) => {
  const errors = [];
  const warnings = [];
  // errors
  if (!session.sessionId) errors.push('Session does not have a sessionId');
  if (!session.startTime) errors.push('Session does not have a startTime');
  if (!session.roster) warnings.push('Session does not have a roster');

  // warnings
  if (!session.endTime) warnings.push('Session does not have an endTime');
  return { errors, warnings };
};

const allViewValidateAttendanceUser = (user: CCServiceAttendanceRosterUser, { isFirstFetch = false }: { isFirstFetch: boolean }) => {
  const errors = [];
  const warnings = [];

  // errors
  if (!user.attendanceStatus) errors.push('User does not have an attendanceStatus');
  if (typeof user.totalTime !== 'number') errors.push('User\'s totalTime is not a number');
  if (!user.firstName && !user.lastName && !user.displayName) errors.push('User has no first, last, or displayName and so can\'t be displayed');

  // warnings
  if (!user.displayName) warnings.push('User does not have a displayName');
  if (!user.firstName) warnings.push('User does not have a firstName');
  if (!user.lastName) warnings.push('User does not have a lastName');
  if (!isFirstFetch) warnings.push('User was not found in previous fetches to attendance. This indicates a non-uniform roster.');
  if (!user.timesInRoom) warnings.push('User does not have a timesInRoom array');
  if (user.attendanceStatus === 'present' && (!user.joinTime || !user.leaveTime)) {
    warnings.push('User is marked present but missing a joinTime or leaveTime');
  }
  if (user.attendanceStatus === 'present' && !user.timesInRoom.length) {
    warnings.push('User is marked present but has no timesInRoom');
  }
  if (user.joinTime && user.leaveTime && typeof user.totalTime === 'number') {
    const maxMs = new Date(user.leaveTime).valueOf() - new Date(user.joinTime).valueOf();
    if (user.totalTime > maxMs) warnings.push('User\'s totalTime exceeds maximum possible ms, given their join and leave time');
  }
  return { errors, warnings };
};

/** Formats fetched sessions and saves to state */
export const allViewAddSessions = (
  fetchedSessions: readonly CCServiceAllViewAttendanceSession[], isFirstFetch = false,
): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const {
    allViewSessions: prevSessionMap, allViewUserIdLabels: prevDisplayNameLabels, allViewSessionIdLabels: prevSortedSessionIds,
  } = state.attendanceState;
  const numDataCols = getAllViewNumDataCols(state);

  const newAllViewSessionMap = { ...prevSessionMap, ...formatSessionsAsMap(fetchedSessions, prevSessionMap) };

  // do not duplicate any userIds in the userId array
  const userIdSet = new Set(prevDisplayNameLabels);
  const sessionIdSet = new Set(prevSortedSessionIds);

  /* add in new sessionIds (expected) and any new roster users received from CC-service (error) */
  Object.entries(newAllViewSessionMap).forEach(([sessionId, session]) => {
    // add session ids
    if (!sessionIdSet.has(sessionId)) {
      const { errors: sessionDataErrors, warnings: sessionDataWarnings } = allViewValidateAttendanceSession(session);

      // log both warnings and errors, but only omit if errors occurred
      if ((sessionDataErrors.length || sessionDataWarnings.length) && !ALL_VIEW_ERROR_MAP.has(sessionId)) {
        ALL_VIEW_ERROR_MAP.set(sessionId, true);
        logger.warn('Malformed attendance session data received from CC-service', {
          sessionDataErrors,
          sessionDataWarnings,
          sessionId,
          startTime: session.startTime,
          endTime: session.endTime,
        });
      }

      if (!sessionDataErrors.length) sessionIdSet.add(sessionId);
    }

    // add user ids
    Object.entries(session.roster).forEach(([userId, user]) => {
      if (!userIdSet.has(userId)) {
        const { errors, warnings } = allViewValidateAttendanceUser(user, { isFirstFetch });

        // log both warnings and errors, but only omit if errors occurred
        if ((errors.length || warnings.length) && !ALL_VIEW_ERROR_MAP.has(userId)) {
          ALL_VIEW_ERROR_MAP.set(userId, true);
          logger.warn('Malformed attendance data received from CC-service', {
            errors,
            warnings,
            userId,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userDisplayName: user.displayName,
            sessionId,
            displayName: user?.displayName,
            attendanceStatus: user?.attendanceStatus,
            joinTime: user.joinTime,
            leaveTime: user.leaveTime,
            totalTime: user.totalTime,
          });
        }

        if (!errors.length) userIdSet.add(userId);
      }
    });
  });

  // sort userIds by their corresponding name data (first, last, and displayName)
  const newAllViewSessionMapValues = Object.values(newAllViewSessionMap);
  const userIdsWithNameData = Array.from(userIdSet).map((userId) => ({
    displayName: newAllViewSessionMapValues[0]?.roster[userId]?.displayName || '',
    firstName: newAllViewSessionMapValues[0]?.roster[userId]?.firstName || '',
    lastName: newAllViewSessionMapValues[0]?.roster[userId]?.lastName || '',
    userId,
  }));

  const newDisplayNameLabels = sortByNameData(userIdsWithNameData, { singleNamesLast: true }).map(({ userId }) => userId);
  const newSessionStartTimeLabels = sortSessionIdsByStartTime(sessionIdSet, newAllViewSessionMap);

  batch(() => {
    const initialSessionId = newSessionStartTimeLabels[Math.max(0, newSessionStartTimeLabels.length - numDataCols)];
    if (isFirstFetch && initialSessionId) dispatch(allViewSetSessionId(initialSessionId));
    dispatch(allViewSetSessionIdLabels(newSessionStartTimeLabels));
    dispatch(allViewSetUserIdLabels(newDisplayNameLabels));
    dispatch(allViewSetSessions(newAllViewSessionMap));
  });
};

/** Moves user back by 1 page. If more need sessions to be loaded, they are loaded and then the view is updated */
export const allViewGoToPrevPage = (roomId: string): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { attendanceState, attendanceState: { allViewPrevSessionsLink, allViewSessionsFetched } } = state;
  let { allViewSessionId: leftmostSessionId, allViewSessionIdLabels: allViewSortedSessionTimeLabels } = attendanceState;
  const cols = getAllViewNumDataCols(state);
  let leftmostSessionIndex = getAllViewSessionIndex(state);
  const canGoToPrevPage = getAllViewCanGoToPrevPage(state);

  if (!roomId) {
    logger.warn("Can't go to previous page of attendance sessions, because roomId is not defined", { roomId });
    return;
  }

  if (!canGoToPrevPage) {
    logger.warn('Invalid state to go to previous page of attendance sessions', {
      canGoToPrevPage, allViewPrevSessionsLink, allViewSessionsFetched, leftmostSessionId, sessionsLength: allViewSortedSessionTimeLabels.length,
    });
    return;
  }

  // incrementing left would extend past what is stored in memory
  if (leftmostSessionIndex - cols < 0) {
    // fetch more sessions
    if (allViewPrevSessionsLink) {
      await dispatch(allViewFetchPrevSessions(roomId));
      const updatedState = getState();
      const updatedAttendanceState = updatedState.attendanceState;
      leftmostSessionId = updatedAttendanceState.allViewSessionId;
      allViewSortedSessionTimeLabels = updatedAttendanceState.allViewSessionIdLabels;
      leftmostSessionIndex = getAllViewSessionIndex(updatedState);
    } else if (leftmostSessionIndex === 0) {
      // no more sessions left to fetch & no more prev columns to show
      logger.warn(
        "Can't go to previous page of attendance sessions, because there are no more previous columns to show and no more sessions left to fetch",
        { leftmostSessionId },
      );
      return;
    }
  }

  const newLeftmostIndex = Math.max(0, leftmostSessionIndex - cols);
  const newLeftmostSessionId = allViewSortedSessionTimeLabels[newLeftmostIndex];

  if (!newLeftmostSessionId) {
    logger.warn("Can't view previous sessions, because there was an error finding the next leftmostSessionId");
    return;
  }

  dispatch(allViewSetSessionId(newLeftmostSessionId));
};

/** Moves user forward 1 page. Sessions never need to be fetched for this action, since the user always starts at the most recent session */
export const allViewGoToNextPage = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { attendanceState, attendanceState: { allViewSessionsFetched, allViewPrevSessionsLink } } = state;
  const { allViewSessionId: leftmostSessionId, allViewSessionIdLabels: allViewSortedSessionTimeLabels } = attendanceState;
  const cols = getAllViewNumDataCols(state);
  const leftmostSessionIndex = getAllViewSessionIndex(state);
  const canGoToNextPage = getAllViewCanGoToNextPage(state);

  if (!canGoToNextPage) {
    logger.warn('Invalid state to go to next page of attendance sessions', {
      canGoToNextPage, allViewPrevSessionsLink, allViewSessionsFetched, leftmostSessionId, sessionsLength: allViewSortedSessionTimeLabels.length,
    });
    return;
  }

  const newLeftmostIndex = constrainNum(leftmostSessionIndex + cols, 0, allViewSortedSessionTimeLabels.length - cols);
  const newLeftmostSessionId = allViewSortedSessionTimeLabels[newLeftmostIndex];

  if (!newLeftmostSessionId) {
    logger.warn("Can't go to next page of attendance sessions, because there was an error finding the next leftmostSessionId");
    return;
  }

  dispatch(allViewSetSessionId(newLeftmostSessionId));
};

export const NUM_SESSIONS_TO_FETCH = 10;

/** Retrieves the previous page of attendance sessions from CC-service */
export const allViewFetchPrevSessions = (roomId: string): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { allViewPrevSessionsLink, allViewSessionsFetched, allViewSessionsLoading } = state.attendanceState;

  if (!roomId) {
    logger.warn("Can't fetch attendance because roomId is not defined", { roomId });
    return;
  }

  if (allViewSessionsLoading) {
    logger.warn("Can't fetch attendance because another request is still loading", { roomId });
    return;
  }

  if (!allViewPrevSessionsLink && allViewSessionsFetched) {
    logger.warn("Can't fetch any more attendance reports, because link to previous 5 sessions is not defined", { allViewPrevSessionsLink });
    return;
  }

  batch(() => {
    dispatch(allViewToggleSessionsLoading(true));
    dispatch(allViewSetSessionsError(''));
    dispatch(resetErrorMessage('attendance'));
  });

  try {
    const URL_FOR_FIRST_FETCH = `rooms/${roomId}/attendance?limit=${NUM_SESSIONS_TO_FETCH}&offset=0`;
    const { data, data: { _links: links } } = await request<GetAllViewAttendance>({
      method: 'GET',
      url: allViewPrevSessionsLink || URL_FOR_FIRST_FETCH,
    });
    let { attendanceReport } = data;

    /** Sort from oldest to newest (reverse order that CC-service sends) */
    attendanceReport = attendanceReport.filter((a) => a.startTime)
      .sort((a, b) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf());

    batch(() => {
      dispatch(allViewToggleSessionsLoading(false));
      dispatch(allViewSetSessionsFetched(true));
      dispatch(allViewAddSessions(attendanceReport, !allViewSessionsFetched));
      dispatch(allViewSetPrevSessionsLink(links?.next));
    });
  } catch (error) {
    const ERROR_MESSAGE = 'Failed to retrieve attendance data';
    batch(() => {
      dispatch(setAttendanceError(ERROR_MESSAGE));
      dispatch(handleError(ERROR_MESSAGE, { error }));
      dispatch(allViewSetSessionsError(ERROR_MESSAGE));
      dispatch(allViewSetSessionsFetched(true));
      dispatch(allViewToggleSessionsLoading(false));
    });
  }
};

/** Recalibrates sessionId when the current user is "stuck" in a 2-column view (or other too small view) at the end of the sessions array */
export const allViewRecalibrateSessionId = (): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const showingTooFewCols = getAllViewShowingTooFewCols(state);
  const { allViewSessionsFetched, allViewSessionsLoading, allViewSessionIdLabels: allViewSortedSessionTimeLabels } = state.attendanceState;

  if (!getAllViewCanRecalibrateSessionId(state)) {
    logger.warn('Invalid state to recalibrate leftmost sessionId',
      {
        allViewSessionsFetched,
        allViewSessionsLoading,
        allViewSessionsLength: allViewSortedSessionTimeLabels.length,
        showingTooFewCols,
      });
    return;
  }

  const cols = getAllViewNumDataCols(state);
  const newSessionIndex = Math.max(0, allViewSortedSessionTimeLabels.length - cols);
  const newSessionId = allViewSortedSessionTimeLabels[newSessionIndex];

  if (!newSessionId) {
    logger.warn('Error finding new sessionId in allViewRecalibrateSessionId');
    return;
  }

  dispatch(allViewSetSessionId(newSessionId));
};

export const ALL_VIEW_CSV_HEADINGS = [
  'Date', 'Day of Week', 'First Name', 'Last Name', 'Display Name', 'Join Time', 'Leave Time', 'Total Time', 'Attended',
];
const NO_DATA = '-';
const INDEX_0_OFFSET = 1;

export const allViewFormatAttendanceAsCSV = (attendanceReport: readonly CCServiceAllViewAttendanceSession[], unparse: typeof Unparse) => {
  const csvData: string[][] = [ALL_VIEW_CSV_HEADINGS];

  // discard any sessions with errors
  // sessions are already sorted by start time
  const filteredSessions = attendanceReport.filter((session) => {
    const { errors } = allViewValidateAttendanceSession(session);
    return errors.length === 0;
  });

  filteredSessions.forEach((session) => {
    const sessionStartDate = new Date(session.startTime);
    const date = `${sessionStartDate.getMonth() + INDEX_0_OFFSET}/${sessionStartDate.getDate()}`;
    const dayOfWeek = dateToWeekdayString(sessionStartDate);

    // discard any users with errors -- users must have a display name to be included
    type FilteredAttendanceUser = CCServiceAttendanceRosterUser & { displayName: Exclude<CCServiceAttendanceRosterUser['displayName'], undefined> };
    const filteredRoster = Object.values(session.roster).filter((user) => (
      allViewValidateAttendanceUser(user, { isFirstFetch: true }).errors.length === 0
    )) as FilteredAttendanceUser[];

    const sortedRoster = sortByNameData(filteredRoster);
    sortedRoster.forEach((user) => {
      const joinTime = user.joinTime ? dateToClockTime(user.joinTime) : NO_DATA;
      const leaveTime = user.leaveTime ? dateToClockTime(user.leaveTime) : NO_DATA;
      const totalTime = msToDurationString(user.totalTime);
      const attended = (() => {
        switch (user.attendanceStatus) {
          case 'present':
            return 'Yes';
          case 'absent':
            return 'No';
          default:
            return NO_DATA;
        }
      })();

      if (user.attendanceStatus === 'present') {
        csvData.push([
          date, dayOfWeek, user.firstName, user.lastName, user.displayName, joinTime, leaveTime, totalTime, attended,
        ]);
      } else {
        csvData.push([
          date, dayOfWeek, user.firstName, user.lastName, user.displayName, NO_DATA, NO_DATA, NO_DATA, attended,
        ]);
      }
    });

    // add line break between sessions
    csvData.push(['\n']);
  });

  const csvString = unparse(csvData);
  logger.debug('Formatted attendance CSV string', { csvString });

  return unparse(csvData);
};

/** Date that is arbitrarily and sufficiently long ago */
const UNIX_EPOCH = new Date(0).toUTCString();

export const allViewFetchAndDownloadAttendanceCSV = (roomId: string): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { attendanceState: { allViewCSVDataLoading } } = state;
  const canFetch = getAllViewCanFetchAttendanceCSVData(state);

  if (!canFetch) logger.warn('Invalid state to fetch attendance CSV data', { allViewCSVDataLoading });

  try {
    batch(() => {
      dispatch(resetErrorMessage('attendance'));
      dispatch(allViewSetCSVDataLoading(true));
    });

    // fetch JSON-to-csv parser and attendance data at the same time
    const [{ unparse }, { data: { attendanceReport: _attendanceReport, _links: links } }] = await Promise.all([
      import('papaparse'),
      request<GetAllViewAttendance>({ method: 'GET', url: `rooms/${roomId}/attendance?startDate=${UNIX_EPOCH}` }),
    ]);

    // prevent mutating data while formatting, converting to CSV, saving to state, etc.
    const attendanceReport = Object.freeze(_attendanceReport);

    const formattedCSVData = allViewFormatAttendanceAsCSV(attendanceReport, unparse);
    const fileName = 'Attendance Report for All Users.csv';
    downloadCSVData(fileName, formattedCSVData);

    batch(() => {
      dispatch(allViewSetCSVDataLoading(false));

      // fill out the rest of attendance state using this data
      dispatch(allViewAddSessions(attendanceReport, false));
      dispatch(allViewSetPrevSessionsLink(links?.next));
    });
  } catch (error) {
    const ERROR_MESSAGE = 'Failed to retrieve attendance data';
    batch(() => {
      dispatch(setAttendanceError(ERROR_MESSAGE));
      dispatch(handleError(ERROR_MESSAGE, { error }));
      dispatch(allViewSetCSVDataLoading(false));
    });
  }
};

/** Checks for any malformed data in response from CC-service */
const userViewValidateAttendanceSession = (session: CCServiceUserViewAttendanceSession) => {
  const errors = [];
  const warnings = [];

  // SESSION DATA:
  // errors
  if (!session.sessionId) errors.push('Session does not have a sessionId');
  if (!session.startTime) errors.push('Session does not have a startTime');

  // warnings
  if (!session.endTime) warnings.push('Session does not have an endTime');

  // USER ATTENDANCE DATA:
  // errors
  if (!session.attendanceStatus) errors.push('User does not have an attendanceStatus');
  if (typeof session.totalTime !== 'number') errors.push('User\'s totalTime is not a number');
  if (session.attendanceStatus === 'present' && (!session.joinTime || !session.leaveTime)) {
    errors.push('User is marked present but missing a joinTime or leaveTime');
  }

  // warnings
  if (session.joinTime && session.leaveTime && typeof session.totalTime === 'number') {
    const maxMs = new Date(session.leaveTime).valueOf() - new Date(session.joinTime).valueOf();
    if (session.totalTime > maxMs) warnings.push('User\'s totalTime exceeds maximum possible ms, given their join and leave time');
  }
  return { errors, warnings };
};

/** Formats fetched sessions and saves to state */
export const userViewAddSessions = (
  report: GetUserViewAttendance,
): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const {
    userViewSessions: prevSessionMap, userViewSessionIdLabels: prevSortedSessionIds,
  } = state.attendanceState;

  const newUserViewSessionMap = { ...prevSessionMap, ...formatSessionsAsMap(report.sessions, prevSessionMap) };

  // do not duplicate any sessionIds in the sessionId array
  const sessionIdSet = new Set(prevSortedSessionIds);

  /* add in any new sessionIds from CC-service */
  Object.entries(newUserViewSessionMap).forEach(([sessionId, session]) => {
    // add session ids
    if (!sessionIdSet.has(sessionId)) {
      const { errors, warnings } = userViewValidateAttendanceSession(session);

      // log both warnings and errors, but only omit if errors occurred
      if ((errors.length || warnings.length) && !USER_VIEW_ERROR_MAP.has(sessionId)) {
        USER_VIEW_ERROR_MAP.set(sessionId, true);
        logger.warn('Malformed attendance session data received from CC-service', {
          errors,
          warnings,
          sessionId,
          startTime: session.startTime,
          endTime: session.endTime,
          userId: report.userId,
          firstName: report.firstName,
          lastName: report.lastName,
          displayName: report.displayName,
        });
      }

      if (!errors.length) sessionIdSet.add(sessionId);
    }
  });

  const newSessionStartTimeLabels = sortSessionIdsByStartTime(sessionIdSet, newUserViewSessionMap);

  batch(() => {
    dispatch(userViewSetSessionIdLabels(newSessionStartTimeLabels));
    dispatch(userViewSetSessions(newUserViewSessionMap));
  });
};

export const userViewFetchSessions = (roomId: string, userId: string, ownerStatus: OwnerStatusEnum): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { userViewSessionsLoading } = state.attendanceState;
  const currentUserId = state.loginState.user?.userId;

  if (!roomId) {
    logger.warn("Can't fetch attendance because roomId is not defined", { roomId });
    return;
  }

  if (!userId) {
    logger.warn("Can't fetch attendance because userId is not defined", {
      fetchingAttendanceForUserId: userId,
      currentUserId,
    });
    return;
  }

  if (userViewSessionsLoading) {
    logger.warn("Can't fetch user attendance because another request is still loading", {
      userViewSessionsLoading,
      roomId,
      fetchingAttendanceForUserId: userId,
      currentUserId,
    });
    return;
  }

  if (ownerStatus === OwnerStatusEnum.UNKNOWN) {
    logger.warn("Can't fetch user attendance because ownerStatus is unknown", {
      roomId,
      ownerStatus,
      fetchingAttendanceForUserId: userId,
      currentUserId,
    });
    return;
  }

  batch(() => {
    dispatch(userViewResetState());
    dispatch(userViewToggleSessionsLoading(true));
    dispatch(userViewSetSessionsError(''));
    dispatch(userViewSetSessionsFetched(false));
    dispatch(resetErrorMessage('attendance'));
  });

  try {
    const { data } = ownerStatus === OwnerStatusEnum.NOT_OWNER
      // when a registered guest user is fetching their own attendance:
      ? await request<GetMyAttendance>({ method: 'GET', url: `/rooms/${roomId}/my-attendance` })
      // when the room owner is fetching the individual view of a guest's attendance:
      : await request<GetUserViewAttendance>({ method: 'GET', url: `/rooms/${roomId}/individual-attendance?userId=${userId}` });

    if (!data.displayName && !data.firstName && !data.lastName) {
      logger.error('User attendance data does not contain a first, last, or displayName and will not be properly displayed', {
        userId,
        roomId,
        firstName: data.firstName,
        lastName: data.lastName,
        displayName: data.displayName,
      });
    }

    batch(() => {
      dispatch(userViewToggleSessionsLoading(false));
      dispatch(userViewSetSessionsFetched(true));
      dispatch(userViewSetNameData({
        displayName: data.displayName,
        firstName: data.firstName,
        lastName: data.lastName,
      }));
      dispatch(userViewAddSessions(Object.freeze(data)));
    });
  } catch (error) {
    const ERROR_MESSAGE = 'Failed to retrieve attendance data';
    batch(() => {
      dispatch(setAttendanceError(ERROR_MESSAGE));
      dispatch(handleError(ERROR_MESSAGE, { error }));
      dispatch(userViewSetSessionsError(ERROR_MESSAGE));
      dispatch(userViewSetSessionsFetched(true));
      dispatch(userViewToggleSessionsLoading(false));
    });
  }
};

export const USER_VIEW_CSV_HEADINGS = [
  'Date', 'Day of Week', 'First Name', 'Last Name', 'Display Name', 'Join Time', 'Leave Time', 'Total Time', 'Attended',
];

export const userViewFormatAttendanceAsCSV = (attendance: Readonly<GetUserViewAttendance>, unparse: typeof Unparse) => {
  const csvData: string[][] = [ALL_VIEW_CSV_HEADINGS];

  // discard any sessions with errors
  // sessions are already sorted by start time
  const filteredSessions = attendance.sessions.filter((session) => {
    const { errors } = userViewValidateAttendanceSession(session);
    return errors.length === 0;
  });

  filteredSessions.forEach((session) => {
    const sessionStartDate = new Date(session.startTime);
    const date = `${sessionStartDate.getMonth() + INDEX_0_OFFSET}/${sessionStartDate.getDate()}`;
    const dayOfWeek = dateToWeekdayString(sessionStartDate);

    const joinTime = session.joinTime ? dateToClockTime(session.joinTime) : NO_DATA;
    const leaveTime = session.leaveTime ? dateToClockTime(session.leaveTime) : NO_DATA;
    const totalTime = msToDurationString(session.totalTime);
    const attended = (() => {
      switch (session.attendanceStatus) {
        case 'present':
          return 'Yes';
        case 'absent':
          return 'No';
        default:
          return NO_DATA;
      }
    })();

    if (session.attendanceStatus === 'present') {
      csvData.push([
        date, dayOfWeek, attendance.firstName, attendance.lastName, attendance.displayName, joinTime, leaveTime, totalTime, attended,
      ]);
    } else {
      csvData.push([
        date, dayOfWeek, attendance.firstName, attendance.lastName, attendance.displayName, NO_DATA, NO_DATA, NO_DATA, attended,
      ]);
    }
  });

  const csvString = unparse(csvData);
  logger.debug('Formatted attendance CSV string', { csvString });

  return unparse(csvData);
};

export const userViewFetchAndDownloadAttendanceCSV = (
  roomId: string, userId: string, ownerStatus: OwnerStatusEnum,
): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { attendanceState: { userViewCSVDataLoading } } = state;
  const canFetch = getUserViewCanFetchAttendanceCSVData(state);
  const displayName = getUserViewDisplayName(state);
  const currentUserId = state.loginState.user?.userId;

  if (!roomId) {
    logger.warn("Can't fetch attendance because roomId is not defined", { roomId });
    return;
  }

  if (!userId) {
    logger.warn("Can't fetch attendance because userId is not defined", { userId });
    return;
  }

  if (ownerStatus === OwnerStatusEnum.UNKNOWN) {
    logger.warn("Can't fetch user attendance because ownerStatus is unknown", {
      roomId,
      ownerStatus,
      fetchingAttendanceForUserId: userId,
      currentUserId,
    });
    return;
  }

  if (!canFetch) logger.warn('Invalid state to fetch attendance CSV data', { userViewCSVDataLoading });

  try {
    batch(() => {
      dispatch(resetErrorMessage('attendance'));
      dispatch(userViewSetCSVDataLoading(true));
    });

    const fetchAttendanceData = ownerStatus === OwnerStatusEnum.NOT_OWNER
      // when a registered guest user is fetching their own attendance:
      ? request<GetMyAttendance>({ method: 'GET', url: `/rooms/${roomId}/my-attendance` })
      // when the room owner is fetching the individual view of a guest's attendance:
      : request<GetUserViewAttendance>({ method: 'GET', url: `/rooms/${roomId}/individual-attendance?userId=${userId}` });

    // fetch JSON-to-csv parser and attendance data at the same time
    const [{ unparse }, { data }] = await Promise.all([
      import('papaparse'),
      fetchAttendanceData,
    ]);

    // prevent mutating data while formatting, converting to CSV, saving to state, etc.
    const attendanceReport = Object.freeze(data);

    const formattedCSVData = userViewFormatAttendanceAsCSV(attendanceReport, unparse);
    const fileName = `Attendance Report for ${displayName}.csv`;
    downloadCSVData(fileName, formattedCSVData);

    batch(() => {
      dispatch(userViewSetCSVDataLoading(false));
      dispatch(userViewAddSessions(attendanceReport));
    });
  } catch (error) {
    const ERROR_MESSAGE = 'Failed to retrieve attendance data';
    batch(() => {
      dispatch(handleError(ERROR_MESSAGE, { error }));
      dispatch(setAttendanceError(ERROR_MESSAGE));
      dispatch(userViewSetCSVDataLoading(false));
    });
  }
};
