/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import Tooltip, { TOOLTIP_TRIANGLE_2_CLASS } from 'components/Tooltip/Tooltip';
import {
  ComponentProps, Fragment, ReactNode, memo,
} from 'react';
import { useSelector } from 'react-redux';
import { getAllViewSessions } from 'selectors';
import { textEllipsis } from 'styles/styles';
import {
  ColorsEnum, RED_TRUE, TEAL_MEDIUM, TEAL_TRUE, WHITE,
} from 'theme/ui/colors';
import { CSS } from 'types/css';
import {
  dateToClockTime, dateToMonthString, dateToNumericDateString, msToDurationString,
} from 'utils/dateUtils';
import { CCServiceAttendanceRosterUser, CCServiceAllViewAttendanceSession } from 'utils/ccService/types';
import { formatLastnameFirstname } from 'utils/stringUtils';

const tooltipStyles: CSS = {
  // main tooltip styles
  minHeight: '20rem',
  minWidth: '28rem',
  width: 'max-content',
  boxShadow: `0px 4px 16px ${ColorsEnum.GREY_40.withOpacity(0.6)}`,
  bottom: 'calc(100% + 3rem)',
  p: '1.2rem 4.8rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  boxSizing: 'border-box',
  cursor: 'auto',

  // makes the main triangle indicator larger
  '&:after': {
    marginLeft: 0,
    borderWidth: '2.4rem',
    top: '100%',
    left: '50%',
    borderColor: `${WHITE} transparent transparent transparent`,
    transform: 'translate(-50%, -1%)',
  },

  // adds shadow behind tooltip's triangle
  [`& .${TOOLTIP_TRIANGLE_2_CLASS}`]: {
    pointerEvents: 'none',
    position: 'absolute',
    top: '100%',
    left: '50%',
    height: 0,
    width: 0,

    // applies filter around triangular :after element
    filter: `drop-shadow(0px 0px 12px ${ColorsEnum.BLACK.withOpacity(0.4)})`,

    '&:after': {
      content: '" "',
      position: 'absolute',
      /* Diagonal length of the main tooltip triangle square element:
      Pythagorean theorem: Math.sqrt(2 * (2.4rem ^ 2)) */
      width: '3.3rem',
      height: '3.3rem',
      top: '100%',
      left: '50%',
      transformOrigin: 'top left',
      clipPath: 'polygon(0 0, 100% 100%, 0 100%)',
      transform: 'translate3d(-37%, -1%, -1px) rotate(-45deg)',
      bg: WHITE,
    },
  },
};

const displayNameStyles: CSS = {
  variant: 'text.body_semibold',
  display: 'block',
  height: '100%',
  maxWidth: '100%',
  ...textEllipsis,
};

const dateStyles: CSS = {
  variant: 'text.body',
  mb: '1.2rem',
};

const attendanceStatusContainerStyles: CSS = {
  height: '100%',
  width: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1.2rem',
  flex: 1,
};

const timeLabelContainerStyles: CSS = {
  position: 'relative',
  height: '2.8rem',
  minWidth: '100%',
  width: 'max-content',
  display: 'grid',
  gridTemplateColumns: '1fr 1px 1fr',
  gridTemplateRows: 'auto',
  borderRadius: '0.4rem',
  justifyContent: 'center',
  alignItems: 'center',
  bg: TEAL_TRUE,
};

const timeLabelInnerItemStyles: CSS = {
  variant: 'text.body_small',
  color: WHITE,
  display: 'block',
  minWidth: '100%',
  width: 'max-content',
  maxWidth: '100%',
  textAlign: 'center',
  px: '1.5rem',
};

const dividerDecoratorStyles: CSS = {
  height: '2rem',
  width: '0.1rem',
  alignSelf: 'center',
  bg: TEAL_MEDIUM,
};

const absentStyles: CSS = {
  color: RED_TRUE,
};

interface TimeLabelContainerProps extends ComponentProps<'time'> {
  label: ReactNode,
}


export const TimeLabelContainer = ({ label, ...rest }: TimeLabelContainerProps) => (
  <div sx={timeLabelContainerStyles}>
    <span sx={{ ...timeLabelInnerItemStyles, fontWeight: 'bold' }}>{label}</span>
    <span sx={dividerDecoratorStyles} />
    <time sx={timeLabelInnerItemStyles} {...rest} />
  </div>
);

export interface AttendanceHoverDetailProps {
  sessionId: string,
  userId: string,
  id?: string,
}

function AttendanceHoverDetail({ userId, sessionId, id }: AttendanceHoverDetailProps) {
  const sessionMap = useSelector(getAllViewSessions);
  const session: CCServiceAllViewAttendanceSession | undefined = sessionMap[sessionId];
  const user: CCServiceAttendanceRosterUser | undefined = session?.roster[userId];
  const sessionStartDate = new Date(session.startTime);

  if (!user || !user.displayName) return null;

  return (
    <Tooltip position="top" sx={tooltipStyles} variant="white" id={id}>
      <span sx={displayNameStyles}>{formatLastnameFirstname(user.displayName)}</span>
      <time dateTime={session.startTime} sx={dateStyles}>
        {dateToMonthString(sessionStartDate).slice(0, 3)}
        {' '}
        {dateToNumericDateString(sessionStartDate)}
      </time>
      <div sx={attendanceStatusContainerStyles}>
        {user.attendanceStatus === 'present' ? (
          <Fragment>
            <TimeLabelContainer label="Joined" dateTime={user.joinTime}>
              {user.joinTime && dateToClockTime(new Date(user.joinTime))}
            </TimeLabelContainer>

            <TimeLabelContainer label="Left" dateTime={user.leaveTime || ''}>
              {user.leaveTime && dateToClockTime(new Date(user.leaveTime))}
            </TimeLabelContainer>

            {/** Our duration text formatting is valid. No need for dateTime prop to specify further.
             * @see https://html.spec.whatwg.org/multipage/text-level-semantics.html#attr-time-datetime */}
            <TimeLabelContainer label="Total">
              {msToDurationString(user.totalTime)}
            </TimeLabelContainer>
          </Fragment>
        ) : (
          <span sx={absentStyles}>Absent</span>
        )}
      </div>
    </Tooltip>
  );
}

export default memo(AttendanceHoverDetail);
