import TypedEventEmitter from 'utils/typedEventEmitter';
import clamp from '../utils/clamp';
import vertexWebGLShaderSource from '../shaders/webgl/filter.vert';
import vertexWebGL2ShaderSource from '../shaders/webgl2/filter_2.vert';
import {
  CurrentBoundingBox, TargetBoundingBox, HTMLMediaReadyState,
  FilterEventData, FilterOptions, Prediction, Logger, Filters, FilterEvent, FaceDetectionEvent,
} from '../types';
import { createProgram, createShader, createTexture } from '../utils/webGLUtils';
import { Tween, Easing } from '../utils/tween.esm';
import { Kernel } from './kernel';
import { FaceDetector } from '../faceDetector/faceDetector';

export const FILTER_DEFAULTS: FilterOptions = {
  enableFaceDetection: true,

  predictionInterval: 2000,

  tensorFlowBackend: 'wasm',

  // max frame rate we typically stream anyway
  maxFrameRate: 24,

  initialFilter: Filters.PASS_THROUGH,

  croppedCanvasSize: 250,

  logger: console,
};

/**
 * Takes a user-supplied video feed and renders manipulated video to user-supplied canvas.
 *
 * Uses video feed pixel data to estimate face location using ML face detection algorithm.
 *
 * If the Insertable Streams API is supported, and the developer provides a stream,
 * this function attempts to do face detection in a web worker, but falls back to main thread.
 *
 * Crops user's video feed based ont their face location and adds any additional (optional) video filtering.
*/
export class Filter<L extends Logger = Logger> extends TypedEventEmitter<FilterEventData> {
  /** If active, the component in which this class is being used is mounted.
   * Should be detecting faces, and it should be trying to render to the provided canvas */
  private hasBeenCleanedUp = false;

  /** Latest prediction received from face detection model */
  private latestPrediction: Prediction | null = null;

  /** Bounding box that is currently cropping the user's video
   * topLeftX %, topLeftY %, sizeX %. sizeY % */
  private currentBoundingBoxPercent: CurrentBoundingBox = null;

  /** Where the bounding box SHOULD be going to (as opposed to where it currently is) */
  private targetBoundingBoxPixels: TargetBoundingBox = {
    topLeftX: 0,
    topLeftY: 0,
    sizeX: 0,
    sizeY: 0,
  };

  private get targetBoundingBoxPercent(): TargetBoundingBox {
    if (this.srcVideo.videoWidth === 0 || this.srcVideo.videoHeight === 0) {
      return {
        topLeftX: 0,
        topLeftY: 0,
        sizeX: 0,
        sizeY: 0,
      };
    }
    return {
      topLeftX: this.targetBoundingBoxPixels.topLeftX / this.srcVideo.videoWidth,
      topLeftY: this.targetBoundingBoxPixels.topLeftY / this.srcVideo.videoHeight,
      sizeX: this.targetBoundingBoxPixels.sizeX / this.srcVideo.videoWidth,
      sizeY: this.targetBoundingBoxPixels.sizeY / this.srcVideo.videoHeight,
    };
  }

  /** Used to transition the location of the current bounding box smoothly */
  private boundingBoxTween: InstanceType<typeof Tween> | null = null;

  /** User-supplied video element from which pixel data is extracted */
  private srcVideo: HTMLVideoElement;

  private _srcVideoDataLoaded = false;

  private srcVideoDataLoadedTimeout: NodeJS.Timeout | null = null;

  /** Video stream has loaded (i.e. there is actual video content coming through the stream) */
  private get srcVideoDataLoaded() {
    return this._srcVideoDataLoaded;
  }

  private set srcVideoDataLoaded(loaded) {
    const ERROR_TIMEOUT = 10_000;

    // if video has not loaded within a set amount of time, log an error
    if (this.srcVideoDataLoadedTimeout === null && loaded === false) {
      this.srcVideoDataLoadedTimeout = setTimeout(() => {
        this.logger.error(`Filter srcVideo data has not loaded within ${ERROR_TIMEOUT}ms`);
      }, ERROR_TIMEOUT);
    } else if (this.srcVideoDataLoadedTimeout !== null && loaded) {
      clearTimeout(this.srcVideoDataLoadedTimeout);
    }
    this._srcVideoDataLoaded = loaded;
  }

  /** User-supplied canvas element to which manipulated pixel data is drawn */
  private destCanvas: HTMLCanvasElement;

  /** WebGL rendering context, received from user-supplied destination canvas element */
  private gl: WebGLRenderingContext | WebGL2RenderingContext;

  /** Enabled in most modern browsers (but not Safari except for on OS 15+) */
  private webgl2Enabled = false;

  private _predictionInterval: FilterOptions['predictionInterval'];

  private logger: L;

  private contextLost = false;

  /** How often face detection predictions should be made (in ms) & and how quickly the bounding
   * box should animate to the new location where a face was detected
   *
   * If changed, the new interval takes effect after the previous prediction interval has completed.
   *
   * min = 1, max = 30,000 */
  public get predictionInterval(): FilterOptions['predictionInterval'] {
    return this._predictionInterval;
  }

  public set predictionInterval(interval: FilterOptions['predictionInterval']) {
    const sanitizedInterval = Math.max(1, Math.min(interval, 30_000));
    if (this._predictionInterval === sanitizedInterval) return;
    this._predictionInterval = interval;
    this.emit(FilterEvent.PREDICTION_INTERVAL_SET.id, sanitizedInterval);
  }

  /**
   * Framebuffers are used to apply multi-step render passes by flip-flopping between them.
   *
   * For example:
   * Read from video frame -> Apply filter -> Write to framebuffer texture 1
   * Read from framebuffer texture 1 -> Apply filter -> Write to framebuffer texture 2
   * Read from framebuffer texture 2 -> Apply filter -> Write to framebuffer texture 1
   * Read from framebuffer texture 1 -> Apply filter -> Write to canvas
   */
  private framebuffers: (WebGLFramebuffer | null)[] = [];

  /** Framebuffer textures are what actually hold the data when rendering to a framebuffer */
  private framebufferTextures: WebGLTexture[] = [];

  private _currentFilter!: Filters;

  /** Filter that was provided at initialization time (or the default one) */
  private initialFilter: Filters;

  /** The currently active, primary filter (as opposed to any filterSteps that this main filter requires) */
  public get currentFilter() {
    return this._currentFilter;
  }

  public set currentFilter(filter: Filters) {
    if (this.currentFilter === filter) return;

    // initialize state with the new filter selection
    this._currentFilter = filter;
    this.initFilterState(filter);
    this.emit(FilterEvent.CURRENT_FILTER_SET.id, filter);
  }

  /** Vertex shader shared between all WebGl1 programs */
  private vertexShaderWebGL: WebGLShader;

  /** Vertex shader shared between all WebGl2 programs */
  private vertexShaderWebGL2: WebGLShader | null = null;

  /**
   * Compiled shaders for each filter type.
   *
   * If a shader requires WebGL2 and WebGL2 is not supported by the user's browser,
   * than the shader at that index will be `null`.
   */
  private shaders: (WebGLShader | null)[] = new Array(Filters.asArray().length).fill(null);

  /**
   * Linked programs for each filter type
   *
   * If a program requires WebGL2 and WebGL2 is not supported by the user's browser,
   * than the program at that index will be `null`.
   */
  private programs: (WebGLProgram | null)[] = new Array(Filters.asArray().length).fill(null);

  /**
   * Returns a list of all filters that are supported by the current user's browser--most of these filters are not
   * necessarily intended for public use, since they represent sub-steps to a larger filter.
   *
   * If a user's browser does not support WebGL2, than any filters that require WebGL2 if will not be returned.
  */
  public get enabledFilters() {
    return this._enabledFilters;
  }

  private _enabledFilters: Filters[] = [];

  private _enabledPublicFilters: Filters[] = [];

  private _currentAnimationFrame: number | null = null;

  private get currentAnimationFrame(): number | null {
    return this._currentAnimationFrame;
  }

  /** Cancelling the current animation frame when setting a new one
   * prevents accidentally requesting multiple animation frames at once */
  private set currentAnimationFrame(requestId: number | null) {
    if (this.currentAnimationFrame) window.cancelAnimationFrame(this.currentAnimationFrame);
    this._currentAnimationFrame = requestId;
  }

  /**
   * Returns a list of all filters that are supported by the current user's browser and which are intended for public use.
   * This excludes filters that are only used as sub-processes to compose a larger filter (such as the Sobel filter in Canny Edge Detection).
   *
   * If a user's browser does not support WebGL2, than any filters that require WebGL2 if will not be returned.
  */
  public get enabledPublicFilters() {
    return this._enabledPublicFilters;
  }

  private _doubleThresholdHigh = 0.25;

  /** Used in Double Threshold filter (part of Canny Edge Detection) as the high threshold value */
  public get doubleThresholdHigh() {
    return this._doubleThresholdHigh;
  }

  public set doubleThresholdHigh(high: number) {
    if (high < 0.001 || high > 1.0) return;
    this._doubleThresholdHigh = high;
    // never let values "crisscross"
    if (high < this._doubleThresholdLow) this.doubleThresholdLow = high;
    this.initDoubleThresholdState();
    this.emit(FilterEvent.DOUBLE_THRESHOLD_HIGH_VALUE_SET.id, high);
  }

  private _doubleThresholdLow = 0.1;

  /** Used in Double Threshold filter (part of Canny Edge Detection) as the low threshold value */
  public get doubleThresholdLow() {
    return this._doubleThresholdLow;
  }

  public set doubleThresholdLow(low: number) {
    if (low < 0.001 || low > 1.0) return;
    this._doubleThresholdLow = low;
    // never let values "crisscross"
    if (low > this._doubleThresholdHigh) this.doubleThresholdHigh = low;
    this.initDoubleThresholdState();
    this.emit(FilterEvent.DOUBLE_THRESHOLD_LOW_VALUE_SET.id, low);
  }

  private strongOutputValue = 1.0;

  private weakOutputValue = 0.5;

  private noOutputValue = 0.0;

  private _enableFaceDetection;

  /** Turning this on will cause Filter to begin detecting faces on the cropping the canvas output accordingly.
   * Turning this off will return the currently cropped position to a center square and stop any future face detections. */
  public get enableFaceDetection() {
    return this._enableFaceDetection;
  }

  public set enableFaceDetection(enabled: boolean) {
    if (this._enableFaceDetection === enabled) return;
    this._enableFaceDetection = enabled;

    if (enabled) {
      // canvas gets updated during render()

      // render a square bounding box
      const centerX = this.srcVideo.videoWidth / 2;
      const centerY = this.srcVideo.videoHeight / 2;
      const size = Math.min(this.srcVideo.videoWidth, this.srcVideo.videoHeight);
      const topLeftX = Math.max(centerX - size / 2, 0);
      const topLeftY = Math.max(centerY - size / 2, 0);
      this.targetBoundingBoxPixels = {
        topLeftX, topLeftY, sizeX: size, sizeY: size,
      };

      // snap to position on next render
      this.currentBoundingBoxPercent = { ...this.targetBoundingBoxPercent };

      // start/resume sending image data to worker
      this.startFaceDetection();
    } else if (!enabled) {
      // canvas gets updated during render()

      this.boundingBoxTween?.stop();

      // pull full video frame as texture
      this.targetBoundingBoxPixels = {
        topLeftX: 0,
        topLeftY: 0,
        sizeX: this.srcVideo.videoWidth,
        sizeY: this.srcVideo.videoHeight,
      };

      // snap to position on next render
      this.currentBoundingBoxPercent = { ...this.targetBoundingBoxPercent };
    }

    this.emit(FilterEvent.ENABLE_FACE_DETECTION_SET.id, enabled);
  }

  private faceDetection: FaceDetector;

  private maxWaitInterval!: number;

  private _maxFrameRate!: number;

  /** This is the max frame rate that Filter will draw to the destination canvas.
   * Minimum is 1 and defaults to 120, but anything higher than 60 is probably safe,
   * since requestAnimationFrame usually only runs at a maximum of 60fps */
  public get maxFrameRate() {
    return this._maxFrameRate;
  }

  public set maxFrameRate(rate: number) {
    const sanitizedMaxFrameRate = Math.max(rate, 1);
    if (this.maxFrameRate === sanitizedMaxFrameRate) return;
    this._maxFrameRate = sanitizedMaxFrameRate;
    this.maxWaitInterval = Math.round(1000 / sanitizedMaxFrameRate);
    this.emit(FilterEvent.MAX_FRAME_RATE_SET.id, sanitizedMaxFrameRate);
  }

  private _croppedCanvasSize!: number;

  /** When using `enableScalingCanvasToVideo`, this setting allows the
   * user to increase or decrease canvas size proportionally
   *
   * A value of `1` means that 1px on the canvas corresponds to 1px on the video */
  public get croppedCanvasSize() {
    return this._croppedCanvasSize;
  }

  public set croppedCanvasSize(size: number) {
    if (size === this._croppedCanvasSize) return;
    let sanitizedSize = Math.round(clamp(1, size, 2400));
    // for some reason, odd numbers cause the browser to lose the WebGL context
    if (sanitizedSize % 2 !== 0) sanitizedSize += 1;
    this._croppedCanvasSize = sanitizedSize;
    this.emit(FilterEvent.CROPPED_CANVAS_SIZE_SET.id, sanitizedSize);
  }

  private texCoordBuffer: WebGLBuffer | null = null;

  private vertexPositionBuffer: WebGLBuffer | null = null;

  constructor(srcVideo: HTMLVideoElement, destCanvas: HTMLCanvasElement, options?: Partial<FilterOptions<L>>) {
    super();
    this.srcVideo = srcVideo;
    this.destCanvas = destCanvas;

    // set up state based on passed-in options
    this._predictionInterval = options?.predictionInterval ?? FILTER_DEFAULTS.predictionInterval;
    this._enableFaceDetection = options?.enableFaceDetection ?? FILTER_DEFAULTS.enableFaceDetection;
    this.maxFrameRate = options?.maxFrameRate ?? FILTER_DEFAULTS.maxFrameRate;
    this.croppedCanvasSize = options?.croppedCanvasSize ?? FILTER_DEFAULTS.croppedCanvasSize;
    this.logger = options?.logger ?? (FILTER_DEFAULTS.logger as L);
    this.faceDetection = new FaceDetector({
      tensorFlowBackend: options?.tensorFlowBackend ?? FILTER_DEFAULTS.tensorFlowBackend,
      logger: this.logger,
    });
    this.initialFilter = options?.initialFilter ?? FILTER_DEFAULTS.initialFilter;
    this.addFaceDetectorListeners();

    // get webgl context -- default to WebGL2, but downshift to WebGL if WebGL2 is not available
    let gl: WebGLRenderingContext | WebGL2RenderingContext | null = this.destCanvas.getContext('webgl2');
    if (gl) this.webgl2Enabled = true;
    else {
      this.webgl2Enabled = false;
      gl = this.destCanvas.getContext('webgl');
    }
    if (!gl) throw new Error('neither webgl2 nor webgl are supported by this browser');
    this.gl = gl;

    // compile vertex shaders -- one for webgl & one for webgl2
    // all WebGL1 fragment shaders use the same vertex shader,
    // and all WebGL2 fragment shaders use the same vertex shader
    this.vertexShaderWebGL = createShader(this.gl, this.gl.VERTEX_SHADER, vertexWebGLShaderSource);
    if (this.webgl2Enabled) {
      this.vertexShaderWebGL2 = createShader(this.gl, this.gl.VERTEX_SHADER, vertexWebGL2ShaderSource);
    }

    // TEXTURE COORDINATE BUFFER /////////////////////////////////////////////////
    this.texCoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0]),
      this.gl.STATIC_DRAW,
    );

    // VERTEX POSITION BUFFER ////////////////////////////////////////////////////
    this.vertexPositionBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexPositionBuffer);
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array([-1.0, 1.0, 1.0, 1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 1.0, 1.0, -1.0]),
      this.gl.STATIC_DRAW,
    );

    // CREATE VIDEO FRAME TEXTURE ////////////////////////////////////////////////
    this.videoFrameTexture = createTexture(this.gl);
    // Load a single-pixel (opaque black) texture while waiting for video frame data
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, new Uint8Array([0, 0, 0, 255]));

    // CREATE FRAMEBUFFERS ///////////////////////////////////////////////////////
    if (this.framebufferTextures.length === 0) {
      const NUM_FRAMEBUFFERS = 2;
      this.framebufferTextures = [];
      this.framebuffers = [];
      for (let i = 0; i < NUM_FRAMEBUFFERS; i += 1) {
        // this texture stores the render that we draw into the framebuffers on multi-step filters
        const texture = createTexture(this.gl);
        this.framebufferTextures.push(texture);

        // put 1px of empty data inside the framebuffer for now (until video width is available)
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, null);

        // create framebuffer
        const framebufferObject = this.gl.createFramebuffer();
        this.framebuffers.push(framebufferObject);
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, framebufferObject);

        // attach texture to framebuffer
        this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D, texture, 0);
      }
    }

    // filters that are available for use on this user's browser
    const enabledFilters: Filters[] = [];

    // compile all fragment shaders from source code
    // and create a corresponding WebGL program
    Filters.asArray().forEach((filter) => {
      // don't setup any programs that require webgl2 if webgl2 is not enabled
      if (filter.requiresWebGl2 && !this.webgl2Enabled) return;
      const vertexShader = filter.requiresWebGl2
        ? this.vertexShaderWebGL2 : this.vertexShaderWebGL;

      // create fragment shader and attach to a corresponding program
      this.shaders[filter.integer] = createShader(this.gl, this.gl.FRAGMENT_SHADER, filter.source);
      const shader = this.shaders[filter.integer];
      if (!shader) throw new Error('Shader was not initialized correctly');
      if (!vertexShader) throw new Error('Vertex shader was not initialized correctly');
      this.programs[filter.integer] = createProgram(this.gl, vertexShader, shader);
      const program = this.programs[filter.integer];
      if (!program) throw new Error('Program was not initialized correctly');
      enabledFilters.push(filter);
    });

    // save which filters were actually able to be compiled (based on if WebGL 2 is enabled)
    this._enabledFilters = enabledFilters;
    // of the filters which can be compiled, save which ones are actually available/intended for public use
    this._enabledPublicFilters = enabledFilters.filter((filter) => filter.publicFilter);

    this.currentFilter = this.initialFilter;

    // once video data has loaded, we can crop the user's video to be square and start making face detection predictions
    this.srcVideo.onloadstart = (e) => this.handleVideoLoadStart(e);
    this.srcVideo.onloadeddata = (e) => this.handleVideoDataLoaded(e);

    /* If the video's data has already loaded, then there will be no event emitted.
    In this case, we should immediately begin pulling frames.
    This can happen if the user sets up the Filter after video data has already loaded */
    if (this.srcVideo.readyState === HTMLMediaReadyState.HAVE_ENOUGH_DATA) {
      this.emit(FilterEvent.VIDEO_DATA_ALREADY_READY.id, null);
      this.handleVideoDataLoaded();
    }

    this.destCanvas.addEventListener('webglcontextlost', (event) => {
      this.contextLost = true;
      this.enableFaceDetection = false;
      if (this.currentAnimationFrame) {
        window.cancelAnimationFrame(this.currentAnimationFrame);
      }
      this.emit(FilterEvent.CONTEXT_LOST.id, event);
    });

    this.destCanvas.addEventListener('webglcontextrestored', (event) => {
      this.emit(FilterEvent.CONTEXT_RESTORED.id, event);
    });
  }

  // / //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PUBLIC METHODS START:


  /** Cleans up all state (must be called when done using Filter to prevent memory leaks) */
  public cleanup() {
    this.emit(FilterEvent.CLEANING_UP.id, null);
    this.enableFaceDetection = false;
    this.hasBeenCleanedUp = true;
    this.faceDetection.cleanup();
    this.gl.getExtension('WEBGL_lose_context')?.loseContext();
    this.removeAllListeners();
  }

  // PUBLIC METHODS END:
  // / //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PRIVATE METHODS START:

  /** This event happens when the video element is first initialized
   * or when its `src` or `srcObject` attribute is changed.
   *
   * Do not try to pull video frames when the video data has not yet loaded */
  private handleVideoLoadStart(e: Event) {
    this.srcVideoDataLoaded = false;
    this.emit(FilterEvent.VIDEO_DATA_LOAD_START.id, e);
  }

  /** This signifies that the video's data has finished loading and it is ready to pull video frames.
   * Can also be called manually if the video element's data is already in readyState = 4.
   */
  private handleVideoDataLoaded(e: Event | null = null) {
    this.srcVideoDataLoaded = true;
    this.setInitialBoundingBox();
    if (this.enableFaceDetection) this.startFaceDetection();
    this.currentAnimationFrame = requestAnimationFrame(this.render.bind(this));
    if (e) this.emit(FilterEvent.VIDEO_DATA_LOADED.id, e);
  }

  private addFaceDetectorListeners() {
    FaceDetectionEvent.asArray().forEach((event) => {
      this.faceDetection.on(event.id, (data) => {
        // perform any event-specific handling
        switch (event.id) {
          case FaceDetectionEvent.PREDICTION_COMPLETE.id:
            this.latestPrediction = data as Prediction | null;
            this.setNewBoundingBoxTween();
            break;
          default:
            break;
        }

        // propagate event
        this.emit(event.id, data);
      });
    });
  }

  private _detectingFaces = false;

  private async startFaceDetection() {
    if (this._detectingFaces) return;
    this._detectingFaces = true;

    while (this.enableFaceDetection) {
      if (this.getVideoDataIsReady()) {
        this.faceDetection.predict(this.srcVideo);
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise((res) => {
        setTimeout(res, this.predictionInterval);
      });
    }

    this._detectingFaces = false;
  }

  /** Set initial bounding box and target bounding box when video is first loaded.
   * If faceDetection is enabled, crop to be centered and square, else show the entire video frame.
   * Requires video stream data to be loaded in order to set values correctly */
  private setInitialBoundingBox() {
    if (!this.srcVideoDataLoaded) {
      throw new Error('Source video data was not loaded before calling setInitialBoundingBox');
    }

    if (this.enableFaceDetection) {
      // use square bounding box
      const centerX = this.srcVideo.videoWidth / 2;
      const centerY = this.srcVideo.videoHeight / 2;
      const size = Math.min(this.srcVideo.videoWidth, this.srcVideo.videoHeight);
      const topLeftX = Math.max(centerX - size / 2, 0);
      const topLeftY = Math.max(centerY - size / 2, 0);

      // pixels is converted automatically to percent through `this.currentBoundingBoxPercent`
      this.targetBoundingBoxPixels = {
        topLeftX, topLeftY, sizeX: size, sizeY: size,
      };
      this.currentBoundingBoxPercent = { ...this.targetBoundingBoxPercent };
    } else {
      // use actual video sizes
      // pixels is converted automatically to percent through `this.currentBoundingBoxPercent`
      this.targetBoundingBoxPixels = {
        topLeftX: 0,
        topLeftY: 0,
        sizeX: this.srcVideo.videoWidth,
        sizeY: this.srcVideo.videoHeight,
      };
      this.currentBoundingBoxPercent = { ...this.targetBoundingBoxPercent };
    }
  }

  /** Sets up any necessary state for a particular FilterTypes */
  private initFilterState(filter: Filters) {
    const program = this.programs[filter.integer];
    if (program) this.initGeneralProgramState(program);

    // do any additional, specific state setup
    switch (filter) {
      case Filters.GAUSSIAN_BLUR_2:
        this.initGaussianBlurState();
        break;
      case Filters.DOUBLE_THRESHOLD_2:
        this.initDoubleThresholdState();
        break;
      case Filters.HYSTERESIS_2:
        this.initHysteresisState();
        break;
      default:
        break;
    }
  }

  private vertexPositionLocation = 0;

  private vertexTextureCoordLocation = 0;

  private videoFrameTextureLocation: WebGLUniformLocation | null = null;

  private boundingBoxInfoLocation: WebGLUniformLocation | null = null;

  private shouldCropToBoundingBoxLocation: WebGLUniformLocation | null = null;

  private isFinalDrawLocation: WebGLUniformLocation | null = null;

  private videoFrameTexture: WebGLTexture | null = null;

  private kernelUniformLocation: WebGLUniformLocation | null = null;

  private kernelSizeUniformLocation: WebGLUniformLocation | null = null;

  private kernelWeightUniformLocation: WebGLUniformLocation | null = null;

  private doubleThresholdLowUniformLocation: WebGLUniformLocation | null = null;

  private doubleThresholdHighUniformLocation: WebGLUniformLocation | null = null;

  private strongOutputValueUniformLocation: WebGLUniformLocation | null = null;

  private weakOutputValueUniformLocation: WebGLUniformLocation | null = null;

  private noOutputValueUniformLocation: WebGLUniformLocation | null = null;

  private videoResolutionLocation: WebGLUniformLocation | null = null;

  /** Resets general state necessary for most WebGL filter programs */
  private initGeneralProgramState(program: WebGLProgram) {
    // INITIALIZATION / BOILERPLATE /////////////////////////////////////////////////
    this.gl.useProgram(program);

    // get positions of attributes and uniforms
    this.vertexPositionLocation = this.gl.getAttribLocation(program, 'a_vertex_position');
    this.videoFrameTextureLocation = this.gl.getUniformLocation(program, 'u_video_frame_texture');
    this.vertexTextureCoordLocation = this.gl.getAttribLocation(program, 'a_vertex_texture_coord');
    this.boundingBoxInfoLocation = this.gl.getUniformLocation(program, 'u_bounding_box_info');
    this.shouldCropToBoundingBoxLocation = this.gl.getUniformLocation(program, 'u_should_crop_to_bounding_box');
    this.isFinalDrawLocation = this.gl.getUniformLocation(program, 'u_is_final_draw');
    this.kernelUniformLocation = this.gl.getUniformLocation(program, 'u_kernel');
    this.kernelSizeUniformLocation = this.gl.getUniformLocation(program, 'u_kernel_size');
    this.kernelWeightUniformLocation = this.gl.getUniformLocation(program, 'u_kernel_weight');
    this.doubleThresholdLowUniformLocation = this.gl.getUniformLocation(program, 'u_low_threshold');
    this.doubleThresholdHighUniformLocation = this.gl.getUniformLocation(program, 'u_high_threshold');
    this.strongOutputValueUniformLocation = this.gl.getUniformLocation(program, 'u_strong_output');
    this.weakOutputValueUniformLocation = this.gl.getUniformLocation(program, 'u_weak_output');
    this.noOutputValueUniformLocation = this.gl.getUniformLocation(program, 'u_no_output');
    this.videoResolutionLocation = this.gl.getUniformLocation(program, 'u_video_resolution');

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
    this.gl.enableVertexAttribArray(this.vertexTextureCoordLocation);
    this.gl.vertexAttribPointer(this.vertexTextureCoordLocation, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexPositionBuffer);
    this.gl.enableVertexAttribArray(this.vertexPositionLocation);
    this.gl.vertexAttribPointer(this.vertexPositionLocation, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.videoFrameTexture);

    // Tell the fragment shader to get the u_image texture from texture unit 0
    this.gl.uniform1i(this.videoFrameTextureLocation, 0);

    this.setCurrentBoundingBoxUniform();

    // should crop on initial draw into framebuffer, but not on subsequent passes
    this.gl.uniform1i(this.shouldCropToBoundingBoxLocation, 1);

    // do not flip y by default
    this.gl.uniform1f(this.isFinalDrawLocation, 0);

    return program;
  }

  private initDoubleThresholdState() {
    this.gl.uniform1f(this.doubleThresholdHighUniformLocation, this._doubleThresholdHigh);
    this.gl.uniform1f(this.doubleThresholdLowUniformLocation, this._doubleThresholdLow);
    this.gl.uniform1f(this.strongOutputValueUniformLocation, this.strongOutputValue);
    this.gl.uniform1f(this.weakOutputValueUniformLocation, this.weakOutputValue);
    this.gl.uniform1f(this.noOutputValueUniformLocation, this.noOutputValue);
  }

  private initHysteresisState() {
    this.gl.uniform1f(this.strongOutputValueUniformLocation, this.strongOutputValue);
    this.gl.uniform1f(this.weakOutputValueUniformLocation, this.weakOutputValue);
    this.gl.uniform1f(this.noOutputValueUniformLocation, this.noOutputValue);
  }

  /** Sets up kernel uniforms for gaussian blur */
  private initGaussianBlurState() {
    const kernelSize = 5;
    const { kernel, kernelWeight } = Kernel.gaussian(kernelSize);
    this.gl.uniform1fv(this.kernelUniformLocation, kernel);
    this.gl.uniform1i(this.kernelSizeUniformLocation, kernelSize);
    this.gl.uniform1f(this.kernelWeightUniformLocation, kernelWeight);
  }

  /** This is how much the bounding box should be scaled up or down when converting the
   * face detection prediction in to a usable bounding box  */
  private boundingBoxScale = 2.5;

  /** Converts latest face detection prediction into useable, square bounding box */
  private updateTargetBoundingBox() {
    if (!this.latestPrediction || !this.srcVideo) return;

    // calculate square bounding box
    const [originalCenterX, originalCenterY] = [
      Math.floor(this.latestPrediction.topLeftX + this.latestPrediction.width / 2),
      Math.floor(this.latestPrediction.topLeftY + this.latestPrediction.height / 2),
    ];

    /** The bounding box should be the greatest possible prediction that is smaller than the smallest video dimension */
    const minVideoDimension = Math.min(this.srcVideo.videoHeight, this.srcVideo.videoWidth);
    const size = Math.min(Math.max(this.latestPrediction.width, this.latestPrediction.height) * this.boundingBoxScale, minVideoDimension);
    const topLeftX = Math.min(
      Math.max(originalCenterX - size / 2, 0),
      this.srcVideo.videoWidth - size,
    );
    const topLeftY = Math.min(
      Math.max(originalCenterY - size / 2, 0),
      this.srcVideo.videoHeight - size,
    );

    this.targetBoundingBoxPixels = {
      topLeftX, topLeftY, sizeX: size, sizeY: size,
    };
  }

  /** Sets up a new tween to animate the position of the current bounding box
   * to the new target bounding box position */
  private setNewBoundingBoxTween(shouldUpdateTargetBoundingBox = true) {
    if (!this.currentBoundingBoxPercent) {
      throw new Error('trying to setNewBoundingBoxTween when there is no currentBoundingBox');
    }
    if (shouldUpdateTargetBoundingBox) {
      this.updateTargetBoundingBox();
    }
    if (this.boundingBoxTween) this.boundingBoxTween.stop();
    this.boundingBoxTween = new Tween(this.currentBoundingBoxPercent)
      .to({
        topLeftX: this.targetBoundingBoxPercent.topLeftX,
        topLeftY: this.targetBoundingBoxPercent.topLeftY,
        sizeX: this.targetBoundingBoxPercent.sizeX,
        sizeY: this.targetBoundingBoxPercent.sizeY,
      }, this.predictionInterval)
      .easing(Easing.Quadratic.InOut)
      .start();
  }

  /** User's video must be cropped down to a square on the first draw pass */
  private cropped = false;

  private prevTextureSize = { width: 0, height: 0 };

  /** This function simply loads a video frame into WebGL as a 2D texture and then runs the
   * shaders based on that data, drawing the output to the user-supplied canvas. */
  private async render(now: number): Promise<void> {
    // should stop rendering if class state has been cleaned up
    if (this.hasBeenCleanedUp || this.contextLost) return;

    // wait for all necessary state to become ready
    if (!this.srcVideo || !this.destCanvas || !this.gl || !this.srcVideoDataLoaded || !this.getVideoDataIsReady()) {
      this.currentAnimationFrame = requestAnimationFrame(this.render.bind(this));
      return;
    }

    if (this.enableFaceDetection) {
      // mutate current bounding box toward target bounding box
      this.boundingBoxTween?.update(performance.now());

      // make sure canvas is the correct size for rendering
      if (this.destCanvas.width !== this.croppedCanvasSize) this.destCanvas.width = this.croppedCanvasSize;
      if (this.destCanvas.height !== this.croppedCanvasSize) this.destCanvas.height = this.croppedCanvasSize;
    } else {
      // make sure canvas is the correct size for rendering
      if (this.destCanvas.width !== this.srcVideo.videoWidth) this.destCanvas.width = this.srcVideo.videoWidth;
      if (this.destCanvas.height !== this.srcVideo.videoHeight) this.destCanvas.height = this.srcVideo.videoHeight;
    }

    this.setCurrentBoundingBoxUniform();

    // update framebuffer textures to be the same size as destination canvas
    if (this.prevTextureSize.width !== this.gl.canvas.width || this.prevTextureSize.height !== this.gl.canvas.height) {
      this.prevTextureSize = { width: this.gl.canvas.width, height: this.gl.canvas.height };
      this.framebufferTextures.forEach((texture) => {
        this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
        this.gl.texImage2D(
          this.gl.TEXTURE_2D,
          0,
          this.gl.RGBA,
          this.gl.canvas.width,
          this.gl.canvas.height,
          0,
          this.gl.RGBA,
          this.gl.UNSIGNED_BYTE,
          null,
        );
      });
    }

    // RENDERING TO FRAMEBUFFER /////////////////////////////////////////////////////////////////////////////////////
    this.cropped = false;

    if (this.currentFilter.filterSteps.length > 0) {
      // loop through each subfilter effect and draw to framebuffer
      // this step is only required in filters that require more than one draw pass
      let prevFramebufferTexture: WebGLTexture | null = null;
      for (let i = 0, evenOddCount = 0; i < this.currentFilter.filterSteps.length; i += 1, evenOddCount += 1) {
        // set which filter effect to apply
        const filterStep = this.currentFilter.filterSteps[i];
        this.initFilterState(filterStep);

        // load video frame as current texture on the first pass and start reading from that one first
        if (i === 0) this.setVideoFrameAsTexture();
        // on subsequent draws, use the framebuffer we just rendered to
        else this.gl.bindTexture(this.gl.TEXTURE_2D, prevFramebufferTexture);

        // crop to destination canvas size only on the first pass
        this.updateShouldCropUniform();

        // do not flip y while drawing to framebuffer
        this.gl.uniform1f(this.isFinalDrawLocation, 0);

        // setup to draw into one of the framebuffers instead of the canvas
        this.setFramebuffer(this.framebuffers[evenOddCount % 2], this.gl.canvas.width, this.gl.canvas.height);

        this.draw();

        // for the next draw, use the texture we just rendered to.
        prevFramebufferTexture = this.framebufferTextures[evenOddCount % 2];
      }
    } else {
      this.setVideoFrameAsTexture();
    }

    // RENDERING FROM LAST USED FRAMEBUFFER (OR VIDEO FRAME) TO CANVAS //////////////////////////////////////////////////
    // map clip space coordinates to canvas coordinates
    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height);

    this.gl.uniform2fv(this.videoResolutionLocation, [this.srcVideo.videoWidth, this.srcVideo.videoHeight]);

    // draw to canvas instead of framebuffer
    this.setFramebuffer(null, this.gl.canvas.width, this.gl.canvas.height);

    // crop to destination canvas size only on the first pass
    this.updateShouldCropUniform();

    // flip y on the final draw to the canvas
    this.gl.uniform1f(this.isFinalDrawLocation, 1);

    // clear canvas
    this.gl.clearColor(0, 0, 0, 0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.draw();

    // throttle framerate, if necessary
    const updatedNow = performance.now();
    const elapsedTime = updatedNow - now;
    const timeLeft = Math.max(Math.floor(this.maxWaitInterval - elapsedTime), 0);
    if (this.maxWaitInterval > 0 && timeLeft > 0) {
      await new Promise((res) => { setTimeout(res, timeLeft); });
    }

    this.currentAnimationFrame = requestAnimationFrame(this.render.bind(this));
  }

  /** Loads video frame into WebGL for use as the texture from which to draw pixels.
   *
   * When switching video devices, there is usually a brief moment where the video is empty
   * so check that the srcVideo element actually has data to draw before uploading to webgl.
  */
  private setVideoFrameAsTexture() {
    if (!this.getVideoDimensionsAreZero()) {
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.videoFrameTexture);
      this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.srcVideo);
    } else {
      // this should never happen, since we listen for when the video element is loading new data
      // and we also make this check at the start of the render function
      this.logger.error('Video frame does not have any height or width', {
        info: 'Trying to upload a video frame as texture to WebGL before the video data has loaded can cause WebGL to lose the context',
      });
      this.srcVideoDataLoaded = false;
      const imageData = new ImageData(1, 1);
      imageData.data.set(new Uint8ClampedArray([0, 0, 0, 255])); // opaque black
      this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, imageData);
    }
  }

  private getVideoDataIsReady() {
    const videoDimensions0Detected = this.getVideoDimensionsAreZeroAndUpdate();
    const hasEnoughData = this.srcVideo.readyState === HTMLMediaReadyState.HAVE_ENOUGH_DATA;
    return !videoDimensions0Detected && hasEnoughData;
  }

  /** Makes the check and updates state/emits event when it happens.
    *
    * This can happen when the video's `src` or `srcObject` attribute has changed but the video
    * element has not yet emitted the loadstart event and has also not yet loaded any data to display */
  private getVideoDimensionsAreZeroAndUpdate() {
    const videoDimensions0Detected = this.getVideoDimensionsAreZero();
    if (this.srcVideoDataLoaded && videoDimensions0Detected) {
      this.srcVideoDataLoaded = false;
      this.emit(FilterEvent.VIDEO_DIMENSIONS_0_DETECTED.id, null);
    }

    return videoDimensions0Detected;
  }

  /** Only makes the check and does not update state */
  private getVideoDimensionsAreZero() {
    return this.srcVideo.videoWidth === 0 || this.srcVideo.videoHeight === 0;
  }

  /** Update bounding box in WebGL state to reflect current bounding box */
  private setCurrentBoundingBoxUniform() {
    if (!this.currentBoundingBoxPercent) return;
    this.gl.uniform4f(
      this.boundingBoxInfoLocation,
      this.currentBoundingBoxPercent.topLeftX,
      this.currentBoundingBoxPercent.topLeftY,
      this.currentBoundingBoxPercent.sizeX,
      this.currentBoundingBoxPercent.sizeY,
    );
  }

  /** set framebuffer as the current framebuffer that we are rendering to */
  private setFramebuffer(framebufferObject: WebGLFramebuffer | null, width: number, height: number) {
    // make this the framebuffer we are rendering to.
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, framebufferObject);

    // Tell webgl the viewport setting needed for framebuffer.
    this.gl.viewport(0, 0, width, height);
  }

  /** Run shader and draw to canvas / frame buffer (whichever is currently selected) */
  private draw() {
    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
  }

  private updateShouldCropUniform() {
    // crop to destination canvas size on the first pass
    if (!this.cropped) {
      this.cropped = true;
      this.gl.uniform1i(this.shouldCropToBoundingBoxLocation, 1);
    } else {
      this.gl.uniform1i(this.shouldCropToBoundingBoxLocation, 0);
    }
  }
}
