/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { useDispatch } from 'react-redux';
import Toast from 'components/ui/Messages/Toast';
import { RED_TRUE, WHITE } from 'theme/ui/colors';
import { ReactComponent as WarningSVG } from 'assets/icons/warning-triangle.svg';
import { resetErrorMessage } from 'actions/errorMessageActions';
import useTypedSelector from 'hooks/useTypedSelector';
import { ErrorMessageState } from 'store/types';
import { buttonResetStyles } from 'styles/styles';
import {
  Fragment, useEffect, useMemo, useRef,
} from 'react';
import { ReactComponent as CloseSVG } from 'assets/icons/close.svg';
import FocusRing from 'components/FocusRing/FocusRing';
import { isHTMLElement } from 'utils/typePredicates';
import usePrevious from 'hooks/usePrevious';
import logger from 'utils/logger';
import useResetStateOnTimer from 'hooks/useResetStateOnTimer';
import { makeGetErrorMessage } from 'selectors';
import { initialState } from 'reducers/errorMessageReducer';
import { CSS } from 'types/css';
import { nanoid } from 'nanoid';
import Svg from '../Svg';


const toastChildrenStyles: CSS = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const warningIconStyles: CSS = {
  minWidth: '2.2rem',
  width: '2.2rem',
  minHeight: '2.2rem',
  height: '2.2rem',
  fill: WHITE,
};

const messageStyles: CSS = {
  mx: '2rem',
  // should match the styles of the Toast's span
  lineHeight: 'inherit',
};

const buttonStyles = {
  ...buttonResetStyles,
  width: '2rem',
  height: '2rem',
};

const closeIconStyles: CSS = {
  width: '1.8rem',
  minWidth: '1.8rem',
  height: '1.8rem',
  minHeight: '1.8rem',
  stroke: WHITE,
  strokeWidth: 6,
};

export const ERROR_MESSAGE_LABEL_ID = 'errorMessage';

interface ErrorMessageProps {
  type: keyof ErrorMessageState,
  useTimer?: boolean,
  timerLength?: number,
}

export const ERROR_TIMER_LENGTH_DEFAULT = 3000;

/**
 * Red notification that does NOT goes away on it's own by default--it must be dismissed/closed.
 * Currently, the three variants of the Toast component are Alert, Notification, and ErrorMessage.
 *
 * See spec: https://www.figma.com/file/DGr70ei18pKpTNGNTi8jou/Session-2.0?node-id=353%3A2
 */
function ErrorMessage({ type, useTimer = false, timerLength = ERROR_TIMER_LENGTH_DEFAULT }: ErrorMessageProps) {
  const dispatch = useDispatch();
  const message = useTypedSelector(makeGetErrorMessage(type));
  const prevMessage = usePrevious(message);
  const onClose = () => {
    dispatch(resetErrorMessage(type));
  };
  const prevFocusedElement = useRef<Element | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const ID = useMemo(() => `${ERROR_MESSAGE_LABEL_ID}-${nanoid(5)}`, []);

  // focus button when opened and return focus after close
  // see: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/dialog_role
  useEffect(() => {
    if (message && prevMessage !== message) {
      prevFocusedElement.current = document.activeElement;
      buttonRef.current?.focus();
    } else if (!message && prevMessage !== message && isHTMLElement(prevFocusedElement.current)) {
      prevFocusedElement.current.focus();
    }
  }, [message, prevMessage]);

  useEffect(() => {
    if (message) {
      logger.warn('Error message displayed', { type, message });
    }
  }, [type, message]);

  useResetStateOnTimer({
    timerLength,
    useTimer,
    state: message,
    defaultState: initialState[type],
    clearStateCallback: () => dispatch(resetErrorMessage(type)),
  });

  return (
    <Toast
      bgColor={RED_TRUE}
      childrenStyles={toastChildrenStyles}
      role="dialog"
      aria-labelledby={message ? ID : undefined}
      aria-hidden={!message}
    >
      {message && (
        <Fragment>
          <Svg svg={WarningSVG} sx={warningIconStyles} aria-hidden />
          <p id={ID} sx={messageStyles}>{message}</p>
          <button onClick={onClose} sx={buttonStyles} type="button" aria-label="Close" ref={buttonRef}>
            <Svg svg={CloseSVG} title="Close" sx={closeIconStyles} />
            <FocusRing />
          </button>
        </Fragment>
      )}
    </Toast>
  );
}

export default ErrorMessage;
