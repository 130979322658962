import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import {
  AppThunkAction, ChalkboardColor, ChalkboardTemplate, ChalkboardTheme, ChalkboardTip, ChalkboardToolMode,
} from 'store/types';
import { Bounds, ChalkboardItem, Point } from 'components/Chalkboard/itemData';
import renderItem from 'components/Chalkboard/editor/renderItems/renderItem';
import { MakeActionType } from 'utils/typeUtils';
import { downloadLocalImage } from 'utils/download';
import { endScreenShare } from 'actions/screenShareActions';
import { setAlert } from 'actions/alertActions';
import { ThemeBGColorConfig } from 'components/Chalkboard/common/colorUtils';
import domToImage from 'dom-to-image';
import { chalkboardLogger } from 'components/Chalkboard/chalkboardLogger';
import { EDITOR_SIZE } from 'components/Chalkboard/editor/config';
import { getMessageFromError } from 'utils/errorUtils';
import { setRoomError } from './sharedActions';

const reducerName = 'chalkboardState';
export const SET_IS_CHALKBOARD_OPEN = `${reducerName}/SET_IS_CHALKBOARD_OPEN` as const;
export const TOGGLE_CHALKBOARD_TIP_DISMISSED = `${reducerName}/TOGGLE_CHALKBOARD_TIP_DISMISSED` as const;
export const SET_SHOULD_SHARE_CHALKBOARD = `${reducerName}/SET_SHOULD_SHARE_CHALKBOARD` as const;
export const SET_TOOL_COLOR = `${reducerName}/SET_TOOL_COLOR` as const;
export const SET_THEME = `${reducerName}/SET_THEME` as const;
export const SET_TEMPLATE = `${reducerName}/SET_TEMPLATE` as const;
export const SET_TOOL_MODE = `${reducerName}/SET_TOOL_MODE` as const;
export const SET_AWAITING_CLEAR = `${reducerName}/SET_AWAITING_CLEAR` as const;
export const SET_ITEMS = `${reducerName}/SET_ITEMS` as const;
export const REMOVE_ITEMS = `${reducerName}/REMOVE_ITEMS` as const;
export const SET_IS_DOWNLOADING = `${reducerName}/SET_IS_DOWNLOADING` as const;

export const setIsChalkboardOpen = (isOpen: boolean) => ({
  type: SET_IS_CHALKBOARD_OPEN,
  payload: { isOpen },
});

export const toggleDismissChalkboardTip = (tip: ChalkboardTip) => ({
  type: TOGGLE_CHALKBOARD_TIP_DISMISSED,
  payload: { tip },
});

export const setShouldShareChalkboard = (shouldShare: boolean) => ({
  type: SET_SHOULD_SHARE_CHALKBOARD,
  payload: { shouldShare },
});

export const setChalkboardToolColor = (color: ChalkboardColor) => ({
  type: SET_TOOL_COLOR,
  payload: { color },
});

export const setChalkboardTheme = (theme: ChalkboardTheme) => ({
  type: SET_THEME,
  payload: { theme },
});

export const setChalkboardTemplate = (template: ChalkboardTemplate) => ({
  type: SET_TEMPLATE,
  payload: { template },
});

export const setChalkboardToolMode = (mode: ChalkboardToolMode) => ({
  type: SET_TOOL_MODE,
  payload: { mode },
});

export const setIsAwaitingClear = (awaiting: boolean) => ({
  type: SET_AWAITING_CLEAR,
  payload: { awaiting },
});

export const setChalkboardItems = (items: ChalkboardItem[]) => ({
  type: SET_ITEMS,
  payload: { items },
});

export const removeChalkboardItems = (items: ChalkboardItem[]) => ({
  type: REMOVE_ITEMS,
  payload: { items },
});

export const setIsDownloading = (isDownloading: boolean) => ({
  type: SET_IS_DOWNLOADING,
  payload: { isDownloading },
});

export type ChalkboardAction = MakeActionType<[
  typeof setIsChalkboardOpen,
  typeof toggleDismissChalkboardTip,
  typeof setShouldShareChalkboard,
  typeof setChalkboardToolColor,
  typeof setChalkboardTheme,
  typeof setChalkboardTemplate,
  typeof setChalkboardToolMode,
  typeof setIsAwaitingClear,
  typeof setChalkboardItems,
  typeof removeChalkboardItems,
  typeof setIsDownloading
]>

/**
 * Sets isChalkboardOpen to true
 * If the user is not already sharing and screen share is allowed,
 * set shouldShare to true. This will trigger the useEffect in
 * the ChalkboardCanvas component where the canvas element lives.
 */
export const openChalkboard = (canScreenShare: boolean): AppThunkAction => (dispatch, getState) => {
  const { isScreenCapture } = getState().screenShareState;
  batch(() => {
    dispatch(setIsChalkboardOpen(true));
    if (!isScreenCapture && canScreenShare) {
      dispatch(setShouldShareChalkboard(true));
    }
  });
};

/**
 * Sets isChalkboardOpen and shouldShare to false
 * If the user is currently sharing, end the share.
 */
export const closeChalkboard = (): AppThunkAction => (dispatch, getState) => {
  const { isScreenCapture } = getState().screenShareState;
  batch(() => {
    dispatch(setIsChalkboardOpen(false));
    dispatch(setShouldShareChalkboard(false));
    if (isScreenCapture) {
      dispatch(endScreenShare());
      dispatch(setAlert('room', 'You are no longer sharing your Chalkboard'));
    }
  });
};

const MIN_DOWNLOAD_SIDE_LEN = 100;
const DOWNLOAD_PADDING = 100;
const DOWNLOAD_MAX_SIDE_LEN = EDITOR_SIZE;

const getSketchBounds = (items: ChalkboardItem[]): Bounds => {
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;
  for (const item of items) {
    const rightX = item.bounds.topLeft.x + item.bounds.size.width;
    const bottomY = item.bounds.topLeft.y + item.bounds.size.height;
    if (item.bounds.topLeft.x < minX) {
      minX = item.bounds.topLeft.x;
    }
    if (rightX > maxX) {
      maxX = rightX;
    }
    if (item.bounds.topLeft.y < minY) {
      minY = item.bounds.topLeft.y;
    }
    if (bottomY > maxY) {
      maxY = bottomY;
    }
  }

  const width = Math.max(maxX - minX, MIN_DOWNLOAD_SIDE_LEN);
  const height = Math.max(maxY - minY, MIN_DOWNLOAD_SIDE_LEN);

  return {
    topLeft: { x: minX, y: minY }, size: { width, height },
  };
};

const createDownloadView = (width: number, height: number, theme: ChalkboardTheme) => {
  const container = document.createElement('div');
  const canvas = document.createElement('canvas');
  const view = document.createElement('div');

  container.style.position = 'fixed';
  container.style.zIndex = '-1';

  view.style.backgroundColor = ThemeBGColorConfig[theme];
  view.style.padding = `${DOWNLOAD_PADDING}px`;
  view.style.boxSizing = 'content-box';
  view.style.width = `${width}px`;
  view.style.height = `${height}px`;

  const longestSide = Math.max(width, height);

  if (longestSide > DOWNLOAD_MAX_SIDE_LEN) {
    const multiplier = DOWNLOAD_MAX_SIDE_LEN / longestSide;

    view.style.transformOrigin = 'left top';
    view.style.transform = `scale(${multiplier})`;

    canvas.width = width * multiplier;
    canvas.height = height * multiplier;
  } else {
    canvas.width = width;
    canvas.height = height;
  }

  container.appendChild(view);
  container.appendChild(canvas);

  return { container, canvas, view };
};

const renderForDownload = (items: ChalkboardItem[], originTopLeft: Point, theme: ChalkboardTheme, view: HTMLDivElement) => {
  for (const item of items) {
    renderItem({
      ...item,
      id: `temp-${item.id}`,
      bounds: {
        ...item.bounds,
        topLeft: {
          x: item.bounds.topLeft.x - originTopLeft.x + DOWNLOAD_PADDING / 2,
          y: item.bounds.topLeft.y - originTopLeft.y + DOWNLOAD_PADDING / 2,
        },
      },
    }, theme, view, false);
  }
};

const saveChalkboardPNG = async (
  container: HTMLDivElement,
  canvas: HTMLCanvasElement,
  view: HTMLDivElement,
  width: number,
  height: number,
  dispatch: Dispatch,
) => {
  const pngDataUrl = await domToImage.toPng(view);

  const img = new Image();
  img.src = pngDataUrl;

  img.onload = () => {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(img, 0, 0, width, height);

    downloadLocalImage(`ChalkCast Sketch ${new Date().toLocaleString().replace(/[/:]/g, '-')}.png`, canvas.toDataURL());

    document.body.removeChild(container);

    dispatch(setIsDownloading(false));
  };

  container.appendChild(img);
};

const performDownload = (items: ChalkboardItem[], theme: ChalkboardTheme, dispatch: Dispatch) => {
  dispatch(setIsDownloading(true));

  // find bounding rect of all drawn items
  const { size: { width, height }, topLeft } = getSketchBounds(items);

  // create and style dom elements
  const { container, canvas, view } = createDownloadView(width, height, theme);

  // uses same core render func that renders to the actual chalkboard
  renderForDownload(items, topLeft, theme, view);

  // must be visible on the page
  document.body.appendChild(container);

  // downloads image & cleans up the dom afterwards
  saveChalkboardPNG(container, canvas, view, width, height, dispatch);
};

export const downloadChalkboard = (): AppThunkAction => async (dispatch, getState) => {
  const { chalkboardState: { committedItems, theme } } = getState();

  try {
    performDownload(Object.values(committedItems), theme, dispatch);
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    chalkboardLogger.warn('Error downloading chalkboard', { errorMessage });
    dispatch(setIsDownloading(false));
    dispatch(setRoomError('Error downloading Chalkboard'));
  }
};
