
import {
  SET_NUDGING_USER_ID,
  SET_SELECTED_NUDGE_GIF_ID,
  SET_SELECTED_NUDGE_EMOJI,
  NudgeAction,
} from 'actions/nudgeActions';

import {
  ACTIVATE_NUDGE,
  DEACTIVATE_NUDGE,
  SharedAction,
} from 'actions/sharedActions';
import produce from 'immer';
import { NudgeState } from 'store/types';

export const initialState: NudgeState = {
  nudges: { },
  nudgingUserId: '',
  selectedNudgeGif: null,
  selectedNudgeEmoji: '',
};

const reducer = produce((draft: NudgeState, action: NudgeAction | SharedAction) => {
  switch (action.type) {
    case ACTIVATE_NUDGE:
      draft.nudges[action.payload.userId] = action.payload.nudge;
      break;
    case DEACTIVATE_NUDGE:
      delete draft.nudges[action.payload.userId];
      break;
    case SET_NUDGING_USER_ID:
      draft.nudgingUserId = action.payload.userId;
      break;
    case SET_SELECTED_NUDGE_GIF_ID:
      draft.selectedNudgeGif = action.payload.gif;
      break;
    case SET_SELECTED_NUDGE_EMOJI:
      draft.selectedNudgeEmoji = action.payload.emoji;
      break;
  }
}, initialState);

export default reducer;
