/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ComponentProps, ReactNode } from 'react';
import { CSS } from 'types/css';

const styles: CSS = {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  minWidth: '32rem',
};

const containerStyles: CSS = {
  flex: 1,
};

interface Props extends ComponentProps<'main'>{
  children?: ReactNode,
}

const Layout = ({ children, ...rest }: Props) => (
  <main sx={styles} {...rest}>
    <div sx={containerStyles}>{children}</div>
  </main>
);


export default Layout;
