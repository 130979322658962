
/** https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent */
export enum WSCloseCodeEnum {

  // WS Standard codes
  NORMAL_CLOSURE = 1000,
  GOING_AWAY = 1001,
  PROTOCOL_ERROR = 1002,
  UNSUPPORTED_DATA = 1003,
  UNKNOWN_RESERVED_1004 = 1004,
  NO_STATUS_RECEIVED = 1005,
  ABNORMAL_CLOSURE = 1006,
  INVALID_PAYLOAD = 1007,
  POLICY_VIOLATION = 1008,
  MESSAGE_TOO_BIG = 1009,
  MISSING_EXTENSION = 1010,
  INTERNAL_ERROR = 1011,
  SERVICE_RESTART = 1012,
  TRY_AGAIN_LATER = 1013,
  BAD_GATEWAY = 1014,
  TLS_HANDSHAKE = 1015,

  // Application codes 4xxx:

  /** Connection closed because the user tried to join a room that requires login without having logged in */
  CHALKCAST_LOGIN_REQUIRED = 4003
}

/** Get the enum keyname (or 'UNKNOWN') and description of websocket disconnect code (describes websocket standard if code is unexpected) */
export const getWsCloseCodeInfo = (code: number): { name: string, desc: string } => {
  let name = WSCloseCodeEnum[code];
  let desc = code < 4000 ? 'Standard code' : 'Application code';
  if (!name) {
    name = 'UNKNOWN';
    desc = 'Unexpected code: ';
    if (code < 1000) {
      desc += 'Reserved codes 0-999 (should not be used)';
    } else if (code >= 1000 && code <= 1015) {
      desc += 'Standard codes 1000-1015 missing definition in app';
    } else if (code >= 1016 && code < 1999) {
      desc += 'Reserved codes 1016-1999 for future use (should not be used)';
    } else if (code >= 2000 && code <= 2999) {
      desc += 'Reserved codes 2000-2999 for WebSocket extensions';
    } else if (code >= 3000 && code <= 3999) {
      desc += 'Library/framework codes 3000-3999';
    } else if (code >= 4000 && code <= 4999) {
      desc += 'Application codes 4000-4999';
    } else {
      desc += 'Non-standard code number';
    }
  }
  return { name, desc };
};
