/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import validateDisplayName, { DISPLAY_NAME_MAX_LEN } from 'utils/validateDisplayName';
import { fixMultiWhitespace } from 'utils/stringUtils';
import { useEffect } from 'react';
import Input, { InputProps } from 'components/ui/Input';

/* Make some InputProps optional, since if they are omitted,
these props are defined here in the component */
export interface DisplayNameInputProps extends Omit<InputProps, 'id' | 'label'> {
  onNameChange?: (val: string) => void,
  currentDisplayName?: string,
  id?: InputProps['id'],
  label?: InputProps['label'],
}

/**
 * Handles input for the authorized user's display name.
 * Additional props passed to the Input component.
 * onNameChange(name: string) provides a sanitized name.
 * On blur, trims the value and calls the onNameChange again.
 * Providing an additional onBlur callback is possible.
 */
const DisplayNameInput = ({
  id,
  label,
  value = '',
  currentDisplayName = '',
  inputRef = { current: null },
  onNameChange,
  required = true,
  ...rest
}: DisplayNameInputProps) => {
  /* current display name can "silently" change when the user is given a
  temporary user account behind the scenes with a random animal name */
  useEffect(() => {
    if (!value && currentDisplayName && onNameChange) onNameChange(currentDisplayName);
  }, [currentDisplayName]);

  return (
    <Input
      id={id || 'displayName'}
      label={label || 'Display Name'}
      placeholder="Firstname Lastname"
      type="text"
      name="displayName"
      autoComplete="name"
      data-selenium="display-name-input"
      inputRef={inputRef}
      maxLength={DISPLAY_NAME_MAX_LEN}
      value={value}
      onChangeSanitized={(val) => onNameChange && onNameChange(val)}
      sanitizeOnBlur={(val) => val.trim() || currentDisplayName}
      sanitizeOnChange={(val) => fixMultiWhitespace(val)}
      validate={(val) => validateDisplayName(val, !!required)}
      validateOnChange
      required={required}
      {...rest}
    />
  );
};

DisplayNameInput.displayName = 'DisplayNameInput';

export default DisplayNameInput;
