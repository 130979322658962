
export const PASSWORD_CHAR_MIN = 8;
/**
 * Validates password string and returns any error messages, if any.
 */
export default function validatePassword(password: string) {
  let errorMessage = '';
  if (password.length < PASSWORD_CHAR_MIN) errorMessage = `Minimum ${PASSWORD_CHAR_MIN} characters required`;
  return errorMessage;
}
