/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { CSS } from 'types/css';
import { memo } from 'react';
import { EncoderVideo as LivelyEncoderVideo, LivelyEncoderUiContext } from '@livelyvideo/video-client-web';
import { useSelector } from 'react-redux';
import { getVideoUiState } from 'selectors';

const styles: CSS = {
  transform: 'scaleX(-1)',
  width: '100%',
  height: '100%',
};


/** Raw video element--should probably not be used directly, since the risk of trying to render
 * in multiple places at once is high. Instead, consider using RegularVideoConditional */
function RegularVideo() {
  const videoUiState = useSelector(getVideoUiState);

  if (!videoUiState) {
    return <div sx={styles} />;
  }

  const lvClasses = {
    root: {
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },
  };

  return (
    <LivelyEncoderUiContext.Provider value={videoUiState}>
      <div sx={styles} data-testid="RegularVideo">
        <LivelyEncoderVideo classes={lvClasses} />
      </div>
    </LivelyEncoderUiContext.Provider>
  );
}

export default memo(RegularVideo);
