import React, {
  createContext, Dispatch, FC, SetStateAction, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStateIfMounted from 'hooks/useStateIfMounted';
import {
  GoogleUser,
  LivelyToken, LoginTypesEnum,
} from 'store/types';
import { getLoginType } from 'selectors';
import { updateAuthData, AuthData } from 'actions/loginActions';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import useAccountMergeError from './useAccountMergeError';
import useContextWithProvider from './useContextWithProvider';

export enum LoginViewEnum {
  LOGIN = 'login',
  SIGNUP = 'signup',
  RESET = 'reset',
  MERGE = 'merge',
}

interface LoginState {
  loginView: LoginViewEnum;
  mergeLoading: boolean;
  mergeError: string;
  mergeSuccess: boolean;
  tempStoredUser: GoogleUser | null;
  tempLivelyToken: LivelyToken | null;
  tempJWT: string;
}

export const defaultState: LoginState = {
  loginView: LoginViewEnum.LOGIN,
  mergeLoading: false,
  mergeError: '',
  mergeSuccess: false,
  tempStoredUser: null,
  tempLivelyToken: null,
  tempJWT: '',
};

type LoginContext = LoginState & {
  redirectUrl: string,
  singlePage: boolean,
  loginModalIsOpen: boolean,
  mergeAccountModalIsOpen: boolean,
  backButtonMainSignupPage: boolean,
  setLoginView: Dispatch<SetStateAction<LoginViewEnum>>,
  setLoginModalIsOpen: Dispatch<SetStateAction<boolean>>,
  setMergeLoading: Dispatch<SetStateAction<boolean>>,
  setMergeError: Dispatch<SetStateAction<string>>,
  setMergeSuccess: Dispatch<SetStateAction<boolean>>,
  setTempStoredUser: Dispatch<SetStateAction<LoginState['tempStoredUser']>>,
  setMergeAccountModalIsOpen: Dispatch<SetStateAction<boolean>>,
  setTempLivelyToken: Dispatch<SetStateAction<LoginState['tempLivelyToken']>>,
  setTempJWT: Dispatch<SetStateAction<string>>,
  resetMergeState: () => void,
  toggleMergeAccountModal: () => void,
  toggleLoginModal: () => void,
  moveUserDataToRedux: () => void,
}


const LoginContext = createContext<LoginContext | null>(null);

interface Props {
  redirectUrl?: string,
  singlePage?: boolean;
  backButtonMainLoginPage?: boolean,
  backButtonMainSignupPage?: boolean,
}

/**
 * Provides state to the entire Login flow--or optionally to single
 * components of the login flow. Allows greater ease of setting state
 * and redirecting across modals and pages.
 */
const LoginProvider: FC<Props> = ({
  redirectUrl = `http://${appConfig.host}/token-auth`,
  singlePage = false,
  backButtonMainSignupPage = false,
  children = null,
}) => {
  const dispatch = useDispatch();
  const { hasMergeError } = useAccountMergeError();
  const loginType = useSelector(getLoginType);
  const location = useLocation();

  const deleteMergeQueryParameters = useCallback(() => {
    if (hasMergeError) dispatch(push(location.pathname));
  }, [location.pathname, hasMergeError, dispatch]);

  // which login component to render when a single-page login flow is used
  const [loginView, setLoginView] = useStateIfMounted(
    hasMergeError ? LoginViewEnum.MERGE : defaultState.loginView,
  );

  // login modal state
  const [loginModalIsOpen, setLoginModalIsOpen] = useStateIfMounted(!!hasMergeError);
  const toggleLoginModal = useCallback(() => {
    setLoginModalIsOpen((prevState) => !prevState);
    deleteMergeQueryParameters();
  }, [setLoginModalIsOpen, deleteMergeQueryParameters]);

  // merge account flow
  const [mergeAccountModalIsOpen, setMergeAccountModalIsOpen] = useStateIfMounted(!!hasMergeError);
  const [mergeLoading, setMergeLoading] = useStateIfMounted(defaultState.mergeLoading);
  const [mergeError, setMergeError] = useStateIfMounted(defaultState.mergeError);
  const [mergeSuccess, setMergeSuccess] = useStateIfMounted(defaultState.mergeSuccess);
  const [tempStoredUser, setTempStoredUser] = useStateIfMounted(defaultState.tempStoredUser);
  const [tempJWT, setTempJWT] = useStateIfMounted(defaultState.tempJWT);
  const [tempLivelyToken, setTempLivelyToken] = useStateIfMounted(defaultState.tempLivelyToken);

  const toggleMergeAccountModal = useCallback(() => {
    setMergeAccountModalIsOpen((prevState) => !prevState);
    deleteMergeQueryParameters();
  }, [setMergeAccountModalIsOpen, deleteMergeQueryParameters]);

  const resetMergeState = () => {
    setMergeLoading(defaultState.mergeLoading);
    setMergeError(defaultState.mergeError);
    setMergeSuccess(defaultState.mergeSuccess);
    setTempStoredUser(defaultState.tempStoredUser);
    setTempLivelyToken(defaultState.tempLivelyToken);
  };

  /**
   * Moves the user's login data from local state into Redux.
   *
   * When the user merges their accounts, we fetch the data and display it locally
   * to prevent a premature redirect while the user is still looking at the modal.
   * Once they close the modal (close button, "Done" button, or click outside), we store
   * the user's data in Redux as a true "login" event.
   */
  const moveUserDataToRedux = useCallback(() => {
    if (!tempStoredUser) return;
    const authData: AuthData = { user: tempStoredUser };
    if (tempLivelyToken) authData.livelyToken = tempLivelyToken;
    if (tempJWT) authData.jwt = tempJWT;
    dispatch(updateAuthData(authData));
  }, [tempStoredUser, tempJWT, tempLivelyToken, dispatch]);

  // closes the login modal after the user logs in via native
  useEffect(() => {
    if (loginType === LoginTypesEnum.AUTH_USER && loginModalIsOpen) toggleLoginModal();
  }, [loginType, loginModalIsOpen, toggleLoginModal]);

  return (
    <LoginContext.Provider
      value={{
        singlePage,
        loginView,
        setLoginView,
        loginModalIsOpen,
        setLoginModalIsOpen,
        toggleLoginModal,
        redirectUrl,
        mergeLoading,
        setMergeLoading,
        mergeError,
        setMergeError,
        mergeSuccess,
        setMergeSuccess,
        resetMergeState,
        tempStoredUser,
        setTempStoredUser,
        mergeAccountModalIsOpen,
        setMergeAccountModalIsOpen,
        moveUserDataToRedux,
        toggleMergeAccountModal,
        tempLivelyToken,
        setTempLivelyToken,
        tempJWT,
        setTempJWT,
        backButtonMainSignupPage,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export { LoginProvider };

export default function useLoginContext() {
  return useContextWithProvider(LoginContext);
}
